import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Modal,
  Typography,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { manageBaseUrl } from 'config';
import { useAuth } from 'auth/user/userAuth';
import ReactGA from 'react-ga4';
import axios from '../../../client/client';
import { selectUser, signInUser } from './shopSignInSlice';
import logo from '../../../assets/images/logo_long_default.png';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
    maxWidth: '35rem',
  },
  cardHeader: {
    padding: theme.spacing(2, 2, 0, 2),
    textAlign: 'center',
  },
  form: {
    width: '80%',
  },
  actionButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  forgotPassword: {
    textAlign: 'center',
  },
  createAccount: {
    textAlign: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(2),
  },
  media: {
    height: '60%',
    width: '60%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
  },
  popup: {
    maxWidth: '35rem',
    width: '80%',
  },
}));

function SignInPage() {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [valid, setValid] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [openSuccessBanner, setOpenSuccessBanner] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (location?.state?.detail === 'password reset succeeded') {
      setOpenSuccessBanner(true);
    }
  }, [location]);

  if (user) return <Redirect to={`/manage/shops?userId=${user.id}`} />;

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const handleLogin = async () => {
    try {
      const response = await auth.signIn(email, password);

      if (response.data.successSignIn) {
        GAevent('authentication', 'login', 'User sign in');

        dispatch(signInUser(response.data.user));
        history.push(`/manage/shops?userId=${response.data.user.id}`);
      } else {
        setValid(false);
      }
    } catch (err) {
      setValid(false);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const handleKeyDown = (props) => (e) => {
    if (e.keyCode === 13) {
      if (props === 'email') {
        const { form } = e.target;
        const index = Array.prototype.indexOf.call(form, e.target);
        form.elements[index + 1].focus();
        e.preventDefault();
      } else {
        handleLogin();
      }
    }
  };

  const handleForgotPassword = (e) => {
    GAevent('authentication', 'forgot_password', 'User forgot password');

    e.preventDefault();
    setForgotPassword(true);
  };

  const handleClose = () => {
    setForgotPassword(false);
  };

  const handleConfirmEmail = async (e) => {
    e.preventDefault();
    handleClose();
    const resourcePath = `${manageBaseUrl}/users/request-reset`;
    await axios.post(resourcePath, { email }, { withCredentials: false });
  };
  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={forgotPassword}
        onClose={handleClose}
      >
        <Card
          className={(classes.root, classes.popup)}
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CardHeader className={classes.cardHeader} title="Forgot Password" />
          <CardContent>
            <form className={classes.form} onSubmit={handleConfirmEmail}>
              <TextField
                fullWidth
                required
                inputProps={{ 'data-testid': 'ResetPWEmail' }}
                label="E-mail Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </form>
          </CardContent>
          <CardActions>
            <Grid container justify="space-between" alignItems="flex-end">
              <Button
                className={classes.actionButton}
                onClick={handleConfirmEmail}
                variant="contained"
              >
                Confirm Email
              </Button>
            </Grid>
          </CardActions>
        </Card>
      </Modal>
      <Grid
        container
        spacing={0}
        direction="column"
        justify="flex-start"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={10}>
          <Card className={classes.root}>
            <Grid container justify="center" alignItems="center">
              <CardMedia
                className={classes.media}
                component="img"
                image={logo}
                title="Primary logo"
              />
            </Grid>
            <CardContent>
              {!valid ? (
                <Alert variant="outlined" severity="error">
                  {' '}
                  The username and password you entered did not match our
                  records. Please double-check and try again.{' '}
                </Alert>
              ) : (
                ''
              )}
              <Grid container justify="center" alignItems="center">
                <form className={classes.form}>
                  <TextField
                    fullWidth
                    required
                    data-testid="EmailParentField"
                    label="E-mail Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    inputProps={{ 'data-testid': 'EmailField' }}
                    onKeyDown={handleKeyDown('email')}
                  />
                  <TextField
                    fullWidth
                    required
                    data-testid="PwParentField"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown('password')}
                    InputProps={{
                      inputProps: { 'data-testid': 'PwField' },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                            onMouseDown={handleMouseDown}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container justify="center" alignItems="center">
                <Button
                  className={classes.actionButton}
                  onClick={handleLogin}
                  variant="contained"
                  data-testid="SignInBtn"
                >
                  Sign In
                </Button>
              </Grid>
            </CardActions>
            <Grid item xs={12} className={classes.forgotPassword}>
              <Typography>
                <Link href="./" onClick={handleForgotPassword}>
                  Forgot your password?
                </Link>
              </Typography>
            </Grid>
          </Card>
          <Snackbar
            open={openSuccessBanner}
            autoHideDuration={5000}
            onClose={() => setOpenSuccessBanner(false)}
          >
            <Alert
              onClose={() => setOpenSuccessBanner(false)}
              severity="success"
            >
              Your new password was saved successfully!
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </>
  );
}

export default SignInPage;
