import React, { useEffect } from 'react';
import {
  Button,
  Card,
  Collapse,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { selectShopId } from 'pages/management/SignInPage/shopSignInSlice';
import CustomerDraft from 'features/customer/customerDraft';
import CustomToolTip, { toolTipMessages } from 'features/toolTip/customToolTip';
import ReactGA from 'react-ga4';
import CustomerList from '../../../features/customer/CustomerList';
import { filterEdited } from './customersSlice';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2, 0, 0),
    color: theme.palette.text.primary,
  },
  card: {
    padding: theme.spacing(1, 2, 1),
    margin: theme.spacing(2, 0),
  },
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  options: {
    padding: theme.spacing(0, 0, 1),
    margin: theme.spacing(2, 0),
    textAlign: 'end',
  },
  add: {
    color: 'white',
  },
}));

function CustomersPage() {
  const classes = useStyles();
  const [draft, setDraft] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState('');
  const dispatch = useDispatch();

  let shopId = useSelector(selectShopId);
  const urlShopId = useParams().shopId;
  if (!shopId) shopId = urlShopId;
  const customerError = useSelector((state) => state.customers.error);
  const customerStatus = useSelector((state) => state.customers.status);
  const filter = useSelector((state) => state.customers.filter);

  useEffect(() => {
    if (customerStatus === 'creation succeeded') {
      setSnackBarMessage('Successfully created customer');
      setSnackBarSeverity('success');
      setSnackBar(true);
    } else if (customerStatus === 'update succeeded') {
      setSnackBarMessage('Successfully updated customer');
      setSnackBarSeverity('success');
      setSnackBar(true);
    } else if (customerStatus === 'delete succeeded') {
      setSnackBarMessage('Successfully deleted customer');
      setSnackBarSeverity('success');
      setSnackBar(true);
    } else if (customerStatus === 'failed') {
      setSnackBarMessage(customerError);
      setSnackBarSeverity('error');
      setSnackBar(true);
    }
  }, [
    customerStatus,
    customerError,
    setSnackBar,
    setSnackBarSeverity,
    setSnackBarMessage,
  ]);

  if (!shopId) return <Redirect to="/users/sign-in" />;

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const setFilter = (payload) => {
    dispatch(filterEdited(payload));
  };

  const onToggleDraft = () => {
    setDraft((prevState) => !prevState);
  };

  const handleSnackbarClose = () => {
    setSnackBar(false);
  };

  const SnackBarAlert = () => (
    <Snackbar
      open={snackBar}
      autoHideDuration={10000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity={snackBarSeverity}>
        {snackBarMessage}
      </Alert>
    </Snackbar>
  );

  const SortBySelect = () => (
    <FormControl fullWidth>
      <InputLabel id="sort-by-select-label">Sort By:</InputLabel>
      <Select
        labelId="sort-by-select-label"
        value={filter.sortParam}
        onChange={(e) => {
          setFilter({ sortParam: e.target.value });

          GAevent(
            'engagement',
            `customers_filter_by_${filter.sortParam}`,
            'Filter customers',
          );
        }}
      >
        <MenuItem value={0}>Company Name (A-Z)</MenuItem>
        <MenuItem value={1}>Company Name (Z-A)</MenuItem>
        <MenuItem value={2}>Last Name (A-Z)</MenuItem>
        <MenuItem value={3}>Last Name (Z-A)</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <>
      <Typography className={classes.title} variant="h3">
        Customers <CustomToolTip text={toolTipMessages.CustomersPage} />
      </Typography>
      <Container>
        <Grid container justify="flex-end" alignItems="flex-end">
          <Grid item xs={12}>
            <Card className={classes.card}>
              <form className={classes.form}>
                <Grid item xs>
                  <TextField
                    fullWidth
                    id="search"
                    label="Search"
                    value={filter.query}
                    onChange={(e) => setFilter({ query: e.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SortBySelect />
                </Grid>
              </form>
            </Card>
          </Grid>
          <Grid className={classes.options} item xs={12}>
            <Button
              color={draft ? 'secondary' : 'primary'}
              variant="contained"
              className={classes.add}
              onClick={onToggleDraft}
            >
              {draft ? 'Cancel' : 'Add Customer'}
            </Button>
            <SnackBarAlert />
          </Grid>
        </Grid>
        <Collapse in={draft}>
          <CustomerDraft shopId={shopId} onCloseDraft={() => setDraft(false)} />
        </Collapse>
        <CustomerList shopId={shopId} />
      </Container>
    </>
  );
}

export default CustomersPage;
