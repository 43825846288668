import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Snackbar,
  Box,
  LinearProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { manageBaseUrl } from 'config';
import ReactGA from 'react-ga4';
import {
  selectUser,
  updateUser,
  selectShopId,
  updateShopBA,
  saveAwsId,
  changeAwsIdStatus,
} from '../SignInPage/shopSignInSlice';
// import { saveAwsId } from './profilePageSlice';
import awsLogo from '../../../assets/images/aws.png';
import axios from '../../../client/client';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 0),
  },
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  billingAddressCard: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1, 2, 0),
  },
  content: {
    paddingBottom: '0px !important',
    padding: theme.spacing(0, 2, 0),
  },
  cardActions: {
    padding: theme.spacing(0),
  },
  text: {
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 1, 0, 0),

    '& .MuiFormLabel-root': {
      color: theme.palette.text.primary,
    },
  },
  add: {
    margin: theme.spacing(0, 1),
    color: 'white',
  },
  textField: {
    margin: theme.spacing(1),
    width: '50ch',
  },
  avatar: {
    backgroundImage: `url(${awsLogo})`,
    height: '50px',
    width: '50px',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  description: {
    marginBottom: '10px',
  },
}));

function ProfilePage() {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const shopId = useSelector(selectShopId);
  const billingAddress = useSelector((state) => state.user.billingAddress);
  const billingAddressStatus = useSelector((state) => state.user.BAStatus);
  const dispatch = useDispatch();
  const [dialog, setDialog] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editUser, setEditUser] = React.useState({
    id: -1,
    email: '',
    firstName: '',
    lastName: '',
    awsUserId: '',
  });
  const [editBA, setEditBA] = React.useState(false);
  const [BADraft, setBADraft] = React.useState({
    streetAddress: '',
    city: '',
    province: '',
    postalCode: '',
  });
  React.useEffect(() => {
    if (billingAddress) {
      setBADraft(billingAddress);
    }
  }, [billingAddress]);
  if (!user) return <Redirect to="/users/sign-in" />;

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const handleFirstNameChange = (e) => {
    const { value } = e.target;
    setEditUser({ ...editUser, firstName: value });
  };
  const handleLastNameChange = (e) => {
    const { value } = e.target;
    setEditUser({ ...editUser, lastName: value });
  };

  // const handleAwsUserIdChange = (e) => {
  //   const { value } = e.target;
  //   setEditUser({ ...editUser, awsUserId: value });
  // };

  const handleClick = (e) => {
    if (e.currentTarget.value === 'Modify') {
      setEditUser({
        ...editUser,
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        awsUserId: user.awsUserId,
      });
      GAevent('engagement', 'profile_modify_details', 'Modify profile details');
    } else {
      dispatch(updateUser(editUser));
      setEditUser({ ...editUser, id: -1 });
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCancelDialog = () => {
    setDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbar(false);
  };

  const handleRequestReset = async () => {
    handleMenuClose();
    setDialog(false);
    try {
      await axios.post(`${manageBaseUrl}/users/request-reset`, {
        email: user.email,
      });
      setSnackbar(true);
    } catch (e) {
      setSnackbar(false);
    }
  };
  const handleAddModifySave = () => {
    if (editBA) dispatch(updateShopBA({ shopId, billingAddress: BADraft }));
    setEditBA(!editBA);
  };

  const AwsS3ShareCard = () => {
    const [awsAccountId, setAwsId] = React.useState(
      user.awsUserId ? user.awsUserId : '',
    );
    const [openSuccessBanner, setOpenSuccessBanner] = React.useState(false);
    const [openErrorBanner, setOpenErrorBanner] = React.useState(false);

    const saveAWSId = (awsId) => {
      const params = {
        customerId: user.id,
        awsAccountId: awsId.toString(),
        admin: 'true',
      };
      dispatch(saveAwsId(params));
    };

    const bannerStatus = useSelector((state) => state.user.awsIdstatus);

    useEffect(() => {
      if (bannerStatus === 'succeeded') {
        setOpenSuccessBanner(true);
        dispatch(changeAwsIdStatus());
      } else if (bannerStatus === 'failed') {
        setOpenErrorBanner(true);
      }
    }, [bannerStatus]);

    const handleClose = () => {
      setOpenSuccessBanner(false);
      setOpenErrorBanner(false);
    };

    return (
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <img className={classes.avatar} src={awsLogo} alt={awsLogo} />
          }
          title="AWS S3 Data Lake"
          subheader="Make your data avilable to customers by providing an AWS Account Id"
        />
        <CardContent>
          <Typography component="div" className={classes.description}>
            <Box>
              Your AWS Account Id will be used to provide cross-account access
              into the S3 bucket that JamLabs hosts. This bucket allows data
              sellers to dump their data, and have it processed and made
              available to licensed customers. This 12-digit number can be found
              in the AWS Management Console by navigating to{' '}
              <strong>My Account,</strong> and then{' '}
              <strong>Account Settings.</strong>
            </Box>
          </Typography>
          <TextField
            value={awsAccountId}
            inputProps={{ 'data-testid': 'AWSAccountIdField' }}
            onChange={(e) => {
              setAwsId(e.target.value);
            }}
            label="AWS Account Id"
          />
        </CardContent>
        <Snackbar
          open={openSuccessBanner}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Your AWS Account ID was saved successfully!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openErrorBanner}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            An error occurred while saving your AWS Account ID. Please try again
            later!
          </Alert>
        </Snackbar>
        <CardActions disableSpacing>
          <Grid container item justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              data-testid="AWSSaveBtn"
              onClick={() => {
                saveAWSId(awsAccountId);
              }}
            >
              Save
            </Button>
          </Grid>
        </CardActions>
      </Card>
    );
  };

  const SnackBarAlert = () => (
    <Snackbar
      open={snackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity="success">
        Successfully sent email with password reset token to{' '}
        <strong>{user.email}</strong>!
      </Alert>
    </Snackbar>
  );

  const CustomDialog = () => (
    <Dialog open={dialog} onClose={handleCancelDialog}>
      <DialogTitle>Confirm Send?</DialogTitle>
      <DialogContent>
        Are you sure you want to send an email with a password reset token to{' '}
        <strong>{user.email}</strong>?
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancelDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleRequestReset}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );

  const displayBillingAddress = () => {
    if (billingAddressStatus === 'loading') {
      return (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      );
    }
    if (billingAddress || editBA)
      return (
        <>
          <Grid item xs={4}>
            <TextField
              className={classes.text}
              fullWidth
              inputProps={{ 'data-testid': 'StreetField' }}
              disabled={!editBA}
              label="Street Address"
              value={
                editBA ? BADraft.streetAddress : billingAddress.streetAddress
              }
              onChange={(e) => {
                setBADraft({ ...BADraft, streetAddress: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              className={classes.text}
              disabled={!editBA}
              inputProps={{ 'data-testid': 'CityField' }}
              label="City"
              value={editBA ? BADraft.city : billingAddress.city}
              onChange={(e) => {
                setBADraft({ ...BADraft, city: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <TextField
              className={classes.text}
              disabled={!editBA}
              label="Province"
              inputProps={{ 'data-testid': 'ProvinceField' }}
              value={editBA ? BADraft.province : billingAddress.province}
              onChange={(e) => {
                setBADraft({ ...BADraft, province: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              className={classes.text}
              disabled={!editBA}
              label="Postal Code"
              inputProps={{ 'data-testid': 'PostalCodeField' }}
              value={editBA ? BADraft.postalCode : billingAddress.postalCode}
              onChange={(e) => {
                setBADraft({ ...BADraft, postalCode: e.target.value });
              }}
            />
          </Grid>
        </>
      );
    return (
      <Typography component="div">
        <Box fontStyle="italic">No billing address found.</Box>
      </Typography>
    );
  };

  const billingAddressButtonText = () => {
    if (editBA) return 'Save';
    if (billingAddress) return 'Modify';
    return 'Add';
  };
  return (
    <>
      <Typography className={classes.title} variant="h3">
        Profile
      </Typography>
      <SnackBarAlert />
      <CustomDialog />
      <Card key={user.id} className={classes.card}>
        <CardHeader
          className={classes.header}
          action={
            <IconButton
              aria-label="Learn More"
              onClick={(e) => {
                handleMenuOpen(e);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={`${user.firstName} ${user.lastName}`}
        />
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={!!anchorEl}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              setDialog(true);
            }}
          >
            Reset Password
          </MenuItem>
        </Menu>
        <CardContent className={classes.content}>
          <Grid container>
            <Grid item xs={3}>
              <TextField
                className={classes.text}
                inputProps={{ 'data-testid': 'EmailField' }}
                fullWidth
                disabled
                label="Email"
                value={user.email}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                className={classes.text}
                disabled={editUser.id !== user.id}
                inputProps={{ 'data-testid': 'FirstNameField' }}
                label="First Name"
                value={
                  editUser.id !== user.id ? user.firstName : editUser.firstName
                }
                onChange={handleFirstNameChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                className={classes.text}
                disabled={editUser.id !== user.id}
                inputProps={{ 'data-testid': 'LastNameField' }}
                label="Last Name"
                value={
                  editUser.id !== user.id ? user.lastName : editUser.lastName
                }
                onChange={handleLastNameChange}
              />
            </Grid>
            {/* <Grid item xs={2}>
              <TextField
                className={classes.text}
                disabled={editUser.id !== user.id}
                label="AWS User Id"
                value={
                  editUser.id !== user.id ? user.awsUserId : editUser.awsUserId
                }
                onChange={handleAwsUserIdChange}
              />
            </Grid> */}
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              className={classes.add}
              data-testid="NameSave"
              onClick={handleClick}
              value={editUser.id === user.id ? 'Save' : 'Modify'}
            >
              {editUser.id === user.id ? 'Save' : 'Modify'}
            </Button>
          </Grid>
        </CardActions>
      </Card>
      <Typography className={classes.title} variant="h3">
        Shop Billing Address
      </Typography>
      <Card key="billing-address" className={classes.billingAddressCard}>
        <CardContent className={classes.content}>
          <Grid container>{displayBillingAddress()}</Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Grid container justify="flex-end">
            {billingAddressStatus !== 'loading' && (
              <Button
                variant="contained"
                color="primary"
                className={classes.add}
                data-testid="ShopBillingAddressBtn"
                onClick={handleAddModifySave}
              >
                {billingAddressButtonText()}
              </Button>
            )}
          </Grid>
        </CardActions>
      </Card>
      <Typography className={classes.title} variant="h3">
        AWS Account Information
      </Typography>
      <AwsS3ShareCard />
    </>
  );
}

export default ProfilePage;
