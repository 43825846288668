/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { manageBaseUrl, stripeKey } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const customersAdapter = createEntityAdapter({
  selectId: (customer) => customer.id,
  sortComparer: null,
});

const initialState = customersAdapter.getInitialState({
  status: 'idle',
  error: null,
  filter: {
    query: '',
    sortParam: 0,
  },
});

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async (shopId) => {
    const resp = await axios.get(`${manageBaseUrl}/customers`, {
      params: { shopId },
    });
    return { customers: resp.data };
  },
);

export const createCustomer = createAsyncThunk(
  'customers/createCustomer',
  async (customer) => {
    const resp = await axios.post(`${manageBaseUrl}/customers`, {
      ...customer,
      id: uuidv4().toUpperCase(),
      stripeKey,
    });
    return resp.data;
  },
);

export const updateCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async (customer) => {
    const resp = await axios.put(`${manageBaseUrl}/customers`, customer);
    return resp.data;
  },
);

export const deleteCustomer = createAsyncThunk(
  'customers/deleteCustomer',
  async (params) => {
    await axios.delete(
      `${manageBaseUrl}/customers`,
      { params },
      { timeout: 30000 },
    );
    return params.id;
  },
);

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    filterEdited(state, action) {
      const prop = Object.keys(action.payload)[0];
      const value = action.payload[prop];
      state.filter[prop] = value;
    },
  },
  extraReducers: {
    [fetchCustomers.pending]: (state) => {
      state.status = 'fetchLoading';
    },
    [createCustomer.pending]: (state) => {
      state.status = 'loading';
    },
    [updateCustomer.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteCustomer.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCustomers.rejected]: (state, action) => errorResponse(state, action),
    [createCustomer.rejected]: (state) => {
      state.status = 'failed';
      state.error = 'Email is not valid please try again';
    },
    [updateCustomer.rejected]: (state, action) => errorResponse(state, action),
    [deleteCustomer.rejected]: (state, action) => errorResponse(state, action),
    [fetchCustomers.fulfilled]: (state, action) => {
      if (action.payload) {
        const { customers } = action.payload;
        customersAdapter.setAll(state, customers);
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving customers: received null value';
      }
    },
    [createCustomer.fulfilled]: (state, action) => {
      const customer = action.payload;
      customersAdapter.upsertOne(state, customer);
      state.status = 'creation succeeded';
    },
    [updateCustomer.fulfilled]: (state, action) => {
      const customer = action.payload;
      customersAdapter.updateOne(state, {
        id: customer.id,
        changes: customer,
      });
      state.status = 'update succeeded';
    },
    [deleteCustomer.fulfilled]: (state, action) => {
      const id = action.payload;
      customersAdapter.removeOne(state, id);
      state.status = 'delete succeeded';
    },
  },
});

export const SelectCustomers = (state) => state.customers.entities;

export const { filterEdited } = customersSlice.actions;

export const {
  selectAll: selectAllCustomers,
  selectById: selectCustomerById,
  selectIds: selectCustomerIds,
} = customersAdapter.getSelectors((state) => state.customers);

export const selectCustomerFilter = (state) => state.customers.filter;

export default customersSlice.reducer;
