import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBarUI from '@material-ui/core/AppBar';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import {
  Assignment as AssignmentIcon,
  Storage as StorageIcon,
  MeetingRoom as MeetingRoomIcon,
  Shop as ShopIcon,
} from '@material-ui/icons';
// import logo from "../../assets/images/logo_long_primary.png";
import { Link, useHistory, useParams } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { manageBaseUrl } from 'config';
import { useAuth as useUserAuth } from 'auth/user/userAuth';
import ReactGA from 'react-ga4';
import { DarkThemeToggle } from './DarkThemeToggle';
import { selectAllShops } from '../../pages/management/ShopSelectPage/shopSelectSlice';
import logo from '../../assets/images/logo_long_primary.png';

import {
  selectUser,
  signOutUser,
  fetchUser,
} from '../../pages/management/SignInPage/shopSignInSlice';

import axios from '../../client/client';

const useStyles = (drawerWidth) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      width: '48px',
      transition: theme.transitions.create(['marginLeft', 'opacity'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      opacity: 0,
      marginLeft: '-48px',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    grow: {
      flexGrow: 1,
    },
    title: {
      display: 'none',
      padding: theme.spacing(0, 2),
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    logo: {
      height: '38px',
    },
    white: {
      color: '#87d12d',
      backgroundColor: '#FFFFFF',
    },
    vCenter: {
      display: 'flex',
      alignItems: 'center',
    },
  }));

export const AppBar = injectIntl(
  ({ onMenuClick, open, drawerWidth, signedOut }) => {
    const userAuth = useUserAuth();
    const { shopId } = useParams();
    const classes = useStyles(drawerWidth)();

    const user = useSelector(selectUser);
    const shops = useSelector(selectAllShops);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const history = useHistory();

    const dispatch = useDispatch();

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const GAevent = (category, action, label) => {
      ReactGA.event({ category, action, label });
    };

    const handleProfileMenuOpen = (event) => {
      GAevent('engagement', 'open_profile_menu', 'Open profile menu');

      setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
      GAevent('engagement', 'open_mobile_menu', 'Open mobile menu');

      setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
      GAevent('engagement', 'close_profile_menu', 'Close profile menu');

      setAnchorEl(null);
      handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
      GAevent('engagement', 'close_mobile_menu', 'Close mobile menu');

      setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleSignInSignOut = () => {
      if (!user) {
        history.push('/welcome');
      } else {
        axios.get(`${manageBaseUrl}/users/sign-out`).then((response) => {
          if (response.data.successSignOut) {
            GAevent('authentication', 'signout', 'User signout');

            dispatch(signOutUser());
            userAuth.signOut();
            history.push('/users/sign-in');
          }
        });
      }
    };
    React.useEffect(() => {
      if (!user) {
        dispatch(fetchUser());
      }
    }, [user, dispatch]);

    let shopdisplay = 'block';
    if (shops.length > 1) {
      shopdisplay = 'block';
    } else {
      shopdisplay = 'none';
    }
    const getMenuItems = () => {
      // Signed in data seller
      if (user) {
        return [
          <MenuItem
            component={Link}
            to={`/shop/${shopId}/profile`}
            key="profile"
          >
            Profile
          </MenuItem>,
          <MenuItem component={Link} to={`/shop/${shopId}/orders`} key="orders">
            View Orders
          </MenuItem>,
          <MenuItem
            component={Link}
            to={`/shop/${shopId}/delivery-options`}
            key="delivery-options"
          >
            Delivery Options
          </MenuItem>,
          <MenuItem
            style={{ display: shopdisplay }}
            component={Link}
            to={`/manage/shops?userId=${user.id}`}
            key="select-shop"
          >
            Select Shop
          </MenuItem>,
          <MenuItem onClick={handleSignInSignOut} key="sign-out">
            Sign Out
          </MenuItem>,
        ];
      }
      // Other options
      return null;
    };
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
      <Menu
        data-testid="AccountMenuBtn"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {getMenuItems()}
      </Menu>
    );
    const getMobileMenuItems = () => {
      // Signed out user
      if (signedOut)
        return [
          <MenuItem key="sign-in" onClick={handleSignInSignOut}>
            <IconButton aria-label="sign in" color="inherit">
              <MeetingRoomIcon />
            </IconButton>
            <p>Sign In</p>
          </MenuItem>,
        ];
      // Signed in data seller
      if (user) {
        return [
          <MenuItem
            key="profile"
            onClick={() => {
              history.push(`/shop/${shopId}/profile`);
            }}
          >
            <IconButton aria-label="profile" color="inherit">
              <AccountCircle />
            </IconButton>
            <p>Profile</p>
          </MenuItem>,
          <MenuItem
            key="view-orders"
            onClick={() => {
              history.push(`/shop/${shopId}/orders`);
            }}
          >
            <IconButton aria-label="view orders" color="inherit">
              <AssignmentIcon />
            </IconButton>
            <p>View Orders</p>
          </MenuItem>,
          <MenuItem
            key="data-delivery"
            onClick={() => {
              history.push(`/shop/${shopId}/delivery-options`);
            }}
          >
            <IconButton aria-label="delivery options" color="inherit">
              <StorageIcon />
            </IconButton>
            <p>Delivery Options</p>
          </MenuItem>,
          <MenuItem
            key="select-shop"
            onClick={() => {
              history.push(`/manage/shops?userId=${user.id}`);
            }}
          >
            <IconButton aria-label="select shop" color="inherit">
              <ShopIcon />
            </IconButton>
            <p>Select Shop</p>
          </MenuItem>,
          <MenuItem key="sign-out" onClick={handleSignInSignOut}>
            <IconButton aria-label="sign out" color="inherit">
              <MeetingRoomIcon />
            </IconButton>
            <p>Sign Out</p>
          </MenuItem>,
        ];
      }
      // Other options
      return null;
    };
    let homeLink = '/welcome';
    if (!signedOut) {
      homeLink = `/shop/${shopId}`;
    }

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
      <Menu
        data-testid="AccountMenuBtn"
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {getMobileMenuItems()}
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBarUI
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onMenuClick}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            {/* {false ? (
            <img
              className={classes.logo}
              src={logo}
              alt="JamLabs Data Shop logo"
            />
          ) : null} */}
            <Button component={Link} to={homeLink} data-testid="IndexLogoBtn">
              <img className={classes.logo} src={logo} alt={logo} />
            </Button>

            <Typography className={classes.title} variant="h4" noWrap>
              jShop
            </Typography>
            <div className={classes.grow} />
            {/* <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div> */}
            <div className={classes.sectionDesktop}>
              <div className={classes.vCenter}>
                <DarkThemeToggle />
              </div>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar className={classes.white}>
                  {user &&
                    user.firstName[0].toUpperCase() +
                      user.lastName[0].toUpperCase()}
                </Avatar>
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBarUI>
        {renderMobileMenu}
        {renderMenu}
      </div>
    );
  },
);
