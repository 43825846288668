import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  IconButton,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  TablePagination,
  LinearProgress,
  Chip,
} from '@material-ui/core';
import {
  HelpOutline as HelpOutlineIcon,
  Add as AddIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { Redirect, useParams } from 'react-router-dom';
import CustomToolTip, { toolTipMessages } from 'features/toolTip/customToolTip';
import ReactGA from 'react-ga4';
import Tags from '../../../features/tags/Tags';
import {
  fetchAttributes,
  createAttribute,
  updateAttribute,
  deleteAttribute,
  selectAllAttributes,
} from './attributesSlice';
import {
  fetchProducts,
  updateProduct,
  selectAllProducts,
} from '../ProductsPage/productsSlice';
import { selectShopId } from '../SignInPage/shopSignInSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 0),
  },
  placeholder: {
    minHeight: 460,
    minWidth: 1230,
  },
  form: {
    flexGrow: 1,
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  emptycell: {
    height: 'auto',
    padding: 0,
    borderBottom: 'hidden',
  },
  loading: {
    width: '50%',
    margin: theme.spacing(10, 0, 10),
  },
  warning: {
    color: 'red',
  },
  text: { color: theme.palette.text.primary },
}));

function AttributesPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const attributeInitialState = {
    id: -1,
    name: '',
    description: '',
    tags: [],
  };

  const attributeStatus = useSelector((state) => state.attributes.status);
  const error = useSelector((state) => state.attributes.error);

  const [tab, setTab] = React.useState('table');
  const [orderBy, setOrderBy] = React.useState('creationDate');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [attribute, setAttribute] = React.useState(attributeInitialState);
  const [dialog, setDialog] = React.useState(false);
  const [deleteTagKey, setDeleteTagKey] = React.useState(-1);

  const [nameErrorText, setNameErrorText] = React.useState('');
  const [descErrorText, setDescErrorText] = React.useState('');

  const attributes = useSelector(selectAllAttributes);
  const products = useSelector(selectAllProducts);

  let shopId = useSelector(selectShopId);
  const urlShopId = useParams().shopId;
  if (!shopId) shopId = urlShopId;

  const [associatedProducts, setAssociatedProducts] = React.useState([]);

  const columns = [
    {
      id: 'name',
      label: 'Attribute Name',
      width: '15%',
      sortable: true,
    },
    {
      id: 'description',
      label: 'Description',
      width: '40%',
      sortable: true,
    },
    {
      id: 'creationDate',
      label: 'Creation Date',
      width: '12.5%',
      sortable: true,
    },
    {
      id: 'edit',
      label: '',
      width: '2.5%',
      sortable: false,
    },
  ];

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, attributes.length - page * rowsPerPage);

  // Populate Products as a dependency for Attributes
  const productsStatus = useSelector((state) => state.products.status);

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  useEffect(() => {
    if (!shopId) return;
    if (attributeStatus === 'idle') {
      dispatch(fetchAttributes({ shopId }));
    }
    if (productsStatus === 'idle') {
      dispatch(fetchProducts({ shopId }));
    }
  }, [attributeStatus, shopId, productsStatus, dispatch]);

  if (!shopId) return <Redirect to="/users/sign-in" />;

  // Table Functions
  // Sorting Functions
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderSort = comparator(a[0], b[0]);
      if (orderSort !== 0) return orderSort;
      return a[1] - b[1];
    });

    if (order === 'asc') {
      return stabilizedThis.reverse().map((el) => el[0]);
    }
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderByThis) => {
    if (b[orderByThis] < a[orderByThis]) return -1;
    if (b[orderByThis] > a[orderByThis]) return 1;
    return 0;
  };

  const getComparator = (orderByThis) => (a, b) =>
    descendingComparator(a, b, orderByThis);

  // Pagination Functions
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    const { value } = e.target;
    setRowsPerPage(+value);
    setPage(0);
  };

  const handleAttributeEdit = (attributeEdit) => {
    setAttribute(
      attributeEdit === null ? attributeInitialState : attributeEdit,
    );
    setTab('edit');

    GAevent('engagement', 'attributes_edit_attribute', 'Edit attributes');
  };

  // Edit Form Functions

  const handleChange = (e, prop) => {
    // set draft attribute property in local state
    const { value } = e.target;
    setAttribute({
      ...attribute,
      [prop]: value,
    });
  };

  const handleDeleteTagRequest = (key) => {
    setDeleteTagKey(key);

    // get all the products that has this tag
    const newAssociatedProducts = [];
    products.forEach((product) => {
      product.tags.forEach((tag) => {
        if (tag.attribute === attribute.id && tag.tag === key) {
          newAssociatedProducts.push({ product, tag: tag.tag });
        }
      });
    });
    setAssociatedProducts(newAssociatedProducts);
    setDialog(true);
  };

  const deleteTag = (key) => {
    setDialog(false);

    // reset associatedProducts
    setAssociatedProducts([]);

    // remove tag from draft attribute in local state
    setAttribute({
      ...attribute,
      tags: attribute.tags.filter((tag) => tag.key !== key),
    });
  };

  const handleAddTag = (newTag) => {
    if (newTag !== '') {
      setAttribute({
        ...attribute,
        tags: [
          ...attribute.tags,
          { key: uuidv4().toUpperCase(), label: newTag },
        ],
      });
    }
  };

  const handleDeleteAttributeRequest = () => {
    setDeleteTagKey(-1);

    // get all products that contain tags that belong to this attribute
    const newAssociatedProducts = [];
    products.forEach((product) => {
      product.tags.forEach((tag) => {
        if (tag.attribute === attribute.id) {
          newAssociatedProducts.push({ product, tag: tag.tag });
        }
      });
    });

    setAssociatedProducts(newAssociatedProducts);
    setDialog(true);
  };

  const UpdateProducts = (deleteAllTags = false) => {
    // Identify any tags that have been deleted
    let tagsToUpdate = [];
    if (deleteAllTags) {
      tagsToUpdate = attribute.tags;
    } else {
      const old = attributes.find((x) => x.id === attribute.id);
      tagsToUpdate = old.tags.filter((x) => attribute.tags.indexOf(x) === -1);
    }
    // Identify any products affected by deleting tags
    const newAssociatedProducts = products.filter((product) => {
      for (let i = 0; i < tagsToUpdate.length; i += 1) {
        const x = tagsToUpdate[i];
        const key = { attribute: attribute.id, tag: x.key };
        if (
          product.tags.findIndex(
            (tag) => tag.attribute === key.attribute && tag.tag === key.tag,
          ) !== -1
        )
          return true;
      }
      return null;
    });
    Object.values(newAssociatedProducts).forEach((y) => {
      const changeset = { ...y };
      // filter out the tags that are supposed to be updated
      changeset.tags = changeset.tags.filter(
        (tag) => tagsToUpdate.findIndex((n) => n.key === tag.tag) === -1,
      );
      dispatch(updateProduct(changeset));
    });
  };

  const deleteAttributeRequest = () => {
    setDialog(false);

    // update every product affected by deleting this attribute
    UpdateProducts(true);

    dispatch(deleteAttribute(attribute.id));
    handleCancelEdit();
  };

  const handleCancelDialog = () => {
    setDialog(false);
  };

  const handleDelete = () => {
    // check if either a tag or attribute is being deleted
    if (deleteTagKey === -1) deleteAttributeRequest();
    else deleteTag(deleteTagKey);

    GAevent('engagement', 'attributes_delete_attribute', 'Delete attribute');
  };

  const handleCancelEdit = () => {
    setAttribute(attributeInitialState);
    setTab('table');
    setNameErrorText('');
    setDescErrorText('');
  };

  const handleSaveEdit = () => {
    if (attribute.name !== '') {
      // create a new attribute if no id is passed through
      if (attribute.id === -1) {
        attribute.shopId = shopId;
        dispatch(createAttribute(attribute));
      } else {
        // update affected products from deleting any tags
        UpdateProducts(false);
        // update the attribute
        dispatch(updateAttribute(attribute));
      }
      handleCancelEdit();
    }
    // error helper text
    if (attribute.name === '')
      setNameErrorText('Attribute Name cannot be empty.');
    else setNameErrorText('');
  };

  const AttributeRow = ({ attributeCurr }) => (
    <TableRow key={attributeCurr.id} data-testid="TableBodyRow">
      <TableCell>{attributeCurr.name}</TableCell>
      <TableCell>{attributeCurr.description}</TableCell>
      <TableCell>
        {new Date(attributeCurr.createdAt).toISOString().split('T')[0]}
      </TableCell>
      <TableCell>
        <IconButton onClick={() => handleAttributeEdit(attributeCurr)}>
          <EditIcon data-testid="EditAttributeBtn"/>
        </IconButton>
      </TableCell>
    </TableRow>
  );

  const AttributesTable = () => {
    if (attributeStatus === 'loading') {
      return (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.placeholder}
        >
          <LinearProgress className={classes.loading} />
        </Grid>
      );
    }
    if (attributeStatus === 'succeeded') {
      return (
        <>
          <TableContainer>
            <Table aria-label="product attributes table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sortDirection={orderBy === column.id ? order : false}
                      width={column.width}
                    >
                      {column.label}
                      {column.sortable && (
                        <TableSortLabel
                          active
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={(e) => handleRequestSort(e, column.id)}
                          data-testid={`TableSortLabel${column.id}`}
                        />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody data-testid="AttributesableBody">
                {stableSort(attributes, getComparator(orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((attributeMap) => (
                    /* .map((attribute, index) => ( */
                    <AttributeRow
                      key={attributeMap.id}
                      /* key={attribute.id + "_" + index} */
                      attributeCurr={attributeMap}
                    />
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 81 * emptyRows }}>
                    <TableCell className={classes.emptycell} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={attributes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      );
    }
    if (attributeStatus === 'failed') {
      return <p>{error}</p>;
    }
    return null;
  };

  return (
    <>
      {tab === 'table' && (
        <>
          <Typography className={classes.title} variant="h3">
            Product Attributes{' '}
            <CustomToolTip text={toolTipMessages.AttributesPage} />
          </Typography>
          <AttributesTable />
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleAttributeEdit(null)}
          >
            <AddIcon />
            Add Attribute
          </Button>
        </>
      )}
      {tab === 'edit' && (
        <>
          <Typography className={classes.title} variant="h3">
            Attribute <HelpOutlineIcon fontSize="large" />
          </Typography>
          <form className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  label="Attribute Name"
                  aria-label="Attribute-Name"
                  inputProps={{ 'data-testid': 'name-input' }}
                  defaultValue={attribute.name}
                  error={nameErrorText !== ''}
                  helperText={nameErrorText}
                  onBlur={(e) => handleChange(e, 'name')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Attribute Description"
                  aria-label="Attribute-Description"
                  inputProps={{ 'data-testid': 'description-input' }}
                  defaultValue={attribute.description}
                  error={descErrorText !== ''}
                  helperText={descErrorText}
                  onBlur={(e) => handleChange(e, 'description')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.text} variant="h5">
                  Tags in Attribute:
                </Typography>
                <Tags
                  useData
                  editing
                  parent={attribute}
                  onDelete={handleDeleteTagRequest}
                  onAdd={handleAddTag}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'end' }}>
                <Button
                  className={classes.button}
                  style={{ backgroundColor: '#ff3c3c', color: 'white' }}
                  variant="contained"
                  disabled={attribute.id === -1}
                  onClick={handleDeleteAttributeRequest}
                >
                  Delete Attribute
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.button}
                  style={{ backgroundColor: '#009e10', color: 'white' }}
                  variant="contained"
                  onClick={handleSaveEdit}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
          <Dialog open={dialog} onClose={handleCancelDialog}>
            <DialogTitle>Confirm Delete?</DialogTitle>
            <DialogContent>
              This {deleteTagKey === -1 ? 'attribute' : 'tag'} is used in these
              products:{' '}
              {associatedProducts.length > 0 ? (
                associatedProducts.map((pair) => (
                  <li key={`${pair.product.id}_${pair.tag}`}>
                    {pair.product.name}{' '}
                    <Chip
                      label={
                        attribute.tags.find((tag) => tag.key === pair.tag) !== undefined ? 
                        attribute.tags.find((tag) => tag.key === pair.tag).label : null
                      }
                    />
                  </li>
                ))
              ) : (
                <li>No Products</li>
              )}
              {deleteTagKey === -1 ? null : (
                <Typography className={classes.warning}>
                  Warning: This will permanantly delete this tag from all
                  products
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleCancelDialog}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleDelete}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

export default AttributesPage;
