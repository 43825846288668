/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { manageBaseUrl } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  // console.error("Error on " + action.type, action);
  state.error = action.error.message;
};

const shopsAdapter = createEntityAdapter({
  selectId: (shop) => shop.id,
  sortComparer: null,
});

const initialState = shopsAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const fetchShops = createAsyncThunk(
  'shops/fetchShops',
  async (userId) => {
    const resp = await axios.get(`${manageBaseUrl}/shops?userId=${userId}`);
    return resp.data.shops;
  },
);

const shopSelectSlice = createSlice({
  name: 'shopSelect',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchShops.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchShops.rejected]: (state, action) => errorResponse(state, action),
    [fetchShops.fulfilled]: (state, action) => {
      if (action.payload) {
        const shop = action.payload;
        shopsAdapter.upsertMany(state, shop);
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        // console.error("Error retrieving shops: received null value");
        state.error = 'Error retrieving shops: received null value';
      }
    },
  },
});

export const {
  selectAll: selectAllShops,
  selectById: selectShopById,
  selectIds: selectShopIds,
} = shopsAdapter.getSelectors((state) => state.shops);

export default shopSelectSlice.reducer;
