import React, { useEffect, useCallback } from 'react';
import {
  Card,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import CustomToolTip, { toolTipMessages } from 'features/toolTip/customToolTip';
import OrderList from '../../features/order/OrderList';
import {
  filterEdited,
  selectOrderFilter,
  selectAllOrders,
} from './ordersSlice';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 0),
  },
  radio: {
    '&$checked': {},
  },
  checked: {},
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  status: {
    flexGrow: 1,
  },
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function OrdersPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const memoizedSetFilter = useCallback(
    (payload) => {
      dispatch(filterEdited({ filterApplied: true }));
      dispatch(filterEdited(payload));
    },
    [dispatch],
  );

  useEffect(() => {
    const customerName = localStorage.getItem('customerName');
    if (customerName !== null) {
      memoizedSetFilter({ customerName });
    }
  }, [memoizedSetFilter, dispatch]);

  const orders = useSelector(selectAllOrders).filter(
    (order) => order.status !== 'cart',
  );
  const filter = useSelector(selectOrderFilter);
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, value) => {
    setTab(value);
  };

  const changeCustomerNameFilter = (e) => {
    memoizedSetFilter({ customerName: e.target.value });
    if (e.target.value === '') {
      localStorage.removeItem('customerName');
    }
  };

  // if there are orders, set from date to be the oldest order date
  if (orders.length !== 0) {
    const oldestOrder = orders.reduce((prev, current) => {
      if (current.createdAt < prev.createdAt) {
        return current;
      }
      return prev;
    });
    const oldestOrderDate = new Date(oldestOrder.createdAt)
      .toISOString()
      .split('T')[0];
    memoizedSetFilter({ from: oldestOrderDate });
  }

  return (
    <>
      <Typography className={classes.title} variant="h3">
        Orders <CustomToolTip text={toolTipMessages.OrdersPage} />
      </Typography>
      <Tabs indicatorColor="primary" value={tab} onChange={handleTabChange}>
        <Tab className={classes.text} label="All" {...a11yProps(0)} />
        <Tab className={classes.text} label="Pending" {...a11yProps(1)} />
        <Tab className={classes.text} label="Approved" {...a11yProps(2)} />
        <Tab className={classes.text} label="Completed" {...a11yProps(3)} />
        <Tab className={classes.text} label="Rejected" {...a11yProps(4)} />
        <Tab className={classes.text} label="Subscriptions" {...a11yProps(5)} />
      </Tabs>
      <Card className={classes.card}>
        <form className={classes.form}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={3}>
              <TextField
                id="dateFrom"
                label="From"
                type="date"
                value={filter.from}
                onChange={(e) => memoizedSetFilter({ from: e.target.value })}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="dateTo"
                label="To"
                type="date"
                value={filter.to}
                onChange={(e) => memoizedSetFilter({ to: e.target.value })}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="customerName"
                label="Customer Name"
                value={filter.customerName}
                onChange={(e) => changeCustomerNameFilter(e)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                id="search"
                label="Search"
                value={filter.query}
                onChange={(e) => memoizedSetFilter({ query: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Card>
      <OrderList tab={tab} />
    </>
  );
}

export default OrdersPage;
