/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { manageBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const orderSubscriptionsAdapter = createEntityAdapter({
  selectId: (subscription) => subscription.id,
  sortComparer: null,
});

export const fetchSubscriptionsByOrder = createAsyncThunk(
  'orders/fetchSubscriptionByOrder',
  async (orderId) => {
    const resp = await axios.get(`${manageBaseUrl}/subscriptions/by-order`, {
      params: { orderId },
    });
    return { subscriptions: resp.data };
  },
);

export const unsubscribe = createAsyncThunk(
  'subscriptions/unsubscribe',
  async (id) => {
    const resp = await axios.post(
      `${manageBaseUrl}/subscriptions/unsubscribe`,
      { id },
    );
    return { id, expiresAt: resp.expiresAt };
  },
);

const initialState = orderSubscriptionsAdapter.getInitialState({
  status: 'idle',
  error: null,
});

const orderSubscriptionsSlice = createSlice({
  name: 'orderSubscriptions',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSubscriptionsByOrder.pending]: (state) => {
      state.status = 'fetchLoading';
    },
    [unsubscribe.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchSubscriptionsByOrder.rejected]: (state, action) =>
      errorResponse(state, action),
    [unsubscribe.rejected]: (state, action) => errorResponse(state, action),
    [fetchSubscriptionsByOrder.fulfilled]: (state, action) => {
      if (action.payload) {
        const { subscriptions } = action.payload;
        orderSubscriptionsAdapter.setAll(state, subscriptions);
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving subscription Orders';
      }
    },
    [unsubscribe.fulfilled]: (state, action) => {
      if (action.payload) {
        const { id } = action.payload;
        const expiresAt = Date.now();

        orderSubscriptionsAdapter.updateOne(state, {
          id,
          changes: { expiresAt },
        });
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error unsubscribing from subscription';
      }
    },
  },
});

export const {
  selectAll: selectAllSubscriptions,
  selectById: selectSubscriptionsById,
  selectIds: selectSubscriptionIds,
} = orderSubscriptionsAdapter.getSelectors((state) => state.orderSubscriptions);

export default orderSubscriptionsSlice.reducer;
