import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  IconButton,
  Snackbar,
  Divider,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  dialogFormControl: {
    margin: theme.spacing(0, 0, 0, 1),
    minWidth: 120,
  },
  dialogSubtitles: {
    fontWeight: 600,
    margin: theme.spacing(0, 0, 0.5),
  },
  validationMsg: {
    margin: theme.spacing(2, 2, 0),
  },
  dialogDefinition: {
    margin: theme.spacing(0, 0, 0),
  },
  dialogExp: {
    margin: theme.spacing(1, 0, 0),
  },
  divider: {
    margin: theme.spacing(2, 0, 2),
  },
  openButton: {
    margin: theme.spacing(1, 0, 1),
  },
  orderedList: {
    paddingLeft: '1.25em',
    margin: theme.spacing(0, 0, 0),
    fontSize: '16px',
  },
  link: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
    color: 'white',
    padding: '1em 1.5em',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  copyBox: {
    width: '90%',
    border: 'none',
    borderBottom: '1px solid grey',
    fontSize: '16px',
  },
}));

export const UploadDataSet = injectIntl(({ onClose, landingZoneLink }) => {
  const classes = useStyles();
  let s3URI = null;
  let canonId = null;
  let s3CLI = null;

  const [open, setOpen] = React.useState(false);

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const copyToClipboard = (uri, cli) => {
    if (uri) s3URI.select();
    else if (cli) s3CLI.select();
    else canonId.select();

    document.execCommand('copy');
    setOpen(true);
  };

  return (
    <Dialog open onClose={() => onClose(false)} fullWidth scroll="paper">
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Copied link to clipboard!
        </Alert>
      </Snackbar>
      <DialogTitle>Upload a Data Set</DialogTitle>
      <DialogContent>
        <Typography className={classes.dialogDefinition}>
          Data sets in CSV files will be hosted on an AWS S3 Bucket. Ensure that
          you have correcty defined your data set schema prior to uploading.
          Data sets can be uploaded via the AWS Console or AWS CLI.
        </Typography>
        <Divider className={classes.divider} />
        <Typography className={classes.dialogSubtitles}>
          Option 1: Upload with AWS Console
        </Typography>
        <Typography>
          If you are unable to open your AWS S3 Bucket, please ensure you have
          the correct AWS account ID in your profile settings
        </Typography>
        <ol className={classes.orderedList}>
          <li>
            <Button
              href={landingZoneLink}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="primary"
              className={classes.openButton}
            >
              Open your AWS S3 Bucket
            </Button>
            <br />
            or copy and paste the below link into your browser
            <br />
            <input
              data-testid="landingZoneLink"
              className={classes.copyBox}
              readOnly
              value={landingZoneLink}
              onClick={() => copyToClipboard(true, false)}
              ref={(ref) => {
                s3URI = ref;
              }}
            />
            <IconButton
              aria-label="Clipboard"
              onClick={() => copyToClipboard(true, false)}
            >
              <AssignmentIcon />
            </IconButton>
          </li>
          <li>
            Click <b>Upload</b>
          </li>
          <li>Add the CSV files</li>
          <li>
            Expand <b>Permissions</b> and select{' '}
            <b>Specify individual ACL permissions</b> from the{' '}
            <b>Access control list (ACL)</b>
          </li>
          <li>
            Click <b>Add grantee</b> from the{' '}
            <b>Access for other AWS accounts</b> section
          </li>
          <li>
            Copy the Canonical ID below and paste it into the{' '}
            <b>Enter canonical ID</b> field <br />
            <input
              data-testid="Canonical_ID"
              className={classes.copyBox}
              readOnly
              value="2918f7326e60483b80a64d91f57ec735c590ce361829d27dd4f99c57646df29c"
              onClick={() => copyToClipboard(false, false)}
              ref={(ref) => {
                canonId = ref;
              }}
            />
            <IconButton
              aria-label="Clipboard"
              onClick={() => copyToClipboard(false, false)}
            >
              <AssignmentIcon />
            </IconButton>
          </li>
          <li>
            Allow <b>Write</b> under <b>Object ACL</b>
          </li>
          <li>
            Scroll to the bottom of the page and click <b>Upload</b>
          </li>
        </ol>
        <Divider className={classes.divider} />
        <Typography className={classes.dialogSubtitles}>
          Option 2: Upload with AWS CLI
        </Typography>
        <Typography>Example command:</Typography>
        <input
          className={classes.copyBox}
          readOnly
          value='aws s3api put-object --bucket examplebucket --key HappyFace.jpg --body c:\HappyFace.jpg --grant-full-control id="AccountA-CanonicalUserID" --profile AccountBUserProfile'
          onClick={() => copyToClipboard(false, true)}
          ref={(ref) => {
            s3CLI = ref;
          }}
        />
        <IconButton
          aria-label="Clipboard"
          onClick={() => copyToClipboard(false, true)}
        >
          <AssignmentIcon />
        </IconButton>
        <Typography className={classes.dialogExp}>
          Refer to the following documentation and the above example to upload
          to S3 using the CLI with the appropriate permissions.
        </Typography>
        <ul className={classes.link}>
          <li>
            <a
              href="https://docs.aws.amazon.com/cli/latest/reference/s3api/put-object.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Upload to S3 (put-object)
            </a>
          </li>
          <li>
            <a
              href="https://docs.aws.amazon.com/cli/latest/userguide/cli-services-s3.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Using Amazon S3 with the AWS CLI
            </a>
          </li>
        </ul>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onClose(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
});
