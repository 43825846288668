/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LOCALE } from '../../i18n';

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    locale: DEFAULT_LOCALE,
  },
  reducers: {
    changeLocale: (state, action) => {
      state.language.locale = action.payload;
    },
  },
});

export const { changeLocale } = languageSlice.actions;

export const selectLocale = (state) => state.language.locale;

export default languageSlice.reducer;
