/* eslint-disable no-nested-ternary */

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Container,
  Drawer,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Group as GroupIcon,
  Payment as PaymentIcon,
  Settings as SettingsIcon,
  Storage as StorageIcon,
  EditAttributes as EditAttributesIcon,
  // Assignment as AssignmentIcon,
  ListAlt as ListAltIcon,
  Equalizer as EqualizerIcon,
  AssignmentInd,
  ArtTrack as ArtTrackIcon,
  Store as StoreIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { Footer } from 'features/footer/Footer';
import ReactGA from 'react-ga4';
import jamlabsIcon from '../../assets/images/logo_long_default.png';
import { AppBar } from '../appbar/AppBar';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
  },

  icon: {
    height: '32px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  body: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    minHeight: '100vh',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  footer: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  footerShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  brandNavbarText: {
    '& span': {
      fontWeight: 600,
      fontSize: '14px',
    },
  },
}));

export const PageWrapper = injectIntl(({ children, signedOut }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { shopId } = useParams();
  const shopLink = useSelector((state) => state.user.shopInfo?.link);
  let homeLink = '/welcome';
  if (!signedOut) {
    homeLink = `/shop/${shopId}`;
  }

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const handleMenuClick = () => {
    if (open) GAevent('engagement', 'close_sidebar_menu', 'Close sidebar menu');
    else GAevent('engagement', 'open_sidebar_menu', 'Open sidebar menu');

    setOpen(!open);
  };

  const ListItemGroup = ({ items }) =>
    items.map(([text, icon, route]) => (
      <ListItem button key={text} component={Link} to={route}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    ));

  return (
    <div className={classes.root} data-testid="root">
      <AppBar
        open={open}
        onMenuClick={handleMenuClick}
        drawerWidth={240}
        signedOut={signedOut}
      />

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <List>
            <ListItem button component={Link} to={homeLink}>
              <ListItemIcon>
                <img
                  className={classes.icon}
                  src={jamlabsIcon}
                  alt={jamlabsIcon}
                />
              </ListItemIcon>
              {/* <ListItemText
                className={classes.brandNavbarText}
                primary="Data Shop"
              /> */}
            </ListItem>
          </List>
          <IconButton onClick={handleMenuClick}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        {!signedOut ? (
          <List>
            <ListItemGroup
              items={[
                ['Products', <StorageIcon />, `/shop/${shopId}/products`],
                [
                  'Attributes',
                  <EditAttributesIcon />,
                  `/shop/${shopId}/attributes`,
                ],
                ['Layout', <ArtTrackIcon />, `/shop/${shopId}/layout`],
              ]}
            />
            <ListItem
              button
              key="Go to Shop"
              component="a"
              target="_blank"
              href={shopLink}
            >
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Go to Shop" />
            </ListItem>
            <Divider />
            <ListItemGroup
              items={[
                ['Customers', <GroupIcon />, `/shop/${shopId}/customers`],
                ['Orders', <ListAltIcon />, `/shop/${shopId}/orders`],
                ['Analytics', <EqualizerIcon />, `/shop/${shopId}/analytics`],
                ['Payments', <PaymentIcon />, `/shop/${shopId}/payments`],
              ]}
            />
            <Divider />
            <ListItemGroup
              items={[
                ['Settings', <SettingsIcon />, `/shop/${shopId}/settings`],
              ]}
            />
          </List>
        ) : (
          <List>
            <ListItem button component={Link} to="/welcome">
              <ListItemIcon>
                <AssignmentInd />
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </ListItem>
          </List>
        )}
      </Drawer>
      <div className={classes.body}>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <Container>
            <div className={classes.drawerHeader} />
            {children}
          </Container>
        </main>
        <footer
          className={clsx(classes.footer, {
            [classes.footerShift]: open,
          })}
        >
          <Footer />
        </footer>
      </div>
    </div>
  );
});
