import React, { useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Box,
  Divider,
  LinearProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import MoreIcon from '@material-ui/icons/MoreVert';
import logo from '../../assets/images/logo_long_default_no_space.png';
import { selectInvoice, fetchInvoice, markAsPaid } from './invoiceSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  page: {
    padding: theme.spacing(5),
    minWidth: '750px',
    maxWidth: '1000px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  logo: {
    height: theme.spacing(6),
  },
  contentBox: {
    padding: theme.spacing(1, 1, 4, 1),
  },
  basicText: {
    variant: 'body1',
  },
  text: {
    color: theme.palette.text.primary,
  },
  paperHeader: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(0.5),
  },
  lowPaddingBottomBox: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  itemDescription: {
    padding: theme.spacing(0, 0, 0, 3),
  },
  invoiceItemBox: {
    padding: theme.spacing(1, 1, 2, 1),
  },
  menu: {
    float: 'right',
  },
}));

const InvoicePage = () => {
  let invoiceId = new URLSearchParams(window.location.search).get('invoiceId');
  if (!invoiceId) invoiceId = 'FBB6C2CF-3BEE-4BE8-A08C-78C13CF549A9'; // for testing
  const classes = useStyles();
  const dispatch = useDispatch();
  const invoice = useSelector(selectInvoice);
  const invoiceStatus = useSelector((state) => state.invoice.status);
  useEffect(() => {
    if (invoiceStatus === 'idle') {
      dispatch(fetchInvoice(invoiceId));
    }
  }, [dispatch, invoiceId, invoiceStatus]);
  const { currency } = invoice;
  const invoiceItems = invoice.invoiceItems.map((curItem) => ({
    title: curItem.name,
    price: curItem.price,
    description: `License: ${
      curItem.license.name
    }; Delivery Options: ${curItem.license.delivery.join(', ')}`,
  }));
  const [dialog, setDialog] = React.useState(false);

  const gridElement = {
    item: true,
    container: true,
    xs: 6,
    alignContent: 'flex-start',
  };

  const handleCancelDialog = () => {
    setDialog(false);
  };

  const SavePaidStatusDialog = () => {
    const saveStatus = () => {
      dispatch(markAsPaid(invoice.id));
      setDialog(false);
    };

    return (
      <Dialog open={dialog} onClose={handleCancelDialog}>
        <DialogTitle>Confirm Invoice as Paid?</DialogTitle>
        <DialogContent>
          Are you sure you want to mark this invoice as paid?
          <br />
          <strong>Warning: This action cannot be undone.</strong>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCancelDialog}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" onClick={saveStatus}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const invoiceLine = (text, isRight = false) => (
    <Grid item container xs={12} justify={isRight ? 'flex-end' : 'flex-start'}>
      <Typography
        className={classes.basicText}
        variant="body1"
        align={isRight ? 'right' : 'left'}
      >
        {text}
      </Typography>
    </Grid>
  );

  const headerLine = (leftText, rightText) => (
    <Paper className={classes.paperHeader} elevation={0} square>
      <Grid container>
        <Grid {...gridElement}>
          <Typography variant="body1" component="div" align="left">
            <Box fontWeight="fontWeightBold">{leftText}</Box>
          </Typography>
        </Grid>
        <Grid {...gridElement} justify="flex-end">
          <Typography variant="body1" component="div" align="right">
            <Box fontWeight="fontWeightBold">{rightText}</Box>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );

  const formatPrice = (amount) =>
    new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: currency || 'CAD',
    }).format(amount);

  const invoiceItemLine = (title, description, price, index) => (
    <Grid item container key={index} alignItems="center">
      <Grid
        item
        container
        md={10}
        sm={9}
        xs={9}
        className={classes.invoiceItemBox}
      >
        <Grid item xs={12}>
          <Typography variant="body1" component="div" align="left">
            <Box fontWeight="fontWeightBold">{title}</Box>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.itemDescription}>
          <Typography variant="body1" align="left">
            {description}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        md={2}
        sm={3}
        xs={3}
        justify="flex-end"
        className={classes.invoiceItemBox}
      >
        <Typography variant="body1" align="right">
          {formatPrice(price)}
        </Typography>
      </Grid>
      <Divider width="100%" />
    </Grid>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const paymentInfo = () => (
    <>
      <Grid item xs={12} className={classes.lowPaddingBottomBox}>
        {headerLine('Payment Method', 'Card #')}
      </Grid>
      <Grid {...gridElement} className={classes.lowPaddingBottomBox}>
        {invoiceLine(invoice.payment.method, false)}
      </Grid>
      <Grid {...gridElement} className={classes.lowPaddingBottomBox}>
        {invoiceLine(invoice.payment.info.cardNumber, true)}
      </Grid>
    </>
  );
  switch (invoice.status) {
    case 'loading':
      return <LinearProgress />;
    case 'failed':
      return (
        <Typography className={classes.text} variant="h6" align="left">
          Invoice data fetch failed.
        </Typography>
      );
    case 'succeeded':
      return (
        <div className={classes.root}>
          <Paper className={classes.page}>
            {invoice.paidStatus === 'paid' ? (
              <></>
            ) : (
              <IconButton
                aria-label="show more"
                aria-haspopup="true"
                onClick={(e) => {
                  handleMenuOpen(e);
                }}
                color="inherit"
                className={classes.menu}
              >
                <MoreIcon />
              </IconButton>
            )}
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={!!anchorEl}
              onClose={handleMenuClose}
            >
              <MenuItem
                data-testid="MarkInvoiceAsPaid"
                onClick={() => {
                  setDialog(true);
                  setAnchorEl(null);
                }}
              >
                Mark Invoice as Paid
              </MenuItem>
            </Menu>
            <Grid container>
              {invoice.paidStatus === 'paid' && (
                <Grid
                  item
                  container
                  className={classes.contentBox}
                  justify="flex-end"
                >
                  <Typography variant="h3" align="right">
                    PAID
                  </Typography>
                </Grid>
              )}
              {/* Seller Logo */}
              <Grid {...gridElement} className={classes.contentBox}>
                <img className={classes.logo} src={logo} alt="logo" />
              </Grid>
              {/* Invoice Info */}
              <Grid {...gridElement} className={classes.contentBox}>
                {invoiceLine(`Invoice #: ${invoice.id.substring(0, 5)}`, true)}
                {invoiceLine(
                  `Creation Date: ${new Date(invoice.creationDate)
                    .toString()
                    .substring(0, 15)}`,
                  true,
                )}
                {invoice.dueDate !== 0 &&
                  invoiceLine(
                    `Due Date: ${new Date(invoice.dueDate)
                      .toString()
                      .substring(0, 15)}`,
                    true,
                  )}
              </Grid>
              {/* Seller Info */}
              <Grid {...gridElement} className={classes.contentBox}>
                {invoiceLine(invoice.seller.name, false)}
                {invoice.seller.billingAddress &&
                  invoiceLine(
                    invoice.seller.billingAddress.streetAddress,
                    false,
                  )}
                {invoice.seller.billingAddress &&
                  invoiceLine(
                    `${invoice.seller.billingAddress.city}, ${invoice.seller.billingAddress.province}. ${invoice.seller.billingAddress.postalCode}`,
                    false,
                  )}
              </Grid>
              {/* Buyer Info */}
              <Grid {...gridElement} className={classes.contentBox}>
                {invoice.customer.firstName &&
                  invoiceLine(
                    `${invoice.customer.firstName} ${invoice.customer.lastName}`,
                    true,
                  )}
                {invoice.customer.companyName &&
                  invoiceLine(invoice.customer.companyName, true)}
                {invoice.customer.email &&
                  invoiceLine(invoice.customer.email, true)}
                {invoice.paidStatus === 'paid' &&
                  invoice.customer.billingAddress &&
                  invoiceLine(
                    invoice.customer.billingAddress.streetAddress,
                    true,
                  )}
                {invoice.paidStatus === 'paid' &&
                  invoice.customer.billingAddress &&
                  invoiceLine(
                    `${invoice.customer.billingAddress.city}, ${invoice.customer.billingAddress.province}. ${invoice.customer.billingAddress.postalCode}`,
                    true,
                  )}
              </Grid>
              {invoice.paidStatus === 'paid' && paymentInfo()}
              <Grid item xs={12} className={classes.lowPaddingBottomBox}>
                {headerLine('Item', `Price (${currency})`)}
              </Grid>
              {invoiceItems.map((item, index) =>
                invoiceItemLine(
                  item.title,
                  item.description,
                  item.price,
                  index,
                ),
              )}
              <Grid
                item
                container
                xs={12}
                justify="flex-end"
                className={classes.contentBox}
              >
                <Typography variant="body1" component="div" align="right">
                  <Box fontWeight="fontWeightBold">
                    {`Total: ${formatPrice(invoice.total)}`}
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <SavePaidStatusDialog />
        </div>
      );
    default:
      return null;
  }
};

export default InvoicePage;
