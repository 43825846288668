/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ReactGA from 'react-ga4';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ProvideAuth as ProvideUserAuth } from 'auth/user/userAuth';
import PrivateUserRoute from 'auth/user/PrivateRoute';
import { PageWrapper } from 'features/pageWrapper/PageWrapper';
import { jamlabsGID } from 'config';
// import theme from './app/theme';
import { useSelector } from 'react-redux';
import AnalyticsPage from './pages/management/AnalyticsPage';
import AttributesPage from './pages/management/AttributesPage';
import CustomersPage from './pages/management/CustomersPage';
import LicensesPage from './pages/management/LicensesPage';
import OrderDetailPage from './pages/OrderDetailPage';
import OrdersPage from './pages/OrdersPage';
import SettingsPage from './pages/SettingsPage';
import ManageSubscriptionsPage from './pages/management/SubscriptionManagementPage';
import PaymentsPage from './pages/management/PaymentsPage';
import ProductsPage from './pages/management/ProductsPage';
import DeliveryOptionsPage from './pages/management/DeliveryOptionsPage';
import SignInPage from './pages/management/SignInPage';
import ProfilePage from './pages/management/ProfilePage';
import NotFoundPage from './pages/NotFoundPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ShopSelectPage from './pages/management/ShopSelectPage';
import LayoutPage from './pages/management/LayoutPage';
import InvoicePage from './pages/InvoicePage';

export default function App() {
  if (process.env.NODE_ENV !== 'test' && jamlabsGID.length > 0) {
    ReactGA.initialize(jamlabsGID);
    ReactGA.send('pageview');
  }

  const darkThemeEnabled = useSelector(
    (state) => state.settings.darkThemeEnabled,
  );

  const palletType = darkThemeEnabled ? 'dark' : 'light';

  const theme = createMuiTheme({
    palette: {
      type: palletType,
      primary: {
        light: 'rgba(154, 216, 82, 1)',
        main: 'rgba(135, 209, 44, 1)',
        dark: 'rgba(119, 193, 36, 1)',
        contrastText: '#fff',
      },
      secondary: {
        light: 'rgba(140, 80, 217, 1)',
        main: 'rgba(118, 44, 209, 1)',
        dark: 'rgba(108, 39, 203, 1)',
        contrastText: '#fff',
      },
      background: {
        light: '#03fcf4',
        dark: '#8803fc',
      },
      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff',
      },
    },
  });

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ProvideUserAuth>
          <Router>
            <Switch>
              <Route exact path="/">
                <Redirect to="/welcome" />
              </Route>
              <Route path={['/shop/:shopId', '/manage/shops']}>
                {' '}
                <PageWrapper>
                  {/* Seller routes */}
                  <PrivateUserRoute exact path="/manage/shops">
                    <ShopSelectPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId">
                    {/* TODO: Change this to homepage when homepage is added */}
                    <Switch>
                      <Redirect to="/shop/:shopId/products" />
                    </Switch>
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/analytics">
                    <AnalyticsPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/attributes">
                    <AttributesPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/customers">
                    <CustomersPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/licenses">
                    <LicensesPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/products">
                    <ProductsPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/order-detail">
                    <OrderDetailPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/orders">
                    <OrdersPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/settings">
                    <SettingsPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute
                    exact
                    path="/shop/:shopId/manage-subscriptions"
                  >
                    <ManageSubscriptionsPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/payments">
                    <PaymentsPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/delivery-options">
                    <DeliveryOptionsPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/profile">
                    <ProfilePage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/layout">
                    <LayoutPage />
                  </PrivateUserRoute>
                  <PrivateUserRoute exact path="/shop/:shopId/invoice">
                    <InvoicePage />
                  </PrivateUserRoute>

                  {/* End of seller routes */}
                </PageWrapper>
              </Route>

              <Route path="/">
                <PageWrapper signedOut>
                  <Switch>
                    {/* Public routes */}
                    <Route exact path="/users/sign-in">
                      <SignInPage />
                    </Route>
                    <Route exact path="/users/reset">
                      <Route
                        render={(props) => <ResetPasswordPage {...props} />}
                      />
                    </Route>
                    <Route exact path="/welcome">
                      <Redirect to="/users/sign-in" />
                    </Route>
                    <Route render={NotFoundPage} />
                  </Switch>
                </PageWrapper>
              </Route>
            </Switch>
          </Router>
        </ProvideUserAuth>
      </ThemeProvider>
    </>
  );
}
