/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { manageBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const persistedTheme = localStorage.getItem('mode');

const initialState = {
  status: 'idle',
  primaryLightColor: '#000000',
  secondaryLightColor: '#000000',
  lightFontColor: '#000000',
  primaryDarkColor: '#000000',
  secondaryDarkColor: '#000000',
  darkFontColor: '#000000',
  isDarkLight: false, // boolean for if dark mode colours are the same as light mode colours
  darkThemeEnabled: persistedTheme ? JSON.parse(persistedTheme) : false,
  error: null,
};

export const fetchThemeByShop = createAsyncThunk(
  'settings/fetchThemeByShop',
  async (shopId) => {
    const resp = await axios.get(`${manageBaseUrl}/shop-theme`, {
      params: { shopId },
    });
    return resp.data;
  },
);

export const updateThemeByShop = createAsyncThunk(
  'settings/updateThemeByShop',
  async ({ shopId, themeObject }) => {
    const resp = await axios.post(`${manageBaseUrl}/shop-theme/update`, {
      shopId,
      updatedShopTheme: themeObject,
    });
    if (resp.status !== 200) {
      return null;
    }
    return themeObject;
  },
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    themeToggle(state, action) {
      state.darkThemeEnabled = action.payload;
      localStorage.setItem('mode', JSON.stringify(state.darkThemeEnabled));
    },
  },
  extraReducers: {
    [fetchThemeByShop.pending]: (state) => {
      state.status = 'loading';
    },
    [updateThemeByShop.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchThemeByShop.rejected]: (state, action) =>
      errorResponse(state, action),
    [updateThemeByShop.rejected]: (state, action) =>
      errorResponse(state, action),
    [fetchThemeByShop.fulfilled]: (state, action) => {
      if (action.payload) {
        const {
          primaryLightColor,
          secondaryLightColor,
          lightFontColor,
          primaryDarkColor,
          secondaryDarkColor,
          darkFontColor,
          isDarkLight,
        } = action.payload;
        state.primaryLightColor = primaryLightColor;
        state.secondaryLightColor = secondaryLightColor;
        state.lightFontColor = lightFontColor;
        state.primaryDarkColor = primaryDarkColor;
        state.secondaryDarkColor = secondaryDarkColor;
        state.darkFontColor = darkFontColor;
        state.isDarkLight = isDarkLight;
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving Settings: received null value';
      }
    },
    [updateThemeByShop.fulfilled]: (state, action) => {
      if (action.payload) {
        const {
          primaryLightColor,
          secondaryLightColor,
          lightFontColor,
          primaryDarkColor,
          secondaryDarkColor,
          darkFontColor,
          isDarkLight,
        } = action.payload;
        state.primaryLightColor = primaryLightColor;
        state.secondaryLightColor = secondaryLightColor;
        state.lightFontColor = lightFontColor;
        state.primaryDarkColor = primaryDarkColor;
        state.secondaryDarkColor = secondaryDarkColor;
        state.darkFontColor = darkFontColor;
        state.isDarkLight = isDarkLight;
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error updating Settings: received null value';
      }
    },
  },
});

export default settingsSlice.reducer;
