/* eslint-disable no-nested-ternary */

import React from 'react';
import { injectIntl } from 'react-intl';
import messages from 'app/message';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Typography,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  Select,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  IconButton,
  Checkbox,
} from '@material-ui/core';
import { Add as AddIcon, Cancel as CancelIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createDataSet } from 'pages/management/ProductsPage/dataSetsSlice';
import { selectShopId } from 'pages/management/SignInPage/shopSignInSlice';
import { Alert } from '@material-ui/lab';
import { v4 as uuidv4 } from 'uuid';
import { updateLicenseFilterables } from 'pages/management/LicensesPage/licensesSlice';

const useStyles = makeStyles((theme) => ({
  dataTypeColumn: {
    margin: theme.spacing(0, 0, 0, 1),
    minWidth: 120,
  },
  dialogSubtitles: {
    fontWeight: 800,
    margin: theme.spacing(2, 0, 0.5),
  },
  chip: {
    margin: theme.spacing(1, 0, 0),
  },
  columnDefinition: {
    margin: theme.spacing(0, 0, 1),
  },
  deleteColumn: {
    margin: theme.spacing(0.5, 2.5, 0),
  },
  validationMsg: {
    margin: theme.spacing(2, 2, 0),
  },
}));

export const DataSetDraft = injectIntl(
  ({ onClose, onConfirm, product, dataSet: initial }) => {
    const classes = useStyles();
    const [valid, setValid] = React.useState(true);
    const [dataSet, setDataSet] = React.useState({
      productId: product.id,
      columnTypes: initial?.columns.map((c) => initial.types[c]) || ['str'],
      separator: initial?.separator || ',',
      columns: initial?.columns || [''],
      partitionColumns: initial?.columns.map(
        (c) => initial.partitionColumns.indexOf(c) !== -1,
      ) || [false],
    });
    const dispatch = useDispatch();
    const shopId = useSelector(selectShopId);
    const awsUserId = useSelector(
      (state) => state.user?.user?.awsUserId || undefined,
    );

    const handleColumnChange = (e, index) => {
      const columns = [...dataSet.columns];
      columns[index] = e.target.value;
      setDataSet({
        ...dataSet,
        columns,
      });
    };

    const handleAddColumn = () => {
      const columns = [...dataSet.columns, ``];
      const columnTypes = [...dataSet.columnTypes, 'str'];
      const partitionColumns = [...dataSet.partitionColumns, false];
      setDataSet({
        ...dataSet,
        columns,
        columnTypes,
        partitionColumns,
      });
    };

    const handleColumnTypeChange = (e, index) => {
      const columnTypes = [...dataSet.columnTypes];
      columnTypes[index] = e.target.value;
      setDataSet({
        ...dataSet,
        columnTypes,
      });
    };

    const handlePartitionColumnChange = (index) => {
      const partitionColumns = [...dataSet.partitionColumns];
      partitionColumns[index] = !partitionColumns[index];
      setDataSet({
        ...dataSet,
        partitionColumns,
      });
    };

    const handleRemoveColumn = (index) => {
      const columns = [...dataSet.columns];
      const columnTypes = [...dataSet.columnTypes];
      const partitionColumns = [...dataSet.partitionColumns];
      columns.splice(index, 1);
      columnTypes.splice(index, 1);
      partitionColumns.splice(index, 1);
      setDataSet({
        ...dataSet,
        columns,
        columnTypes,
        partitionColumns,
      });
    };

    const handleConfirm = () => {
      if (dataSet.columns.includes('')) {
        setValid(false);
      } else {
        setValid(true);
        const dataSetDraft = {
          shopId,
          productId: dataSet.productId,
          partitionColumns: JSON.stringify(
            dataSet.columns.filter(
              (_element, index) => dataSet.partitionColumns[index],
            ),
          ),
          separator: dataSet.separator,
          columns: JSON.stringify(dataSet.columns),
          types: JSON.stringify({
            ...dataSet.columns.reduce(
              (acc, curr, index) => ({
                ...acc,
                [curr]: dataSet.columnTypes[index],
              }),
              {},
            ),
          }),
        };
        const datasetId = uuidv4().toUpperCase();
        dispatch(createDataSet({ dataSetDraft, awsUserId, datasetId }));
        dispatch(
          updateLicenseFilterables({
            productId: dataSet.productId,
            datasetColumns: dataSet.columns,
            shopId: shopId,
          }),
        );
      }
    };
    const dataTypes = [
      { key: 'str', label: messages.DATA_TYPE_str.defaultMessage },
      {
        key: 'timestamp',
        label: messages.DATA_TYPE_timestamp.defaultMessage,
      },
      { key: 'float64', label: messages.DATA_TYPE_float64.defaultMessage },
      { key: 'int64', label: messages.DATA_TYPE_int64.defaultMessage },
    ];

    return (
      <Dialog open onClose={() => onClose(false)} fullWidth scroll="paper">
        <div className={classes.validationMsg}>
          {!valid && awsUserId && (
            <Alert severity="error">
              {' '}
              Do not leave partition names or column names empty. Please define
              the dataset partitions and columns.{' '}
            </Alert>
          )}
          {!awsUserId && (
            <Alert severity="error">
              You do not have an AWS user id associated with your account.
              Please head to the Profile page to set up your AWS user id.
            </Alert>
          )}
        </div>

        <DialogTitle>Define a Data Set</DialogTitle>

        <DialogContent>
          <Typography className={classes.columnDefinition}>
            {' '}
            Define table partitions and columns before uploading a dataset.
            Ensure that the ordering of columns matches that of the dataset for
            upload.
          </Typography>
          <Typography className={classes.dialogSubtitles}>Columns</Typography>
          <Grid container>
            <Grid item xs={1} container>
              <InputLabel shrink> Index</InputLabel>
            </Grid>
            <Grid item xs={4} container>
              <InputLabel shrink> Column Name</InputLabel>
            </Grid>
            <Grid item xs={3} container>
              <InputLabel shrink className={classes.dataTypeColumn}>
                Data type
              </InputLabel>
            </Grid>
            <Grid item xs={3} container>
              <Grid item xs={6} container>
                <InputLabel shrink>Partition</InputLabel>
              </Grid>
            </Grid>
          </Grid>
          {dataSet.columns.map((column, index) => (
            /* eslint-disable react/no-array-index-key */

            <Grid
              container
              key={`column${index}`}
              className={classes.columnDefinition}
            >
              <Grid item xs={1} container direction="column" justify="flex-end">
                <Typography>{index}</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  value={column}
                  placeholder="Enter Column Name"
                  inputProps={{ 'data-testid': `ColumnName${index}` }}
                  onChange={(e) => handleColumnChange(e, index)}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.dataTypeColumn}>
                  <Select
                    value={dataSet.columnTypes[index]}
                    onChange={(e) => handleColumnTypeChange(e, index)}
                  >
                    {dataTypes.map((type) => (
                      <MenuItem key={type.key} value={type.key}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item container xs={3}>
                <Grid item container xs={6}>
                  <Checkbox
                    inputProps={{ 'data-testid': `partition${index}` }}
                    checked={dataSet.partitionColumns[index]}
                    onChange={() => handlePartitionColumnChange(index)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={1} container>
                <div className={classes.deleteColumn}>
                  <IconButton
                    data-testid={`Delete${index}`}
                    color="secondary"
                    size="small"
                    onClick={() => handleRemoveColumn(index)}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          ))}
          <Chip
            data-testid="AddRow"
            className={classes.chip}
            label={<AddIcon />}
            onClick={handleAddColumn}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleConfirm();
              onConfirm();
            }}
            disabled={!awsUserId}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
