import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  HelpOutline as HelpOutlineIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Link, useParams } from 'react-router-dom';
import { selectProductById } from 'pages/management/ProductsPage/productsSlice';
import {
  fetchDataSets,
  selectAllDataSets,
} from 'pages/management/ProductsPage/dataSetsSlice';
import ReactGA from 'react-ga4';
import License from '../../../features/license/License';
import {
  filterEdited,
  fetchLicenses,
  createLicense,
  selectAllLicenses,
  selectFilter,
} from './licensesSlice';

import { selectShopId } from '../SignInPage/shopSignInSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    padding: theme.spacing(2, 0),
    color: theme.palette.text.primary,
  },
  form: {
    flexGrow: 1,
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  searchBar: {
    flexGrow: 1,
  },
  loading: {
    textAlign: 'center',
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

function LicensingPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const licensesStatus = useSelector((state) => state.licenses.status);
  const filter = useSelector(selectFilter);
  let shopId = useSelector(selectShopId);
  const dataSetsStatus = useSelector((s) => s.dataSets.status);
  const editRecentlyCreated = useSelector(
    (state) => state.licenses.editRecentlyCreated,
  );
  const currency = useSelector((state) => state.licenses.currency);
  const urlShopId = useParams().shopId;
  if (!shopId) shopId = urlShopId;
  let associatedProductId = new URLSearchParams(window.location.search).get(
    'productId',
  );
  if (!associatedProductId)
    associatedProductId = 'AFEDBFA3-171D-42E0-8A5B-EF9C0C45EFA5'; // for testing

  const associatedProduct = useSelector((state) =>
    selectProductById(state, associatedProductId),
  );
  const dataSets = useSelector(selectAllDataSets);
  const licenses = useSelector(selectAllLicenses)
    .filter((license) => {
      const { productId } = license;
      return (
        (filter.query
          ? license.name.toUpperCase().includes(filter.query.toUpperCase())
          : true) && productId === associatedProductId
      );
    })
    .sort((a, b) => {
      switch (filter.sortParam) {
        case 0:
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        case 1:
          if (a.name < b.name) return 1;
          if (a.name > b.name) return -1;
          return 0;
        case 2:
          if (a.price < b.price) return -1;
          if (a.price > b.price) return 1;
          return 0;
        case 3:
          if (a.price < b.price) return 1;
          if (a.price > b.price) return -1;
          return 0;
        case 4:
          if (a.createdAt < b.createdAt) return -1;
          if (a.createdAt > b.createdAt) return 1;
          return 0;
        case 5:
          if (a.createdAt < b.createdAt) return 1;
          if (a.createdAt > b.createdAt) return -1;
          return 0;
        default:
          return 0;
      }
    });

  useEffect(() => {
    if (!shopId) return;
    if (licensesStatus === 'idle') {
      dispatch(fetchLicenses({ shopId }));
    }
  }, [licensesStatus, shopId, dispatch]);

  useEffect(() => {
    if (dataSetsStatus === 'idle') {
      dispatch(fetchDataSets(shopId));
    }
  }, [dataSetsStatus, shopId, dispatch]);

  useEffect(
    () => () => {
      if (window.onbeforeunload !== null) window.onbeforeunload = null;
    },
    [],
  );

  if (!shopId) return <Redirect to="/users/sign-in" />;

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const LicenseList = () =>
    licenses.map((license) => (
      <License
        key={license.id}
        license={license}
        dataSets={dataSets}
        associatedProduct={associatedProduct}
        viewState={
          editRecentlyCreated != null && editRecentlyCreated === license.id
            ? 1
            : 0
        }
        currency={currency}
      />
    ));

  const setFilter = (payload) => {
    dispatch(filterEdited(payload));
  };

  const handleNewClick = () => {
    dispatch(
      createLicense({
        shopId,
        productId: associatedProductId,
        currency,
      }),
    );

    GAevent('engagement', 'licenses_create_new', 'Create new license');

    licenses.sort((a, b) => {
      switch (filter.sortParam) {
        case 0:
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        case 1:
          if (a.name < b.name) return 1;
          if (a.name > b.name) return -1;
          return 0;
        case 2:
          if (a.price < b.price) return -1;
          if (a.price > b.price) return 1;
          return 0;
        case 3:
          if (a.price < b.price) return 1;
          if (a.price > b.price) return -1;
          return 0;
        case 4:
          if (a.createdAt < b.createdAt) return -1;
          if (a.createdAt > b.createdAt) return 1;
          return 0;
        case 5:
          if (a.createdAt < b.createdAt) return 1;
          if (a.createdAt > b.createdAt) return -1;
          return 0;
        default:
          return 0;
      }
    });
  };

  const SortBySelect = () => (
    <FormControl>
      <InputLabel id="sort-by-select-label">Sort By:</InputLabel>
      <Select
        labelId="sort-by-select-label"
        value={filter.sortParam}
        onChange={(e) => {
          setFilter({ sortParam: e.target.value });
          GAevent(
            'engagement',
            `licenses_sort_by_${filter.sortParam}`,
            'Sort licenses',
          );
        }}
      >
        <MenuItem value={0}>License Name (A-Z)</MenuItem>
        <MenuItem value={1}>License Name (Z-A)</MenuItem>
        <MenuItem value={2}>Price (Lowest-Highest)</MenuItem>
        <MenuItem value={3}>Price (Highest-Lowest)</MenuItem>
        <MenuItem value={4}>Date (Oldest to Newest)</MenuItem>
        <MenuItem value={5}>Date (Newest to Oldest)</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <>
      <Typography className={classes.title} variant="h3">
        Your Licenses <HelpOutlineIcon fontSize="large" />
      </Typography>
      <form className={classes.form}>
        <TextField
          id="search"
          label="Search"
          className={classes.searchBar}
          value={filter.query}
          onChange={(e) => setFilter({ query: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <SortBySelect />
      </form>
      <Grid container justify="space-between" alignItems="flex-end">
        <Typography className={classes.text} variant="caption">
          {licenses.length} of your licenses.
        </Typography>
        <Button onClick={handleNewClick} variant="contained">
          New License
        </Button>
      </Grid>
      {licensesStatus === 'fetchLoading' ? (
        <Typography className={classes.loading} variant="h5">
          Loading...
        </Typography>
      ) : (
        <div data-testid="LicenseList">
          <LicenseList />
        </div>
      )}
      <Button component={Link} to="./products" variant="contained">
        Back to Products
      </Button>
    </>
  );
}

export default LicensingPage;
