import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormGroup,
  TextField,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from 'app/message';
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { selectShopId } from 'pages/management/SignInPage/shopSignInSlice';
import { useDispatch, useSelector } from 'react-redux';
import { updateProduct } from 'pages/management/ProductsPage/productsSlice';
import {
  updateLicense,
  deleteLicense,
} from 'pages/management/LicensesPage/licensesSlice';
import ReactGA from 'react-ga4';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  edit: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.short,
    }),
  },
  card: {
    padding: theme.spacing(2, 2, 0),
  },
  alert: {
    margin: theme.spacing(0, 0, 1),
  },
  content: {
    padding: theme.spacing(0, 2, 0),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  licenseSubtitles: {
    fontWeight: 800,
  },
  licenseTitle: {
    margin: theme.spacing(1, 0, 0),
  },
  license: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  select: {
    margin: theme.spacing(2.2, 2, 0, 1),
    display: 'inline-flex',
    alignItems: 'center',
  },
  required: {
    margin: theme.spacing(1, 0),
  },
  form: {
    '& > *': {
      padding: theme.spacing(1, 0),
    },
  },
  warning: {
    color: 'red',
  },
}));

const licenseState = {
  view: 0,
  edit: 1,
};

const tableColumns = [
  { id: 'Column', label: 'Column', minWidth: 170 },
  { id: 'Single Value', label: 'Single Value', minWidth: 100 },
  {
    id: 'Multiple Values',
    label: 'Multiple Values',
    minWidth: 170,
  },
];

const License = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [license, setLicense] = React.useState(props.license);
  const { associatedProduct } = props;
  const [tab, setTab] = React.useState(props.viewState);
  const [dialog, setDialog] = React.useState(false);
  const [nameWarning, setNameWarning] = React.useState(false);
  const [awsUserIdWarning, setAwsUserIdWarning] = React.useState(false);
  const [deliveryWarning, setDeliveryWarning] = React.useState(false);
  const [priceWarning, setPriceWarning] = React.useState(false);
  const [price, setPrice] = React.useState(license.price);
  const [revoke, setRevoke] = React.useState(false);
  const [draftStatus, setDraftStatus] = React.useState(license.draftStatus);

  const shopId = useSelector(selectShopId);
  const associatedDataSets = props.dataSets.filter(
    (ds) => ds.productId === license.productId,
  );

  let newestDataSet;
  if (associatedDataSets.length !== 0) {
    newestDataSet = associatedDataSets.reduce((prev, curr) => {
      if (prev.createdAt <= curr.createdAt) {
        return curr;
      }
      return prev;
    });
  }

  const dataSet = {
    productId: associatedProduct.id,
    columnTypes: newestDataSet?.columns?.map((c) => newestDataSet.types[c]) || [
      'str',
    ],
    separator: newestDataSet?.separator || ',',
    columns: newestDataSet?.columns || [''],
    columnFilterable: newestDataSet?.columns?.map((c) =>
      newestDataSet.filterable ? newestDataSet.filterable[c] : false,
    ) || [false],
    partitionColumns: newestDataSet?.columns?.map(
      (c) => newestDataSet.partitionColumns.indexOf(c) !== -1,
    ) || [false],
  };

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const handleEditClick = () => {
    setTab(licenseState.edit);

    GAevent('engagement', 'licenses_edit_license', 'Edit license');
  };

  const setUnloadWindow = (windowState) => {
    if (windowState && window.onbeforeunload === null) {
      window.onbeforeunload = () => true;
    } else if (!windowState && window.onbeforeunload !== null) {
      window.onbeforeunload = null;
    }
  };

  const handleCancel = () => {
    setUnloadWindow(false);
    setLicense(props.license);
    setTab(licenseState.view);
  };

  const handleCancelDialog = () => {
    setDialog(false);
  };

  const handleSaveClick = async () => {
    let deliveryWarningFlag = false;
    let licenseNameWarningFlag = false;
    let priceWarningFlag = false;

    if (license.name.trim().length === 0) {
      setNameWarning(true);
      licenseNameWarningFlag = true;
    }

    if (license.delivery.length === 0) {
      setDeliveryWarning(true);
      deliveryWarningFlag = true;
    }

    if (license.price < 0) {
      setPriceWarning(true);
      priceWarningFlag = true;
    }

    if (!deliveryWarningFlag && !licenseNameWarningFlag && !priceWarningFlag) {
      setDraftStatus('published');
      handleLicenseChange({ draftStatus: 'published' });
      handleConfirm();
      setUnloadWindow(false);
      dispatch(updateLicense(license));
      setTab(licenseState.view);
    }
  };

  const handleDeleteClick = () => {
    setDialog(true);
  };

  const handleDelete = () => {
    updateAssociatedProduct(associatedProduct);
    dispatch(deleteLicense({ id: license.id, revoke }));
    setDialog(false);

    GAevent('engagement', 'licenses_delete_license', 'Delete license');
  };

  const updateAssociatedProduct = (product) => {
    const changeset = { ...product };
    // filter out the license that is supposed to be updated
    changeset.licenses = changeset.licenses.filter(
      (licenseId) => licenseId !== license.id,
    );
    dispatch(updateProduct(changeset));
  };

  useEffect(() => {
    if (
      license.contentSpan === 'allRows' &&
      license.pricingUnit === 'perUniqueValue'
    )
      handleLicenseChange({ pricingUnit: 'fixedPrice' });
  });

  const handleLicenseChange = (e) => {
    const prop = Object.keys(e)[0];
    const value = e[prop];
    setLicense({ ...license, [prop]: value });
    setUnloadWindow(true);
    setDeliveryWarning(false);
    setAwsUserIdWarning(false);
    setNameWarning(false);
    setPriceWarning(false);
  };

  const handlePriceChange = (e) => {
    setPrice(e.target.value);
    if (e.target.value === '') {
      handleLicenseChange({ price: 0 });
    } else {
      handleLicenseChange({ price: parseFloat(e.target.value) });
    }
  };

  const ViewContent = () => (
    <>
      <CardHeader className={classes.card} title={license.name} />
      <CardContent className={classes.content}>
        <Typography variant="body2" color="textSecondary" component="p">
          {license.description}
        </Typography>
        <Typography className={classes.licenseTitle} variant="h6">
          <FormattedMessage {...messages.LABEL_PRICING} />
        </Typography>
        <div className={classes.flex}>
          <Typography
            className={classes.license}
            component="span"
            variant="body2"
          >
            {license.price.toString()}{' '}
            <FormattedMessage
              {...messages[`CURRENCY_${props.currency.toUpperCase()}`]}
            />
          </Typography>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button onClick={handleDeleteClick}>Delete</Button>
        <Button
          className={classes.edit}
          onClick={handleEditClick}
          variant="contained"
          color="primary"
        >
          Edit
        </Button>
      </CardActions>
      {draftStatus === 'draft' ? (
        <Typography>This license is still a draft.</Typography>
      ) : null}
    </>
  );

  const DeleteDialog = () => (
    <Dialog open={dialog} onClose={handleCancelDialog}>
      <DialogTitle>Confirm Delete?</DialogTitle>
      <DialogContent>
        This license exists in {associatedProduct.name}. Are you sure you want
        to delete this license?
        <Typography className={classes.warning}>
          Warning: By clicking Delete, this license will be permanantly deleted,
          along with any JamLabs AWS Resources that allow customers to gain
          access to their data associated with this product.
        </Typography>
      </DialogContent>
      <DialogActions>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="s3Share"
                className={classes.checkbox}
                checked={revoke}
                onChange={(e) => {
                  setRevoke(e.target.checked);

                  if (e.target.checked)
                    GAevent(
                      'engagement',
                      'licenses_revoke_S3',
                      'Revoke S3 on delete',
                    );
                }}
              />
            }
            label="Revoke Access to S3?"
          />
        </FormGroup>
        <Button variant="contained" onClick={handleCancelDialog}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleConfirm = () => {
    const dataSetDraft = {
      shopId,
      productId: dataSet.productId,
      partitionColumns: JSON.stringify(
        dataSet.columns.filter(
          (_element, index) => dataSet.partitionColumns[index],
        ),
      ),
      separator: dataSet.separator,
      columns: JSON.stringify(dataSet.columns),
      types: JSON.stringify({
        ...dataSet.columns.reduce(
          (acc, curr, index) => ({
            ...acc,
            [curr]: dataSet.columnTypes[index],
          }),
          {},
        ),
      }),
      filterable: {
        ...dataSet.columns.reduce(
          (acc, curr, index) => ({
            ...acc,
            [curr]: dataSet.columnFilterable[index],
          }),
          {},
        ),
      },
    };
    handleLicenseChange({ filterable: dataSetDraft.filterable });
  };

  const handleFilterableColumnChange = (e) => {
    const newValue = !e.value;
    const { column } = e;
    const { type } = e;
    let licenseFilterable = license.filterable;
    if (type === 'singleValue') {
      licenseFilterable = {
        ...licenseFilterable,
        [column]: { multipleValues: false, singleValue: newValue },
      };
    } else if (type === 'multipleValues') {
      licenseFilterable = {
        ...licenseFilterable,
        [column]: { multipleValues: newValue, singleValue: newValue },
      };
    }
    setLicense({
      ...license,
      filterable: licenseFilterable,
    });
    setUnloadWindow(true);
    setDeliveryWarning(false);
    setAwsUserIdWarning(false);
    setNameWarning(false);
    setPriceWarning(false);
  };

  return (
    <Card className={classes.root} data-testid={license.name}>
      <DeleteDialog />
      {tab === licenseState.view && <ViewContent />}
      {tab === licenseState.edit && (
        <>
          <CardHeader
            className={classes.card}
            title={
              <div data-testid="DescriptionTitle">
                Description
                <HelpOutlineIcon fontSize="small" />
              </div>
            }
          />
          <CardContent className={classes.card}>
            <form>
              <Grid
                container
                className={classes.form}
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={6}>
                  {nameWarning ? (
                    <Alert severity="error">A License Name is required</Alert>
                  ) : null}
                  <TextField
                    className={classes.required}
                    variant="outlined"
                    label="Name"
                    inputProps={{ 'data-testid': 'NameField' }}
                    defaultValue={
                      license.name === 'License Name' ? '' : license.name
                    }
                    placeholder="License Name"
                    onChange={(e) =>
                      handleLicenseChange({ name: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    inputProps={{ 'data-testid': 'DescriptionField' }}
                    rows={3}
                    label="Description"
                    defaultValue={
                      license.description === 'Enter your description here'
                        ? ''
                        : license.description
                    }
                    placeholder="Enter your description here"
                    onChange={(e) =>
                      handleLicenseChange({ description: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <CardHeader
            className={classes.card}
            title={
              <div>
                Agreement Body
                <HelpOutlineIcon fontSize="small" />
              </div>
            }
          />
          <CardContent className={classes.card}>
            <form>
              <Grid
                container
                className={classes.form}
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    inputProps={{ 'data-testid': 'AgreementBodyField' }}
                    rows={3}
                    label="License Body"
                    defaultValue={
                      license.agreementBody === 'Enter your agreement body here'
                        ? ''
                        : license.agreementBody
                    }
                    placeholder="Enter your agreement body here"
                    onChange={(e) =>
                      handleLicenseChange({ agreementBody: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <CardHeader
            className={classes.card}
            title={
              <div>
                Delivery Options Available
                <HelpOutlineIcon fontSize="small" />
              </div>
            }
          />
          <CardContent className={classes.card}>
            {deliveryWarning ? (
              <Alert severity="error">
                A delivery option must be selected before saving
              </Alert>
            ) : null}
            <Grid container className={classes.form}>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ 'data-testid': 'ApiCheckBox' }}
                    checked={license.delivery.includes('api')}
                    onChange={() => {
                      if (!license.delivery.includes('api'))
                        handleLicenseChange({
                          delivery: [...license.delivery, 'api'],
                        });
                      else
                        handleLicenseChange({
                          delivery: license.delivery.filter(
                            (method) => method !== 'api',
                          ),
                        });
                    }}
                  />
                }
                label="API"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ 'data-testid': 'aws' }}
                    checked={license.delivery.includes('aws')}
                    onChange={() => {
                      if (!license.delivery.includes('aws'))
                        handleLicenseChange({
                          delivery: [...license.delivery, 'aws'],
                        });
                      else
                        handleLicenseChange({
                          delivery: license.delivery.filter(
                            (method) => method !== 'aws',
                          ),
                        });
                    }}
                  />
                }
                label="Cloud Transfer to AWS S3"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ 'data-testid': 'download' }}
                    checked={license.delivery.includes('download')}
                    onChange={() => {
                      if (!license.delivery.includes('download'))
                        handleLicenseChange({
                          delivery: [...license.delivery, 'download'],
                        });
                      else
                        handleLicenseChange({
                          delivery: license.delivery.filter(
                            (method) => method !== 'download',
                          ),
                        });
                    }}
                  />
                }
                label="Download Latest Data Link"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ 'data-testid': 'SFTP-buyer-hosted' }}
                    checked={license.delivery.includes('SFTP-buyer-hosted')}
                    onChange={() => {
                      if (!license.delivery.includes('SFTP-buyer-hosted'))
                        handleLicenseChange({
                          delivery: [...license.delivery, 'SFTP-buyer-hosted'],
                        });
                      else
                        handleLicenseChange({
                          delivery: license.delivery.filter(
                            (method) => method !== 'SFTP-buyer-hosted',
                          ),
                        });
                    }}
                  />
                }
                label="SFTP (Buyer hosted server)"
              />
            </Grid>
          </CardContent>
          <CardHeader
            className={classes.card}
            title={
              <div>
                Content Span
                <HelpOutlineIcon fontSize="small" />
              </div>
            }
          />
          <CardContent className={classes.card}>
            <CardContent>
              <Grid>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Includes</FormLabel>
                  <RadioGroup
                    row
                    aria-label="Includes"
                    value={license.contentSpan}
                    onChange={(e) =>
                      handleLicenseChange({ contentSpan: e.target.value })
                    }
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="allRows"
                      control={<Radio />}
                      label="All Rows"
                    />
                    <FormControlLabel
                      data-testid="FilteredByBuyer"
                      value="filteredByBuyer"
                      control={<Radio />}
                      label="Filtered by Buyer"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid>
                {license.contentSpan === 'filteredByBuyer' ? (
                  <>
                    {newestDataSet ? (
                      <Grid>
                        <CardContent>
                          <Typography className={classes.licenseSubtitles}>
                            Filterable Columns
                          </Typography>
                        </CardContent>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {tableColumns.map((tableColumn) => (
                                  <TableCell
                                    key={tableColumn.id}
                                    align={tableColumn.align}
                                    style={{
                                      top: 57,
                                      minWidth: tableColumn.minWidth,
                                    }}
                                  >
                                    {tableColumn.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            {dataSet?.columns?.map((column) => (
                              /* eslint-disable react/no-array-index-key */
                              <TableBody key={column}>
                                <TableRow>
                                  <TableCell>
                                    <Typography>{column}</Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Radio
                                      checked={
                                        license?.filterable[column]?.singleValue
                                      }
                                      onClick={() =>
                                        handleFilterableColumnChange({
                                          value:
                                            license.filterable[column]
                                              ?.singleValue,
                                          column,
                                          type: 'singleValue',
                                        })
                                      }
                                      name="singleValue-radio-buttons"
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Radio
                                      checked={
                                        license.filterable[column]
                                          ?.multipleValues
                                      }
                                      onClick={() =>
                                        handleFilterableColumnChange({
                                          value:
                                            license.filterable[column]
                                              ?.multipleValues,
                                          column,
                                          type: 'multipleValues',
                                        })
                                      }
                                      name="multipleValues-radio-buttons"
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            ))}
                          </Table>
                        </TableContainer>
                        {awsUserIdWarning ? (
                          <Alert severity="error">
                            An Aws user id must be added to specify Filterable
                            Columns.
                          </Alert>
                        ) : null}
                      </Grid>
                    ) : (
                      <Alert severity="error">
                        A Data Set must be defined to specify Filterable
                        columns.
                      </Alert>
                    )}
                  </>
                ) : null}
              </Grid>
            </CardContent>
          </CardContent>
          <CardHeader
            className={classes.card}
            title={
              <div>
                <FormattedMessage {...messages.LABEL_PRICING} />
                <HelpOutlineIcon fontSize="small" />
              </div>
            }
          />
          <CardContent className={classes.card}>
            {priceWarning ? (
              <Alert severity="error">Price must be positive</Alert>
            ) : null}
            <form>
              <Grid
                container
                className={classes.form}
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    label="Price"
                    inputProps={{ 'data-testid': 'PriceField' }}
                    value={price}
                    type="number"
                    onChange={(e) => handlePriceChange(e)}
                  />
                  <span className={classes.select}>
                    {props.currency.toUpperCase()}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Pricing Unit</FormLabel>
                    <RadioGroup
                      row
                      aria-label="Frequency"
                      name="row-radio-buttons-group"
                      value={license.pricingUnit}
                      onChange={(e) =>
                        handleLicenseChange({ pricingUnit: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="fixedPrice"
                        control={<Radio />}
                        label="Fixed Price"
                      />
                      <FormControlLabel
                        value="perGB"
                        control={<Radio />}
                        label="Per GB Accessed"
                      />
                      {license.contentSpan === 'filteredByBuyer' ? (
                        <FormControlLabel
                          value="perUniqueValue"
                          control={<Radio />}
                          label="Per unique value filtered"
                        />
                      ) : null}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <CardContent className={classes.card}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Frequency</FormLabel>
              <RadioGroup
                row
                aria-label="Frequency"
                name="row-radio-buttons-group"
                value={license.billingFrequency}
                onChange={(e) =>
                  handleLicenseChange({ billingFrequency: e.target.value })
                }
              >
                <FormControlLabel
                  value="once"
                  control={<Radio />}
                  label="Once"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio />}
                  label="Monthly with Updates"
                />
              </RadioGroup>
            </FormControl>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default injectIntl(License);
