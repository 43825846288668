import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  fetchSubscriptionsByOrder,
  selectAllSubscriptions,
  unsubscribe,
} from './orderSubscriptionsSlice';
import { selectAllLicenses } from '../LicensesPage/licensesSlice';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 0),
  },
  text: {
    color: theme.palette.text.primary,
  },
  radio: {
    '&$checked': {},
  },
  checked: {},
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  status: {
    flexGrow: 1,
  },
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function SubscriptionManagementPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const orderId = new URLSearchParams(window.location.search).get('orderId');
  const licenses = useSelector(selectAllLicenses);
  const subscriptionStatus = useSelector(
    (state) => state.orderSubscriptions.status,
  );
  const subscriptions = useSelector(selectAllSubscriptions);
  const [deactivateDialog, setDeactivateDialog] = React.useState(false);
  const [currSubscriptionId, setCurSubscriptionId] = React.useState('');

  useEffect(() => {
    if (subscriptionStatus === 'idle') {
      dispatch(fetchSubscriptionsByOrder(orderId));
    }
  });

  const formattedDate = (epochUnix) => {
    const date = new Date(epochUnix);
    return date.toString().substring(0, 15);
  };

  const findAssociatedLicense = (licenseId) => {
    const license = licenses.find((lic) => lic.id === licenseId);
    return license.name;
  };

  const handleUnsubscribe = () => {
    dispatch(unsubscribe(currSubscriptionId));
    setDeactivateDialog(false);
  };

  return (
    <>
      <Typography className={classes.title} variant="h3">
        Subscriptions
      </Typography>
      <Typography className={classes.text} variant="h6">
        Order # {orderId.substring(0, 5)}
      </Typography>
      <Dialog
        open={deactivateDialog}
        onClose={() => setDeactivateDialog(false)}
      >
        <DialogTitle>
          Are you sure you want to deactivate this subscription?
        </DialogTitle>
        <DialogContent>
          Clicking <strong>Confirm</strong> will deactivate this subscription.
          All future data deliveries will be cancelled but the customer will
          still have access to data that has been delivered.
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setDeactivateDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUnsubscribe()}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {subscriptions.length > 0
        ? subscriptions.map((subscription) => (
            <Card key={subscription.id} className={classes.card}>
              <CardHeader
                className={classes.header}
                title={`Subscription #${subscription.id.substring(0, 5)}`}
                action={
                  <>
                    <Chip
                      color="default"
                      className={classes.chip}
                      label={
                        subscription.expiresAt > Date.now()
                          ? 'Active'
                          : 'Inactive'
                      }
                    />
                  </>
                }
              />
              <CardContent className={classes.content}>
                <Grid
                  container
                  direction="row"
                  alignItems="stretch"
                  justify="flex-start"
                >
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">
                      <b> Status:</b>{' '}
                      {subscription.expiresAt < Date.now()
                        ? 'Active'
                        : 'Deactivated'}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b> Last Updated:</b>{' '}
                      {formattedDate(subscription.updatedAt)}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b> Last Billed:</b>{' '}
                      {formattedDate(subscription.lastBillingDate)}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b> Next Billing Date:</b>{' '}
                      {formattedDate(subscription.nextBillingDate)}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b> Expiry Date:</b>{' '}
                      {formattedDate(subscription.expiresAt)}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b>Associated License:</b>{' '}
                      {findAssociatedLicense(subscription.licenseId)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions className={classes.actions}>
                {subscription.expiresAt > Date.now() && (
                  <Grid>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setCurSubscriptionId(subscription.id);
                        setDeactivateDialog(true);
                      }}
                    >
                      Deactivate
                    </Button>
                  </Grid>
                )}
              </CardActions>
            </Card>
          ))
        : null}
    </>
  );
}

export default SubscriptionManagementPage;
