import React from 'react';
import {
  Typography,
  Grid,
  Container,
  Box,
  Link,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectAllLayoutFooter } from 'pages/management/LayoutPage/layoutFooterSlice';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 'lg',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    paddingLeft: '17.5vw',
    paddingRight: '17.5vw',
    color: '#888',
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  footerList: {
    marginLeft: theme.spacing(2),
  },
  footerLink: {
    color: '#888',
  },
}));

export const Footer = () => {
  const classes = useStyles();
  const status = useSelector((state) => state.layoutFooter.status);
  const footerData = useSelector(selectAllLayoutFooter);
  const getLinkTags = (link) => {
    if (link.length <= 0) return {};
    return {
      href: link,
      target: '_blank',
      rel: 'noopener',
    };
  };
  const columns = (columnsData) => {
    const numColumns = columnsData.length;
    return columnsData.map((curColumnData) => (
      <Grid
        container
        item
        xs={12 / numColumns}
        spacing={3}
        alignContent="flex-start"
        key={curColumnData.id}
      >
        <Divider variant="inset" />

        <Grid item xs={12}>
          <Typography variant="h5">{curColumnData.header}</Typography>
        </Grid>
        <Grid container item xs={12} className={classes.footerList} spacing={1}>
          {curColumnData.footerItems.map((item) => (
            <Grid item xs={12} key={item.id}>
              <Link {...getLinkTags(item.link)} className={classes.footerLink}>
                {item.text}
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    ));
  };
  return status === 'succeeded' ? (
    <>
      <Divider m={5} />
      <Box className={classes.footer}>
        <Container>
          <Grid container justify="space-between" m={5}>
            {columns(footerData)}
          </Grid>
        </Container>
      </Box>
    </>
  ) : null;
};
