/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { manageBaseUrl } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const productsAdapter = createEntityAdapter({
  selectId: (product) => product.id,
  sortComparer: (a, b) => b.createdAt - a.createdAt,
});

const productDraft = {
  id: '',
  shopId: '',
  name: '',
  description: '',
  status: 'Draft',
  tagline: '',
  fileName: '',
  factSheetUri: '',
  tags: [],
  licenses: [],
  dataSourceId: '',
  dataView: '',
  columns: [],
  rows: [],
  sampleDataUri: '',
  startDate: moment().format('LL'),
  endDate: moment().format('L'),
  createdAt: Date.now(),
};

const initialState = productsAdapter.getInitialState({
  status: 'idle',
  error: null,
  editRecentlyCreated: null,
  filter: {
    from: moment().add(-2, 'M').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    status: {
      draft: true,
      published: true,
    },
    query: '',
    sortParam: 3,
  },
});

export const createProduct = createAsyncThunk(
  'products/createProduct',
  async (shopId) => {
    const product = { ...productDraft, createdAt: Date.now() };
    product.id = uuidv4().toUpperCase();
    localStorage.setItem('new_product_id', product.id);
    product.shopId = shopId;
    await axios.post(`${manageBaseUrl}/products`, product);
    return product;
  },
);

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async ({ shopId }) => {
    const resp = await axios.get(`${manageBaseUrl}/products`, {
      params: { shopId },
    });
    return { products: resp.data };
  },
);

export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  async (product) => {
    await axios.put(`${manageBaseUrl}/products`, {
      ...product,
    });

    return product;
  },
);

export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (params) => {
    await axios.delete(
      `${manageBaseUrl}/products`,
      {
        params,
      },
      { timeout: 30000 },
    );
    return params.id;
  },
);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    filterEdited(state, action) {
      const prop = Object.keys(action.payload)[0];
      const value = action.payload[prop];
      state.filter[prop] = value;
    },
  },
  extraReducers: {
    [createProduct.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchProducts.pending]: (state) => {
      state.status = 'loading';
    },
    [updateProduct.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteProduct.pending]: (state) => {
      state.status = 'loading';
    },
    [createProduct.rejected]: (state, action) => errorResponse(state, action),
    [fetchProducts.rejected]: (state, action) => errorResponse(state, action),
    [updateProduct.rejected]: (state, action) => errorResponse(state, action),
    [deleteProduct.rejected]: (state, action) => errorResponse(state, action),
    [createProduct.fulfilled]: (state, action) => {
      const product = action.payload;
      productsAdapter.upsertOne(state, product);
      state.status = 'succeeded';
      state.editRecentlyCreated = product.id;
    },
    [fetchProducts.fulfilled]: (state, action) => {
      if (action.payload) {
        const { products } = action.payload;
        productsAdapter.setAll(state, products);
        state.status = 'succeeded';
      } else {
        state.error = 'Error retrieving attributes: received null value';
        state.status = 'failed';
      }
    },
    [updateProduct.fulfilled]: (state, action) => {
      const product = action.payload;
      productsAdapter.updateOne(state, {
        id: product.id,
        changes: product,
      });
      state.status = 'succeeded';
      state.editRecentlyCreated = null;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      const id = action.payload;
      productsAdapter.removeOne(state, id);
      state.status = 'succeeded';
      if (state.editRecentlyCreated === id) state.editRecentlyCreated = null;
    },
  },
});

export const {
  selectAll: selectAllProducts,
  selectById: selectProductById,
  selectIds: selectProductIds,
} = productsAdapter.getSelectors((state) => state.products);

export const { filterEdited } = productsSlice.actions;

export default productsSlice.reducer;
