import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { Redirect, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CustomToolTip, { toolTipMessages } from 'features/toolTip/customToolTip';
import {
  createLayoutFooterHeader,
  fetchLayoutFooter,
  updateLayoutFooterHeader,
  deleteFooterHeader,
  selectAllLayoutFooter,
} from './layoutFooterSlice';
import { selectShopId } from '../SignInPage/shopSignInSlice';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2, 0),
    color: theme.palette.text.primary,
  },
  placeholder: {
    minHeight: 460,
    minWidth: 1230,
  },
  form: {
    flexGrow: 1,
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  loading: {
    width: '50%',
    margin: theme.spacing(10, 0, 10),
  },
  redButton: {
    backgroundColor: '#cf4520',
    color: '#fff',
    margin: theme.spacing(1),
  },
}));

const LayoutPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const footerStatus = useSelector((state) => state.layoutFooter.status);
  const error = useSelector((state) => state.layoutFooter.error);
  const curHighestOrder = useSelector(
    (state) => state.layoutFooter.highestOrder,
  );
  const [editFooterHeader, setEditFooterHeader] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editComponent, setEditComponent] = useState('');
  const [newFooterHeaderName, setNewFooterHeaderName] = useState('');
  const [newFooterItems, setNewFooterItems] = useState([]);
  const [isNewFooterHeader, setIsNewFooterHeader] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  let shopId = useSelector(selectShopId);
  const urlShopId = useParams().shopId;
  if (!shopId) shopId = urlShopId;

  const footerData = useSelector(selectAllLayoutFooter);

  const footerColumns = [
    {
      id: 'header',
      label: 'Header',
      width: '20%',
    },
    {
      id: 'text-link',
      label: 'Text (Link)',
      width: '76.5%',
    },
    {
      id: 'edit',
      label: '',
      width: '3.5%',
    },
  ];
  const editFooterColumns = [
    {
      id: 'text',
      label: 'Text',
      width: '48%',
    },
    {
      id: 'link',
      label: 'Link',
      width: '48%',
    },
    {
      id: 'delete',
      label: '',
      width: '4%',
    },
  ];

  useEffect(() => {
    if (!shopId) return;
    if (footerStatus === 'idle') {
      dispatch(fetchLayoutFooter({ shopId }));
    }
  }, [footerStatus, dispatch, shopId]);

  if (!shopId) return <Redirect to="/users/sign-in" />;

  const handleEditFooterButton = (curFooterHeader) => {
    setEditFooterHeader(curFooterHeader);
    setNewFooterHeaderName(curFooterHeader.header);
    setNewFooterItems(curFooterHeader.footerItems);
    setEditComponent('footer');
    setIsNewFooterHeader(false);
    setEditing(true);
  };

  const footerRow = (curFooterHeader) => (
    <TableRow key={curFooterHeader.id}>
      <TableCell>{curFooterHeader.header}</TableCell>
      <TableCell>
        <Grid container>
          {curFooterHeader.footerItems.map((curItem) => (
            <Grid item xs={12} key={curItem.id}>
              {`${curItem.text} (${curItem.link})`}
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() => handleEditFooterButton(curFooterHeader)}
          data-testid="EditFooterIcon"
        >
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  const updateFooterItem = (index, elementName, newElement) => {
    const updatedItems = [...newFooterItems];
    const curItem = { ...newFooterItems[index] };
    curItem[elementName] = newElement;
    updatedItems[index] = curItem;
    setNewFooterItems(updatedItems);
  };

  const deleteFooterItem = (index) => {
    const updatedItems = [...newFooterItems];
    updatedItems.splice(index, 1);
    setNewFooterItems(updatedItems);
  };

  const editFooterRow = (curItem, index) => (
    <TableRow key={curItem.id}>
      <TableCell>
        <TextField
          required
          error={curItem.text.length === 0}
          inputProps={{ 'data-testid': 'ItemText' }}
          helperText={
            curItem.text.length === 0 ? 'Item name must not be empty' : ''
          }
          value={curItem.text}
          onChange={(e) => {
            updateFooterItem(index, 'text', e.target.value);
          }}
          fullWidth
        />
      </TableCell>
      <TableCell>
        <TextField
          required
          error={curItem.link.length === 0}
          inputProps={{ 'data-testid': 'ItemLink' }}
          helperText={
            curItem.link.length === 0 ? 'Item link must not be empty' : ''
          }
          value={curItem.link}
          onChange={(e) => {
            updateFooterItem(index, 'link', e.target.value);
          }}
          fullWidth
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => deleteFooterItem(index)}>
          <DeleteIcon data-testid="DeleteIcon"/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
  const FooterTable = () => {
    if (footerStatus === 'loading') {
      return (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.placeholder}
        >
          <LinearProgress className={classes.loading} />
        </Grid>
      );
    }
    if (footerStatus === 'succeeded') {
      return (
        <>
          <TableContainer>
            <Table aria-label="footer layout table">
              <TableHead>
                <TableRow>
                  {footerColumns.map((column) => (
                    <TableCell key={column.id} width={column.width}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody data-testid="FooterTableBody">
                {footerData.map((curCol) => footerRow(curCol))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }
    if (footerStatus === 'failed') {
      return <p>{error}</p>;
    }
    return null;
  };
  const exitEditMode = () => {
    setEditFooterHeader(null);
    setNewFooterHeaderName('');
    setNewFooterItems([]);
    setEditComponent('');
    setIsNewFooterHeader(false);
    setEditing(false);
  };

  const handleDeleteFooterHeader = () => {
    dispatch(
      deleteFooterHeader({
        shopId,
        footerHeaderId: editFooterHeader.id,
      }),
    );

    exitEditMode();
  };
  const saveAddFooterChanges = () => {
    if (editFooterHeader.id) {
      dispatch(
        updateLayoutFooterHeader({
          shopId,
          footerHeaderId: editFooterHeader.id,
          newFooterHeaderName,
          newFooterItems,
        }),
      );
    } else {
      dispatch(
        createLayoutFooterHeader({
          shopId,
          footerHeaderName: newFooterHeaderName,
          curHighestOrder,
          footerItems: newFooterItems,
        }),
      );
    }
    exitEditMode();
  };
  const addItem = () => {
    const updatedItems = [...newFooterItems];
    const newItem = { id: uuidv4(), text: '', link: '' };
    updatedItems.push(newItem);
    setNewFooterItems(updatedItems);
  };

  const checkFooterSaveDisabled = () => {
    const test =
      newFooterHeaderName.length === 0 ||
      !newFooterItems.every((curItem) => (curItem.text.length !== 0 && curItem.link.length !== 0));
    return test;
  };

  const addNewFooterHeader = () => {
    setEditFooterHeader({ header: '', footerItems: [] });
    setNewFooterHeaderName('');
    setNewFooterItems([]);
    setEditComponent('footer');
    setIsNewFooterHeader(true);
    setEditing(true);
  };

  if (!editing) {
    return (
      <>
        <Typography className={classes.title} variant="h3">
          Layout <CustomToolTip text={toolTipMessages.LayoutPage} />
        </Typography>
        <Grid container>
          <Grid item xs={10}>
            <Typography className={classes.title} variant="h4">
              Footer
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'end' }}>
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={() => {
                addNewFooterHeader();
              }}
            >
              Add New Header
            </Button>
          </Grid>
        </Grid>

        {FooterTable()}
      </>
    );
  }
  if (editing && editComponent === 'footer') {
    return (
      <>
        <Typography className={classes.title} variant="h3">
          Edit Footer
        </Typography>
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                label="Footer Column Header"
                inputProps={{ 'data-testid': 'FooterColumnHeader' }}
                error={newFooterHeaderName.length === 0}
                helperText={
                  newFooterHeaderName.length === 0
                    ? 'Header name must not be empty'
                    : ''
                }
                value={newFooterHeaderName}
                onChange={(e) => {
                  setNewFooterHeaderName(e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h5">
                Footer Items
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table aria-label="edit footer layout table">
                  <TableHead>
                    <TableRow>
                      {editFooterColumns.map((column) => (
                        <TableCell key={column.id} width={column.width}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody data-testid="FooterItemsTable">
                    {newFooterItems.map((curItem, index) =>
                      editFooterRow(curItem, index),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={() => {
                  addItem();
                }}
              >
                Add New Item
              </Button>
            </Grid>
            <Grid item xs={10} style={{ textAlign: 'end' }}>
              {!isNewFooterHeader && (
                <Button
                  className={classes.redButton}
                  variant="contained"
                  onClick={() => {
                    setDeleteDialog(true);
                  }}
                >
                  Delete Header
                </Button>
              )}
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  exitEditMode();
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                color={checkFooterSaveDisabled() ? 'default' : 'primary'}
                disabled={checkFooterSaveDisabled()}
                onClick={() => {
                  saveAddFooterChanges();
                }}
              >
                {isNewFooterHeader ? 'Add' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={deleteDialog}
          onClose={() => {
            setDeleteDialog(false);
          }}
        >
          <DialogTitle>Confirm Delete?</DialogTitle>
          <DialogContent>
            <Typography>
              This will delete this header and all items/links under this header
              from the footer.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setDeleteDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="ConfirmDelete"
              variant="contained"
              className={classes.redButton}
              onClick={() => {
                handleDeleteFooterHeader();
                setDeleteDialog(false);
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return null;
};

export default LayoutPage;
