import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectOrderById } from 'pages/OrdersPage/ordersSlice';
import {
  selectAllOrderItems,
  fetchOrderItems,
} from 'pages/OrdersPage/orderItemsSlice';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { selectAllProducts } from 'pages/management/ProductsPage/productsSlice';
import { selectAllCustomers } from '../management/CustomersPage/customersSlice';
import { Product } from '../../features/product/customerProduct';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  actionButton: {
    margin: theme.spacing(2, 1),
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

function OrderDetailPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  let orderId = new URLSearchParams(window.location.search).get('orderId');
  // eslint-disable-next-line
  if (orderId == undefined) {
    orderId = '1E251B05-E7DF-4AEF-9A0A-FDDC594823D9';
  }

  const orderItems = useSelector(selectAllOrderItems).filter(
    (orderItem) => orderItem.orderId === orderId,
  );

  const order = useSelector((state) => selectOrderById(state, orderId));
  const customerInfo = useSelector(selectAllCustomers).find(
    (customer) => customer.id === order.customerId,
  );
  const currency = useSelector((state) => state.licenses.currency);

  useEffect(() => {
    dispatch(fetchOrderItems(customerInfo.id));
  }, [dispatch, customerInfo]);

  const products = useSelector(selectAllProducts).filter((product) =>
    order.productIds.includes(product.id),
  );

  const numProducts = products.length;
  const ProductList = () =>
    products.map((entity) => (
      <Product
        key={entity.id}
        order={order}
        orderItems={orderItems}
        cart
        entity={entity}
        currency={currency}
      />
    ));

  const formattedDate = (epochUnix) => {
    const date = new Date(epochUnix);
    return date.toString().substring(0, 15);
  };

  return (
    <>
      <Typography className={classes.text} variant="h3">
        Order #{orderId.substring(0, 5)}
      </Typography>
      <Grid container spacing={3}>
        {order ? (
          <Grid item xs={8}>
            <ProductList />
          </Grid>
        ) : null}
        <Grid item xs>
          <Typography className={classes.text} variant="h6">
            Order Summary
          </Typography>
          {order ? (
            <Grid item xs>
              <Grid>
                <Typography className={classes.text} variant="body1">
                  {numProducts} product{numProducts !== 1 && 's'}
                </Typography>
                <Typography className={classes.text} variant="body1">
                  Client: {customerInfo.firstName} {customerInfo.lastName}
                </Typography>
                <Typography className={classes.text} variant="body1">
                  Company: {customerInfo.companyName}
                </Typography>
              </Grid>

              <Typography className={classes.text} variant="body1">
                Placed: {formattedDate(order.createdAt)}
              </Typography>
              <Typography className={classes.text} variant="body1">
                Last Updated: {formattedDate(order.updatedAt)}
              </Typography>
              <Box>
                <Button
                  className={classes.actionButton}
                  color="default"
                  variant="contained"
                  component={Link}
                  to={`/shop/${order.shopId}/orders`}
                >
                  View Orders
                </Button>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

// const mapStateToProps = () => ({});

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailPage);

export default OrderDetailPage;
