import React, { useState, useEffect, useContext, createContext } from 'react';
import { manageBaseUrl } from 'config';
import { useDispatch } from 'react-redux';
import {
  fetchUser,
  fetchShop,
  setSelectedShopId,
  fetchShopBA,
} from 'pages/management/SignInPage/shopSignInSlice';
import axios from 'client/client';
import {
  fetchLicenses,
  fetchCurrency,
} from 'pages/management/LicensesPage/licensesSlice';
import { fetchAttributes } from 'pages/management/AttributesPage/attributesSlice';
import { fetchProducts } from 'pages/management/ProductsPage/productsSlice';
import { fetchCustomers } from 'pages/management/CustomersPage/customersSlice';
import { fetchOrdersByShop } from 'pages/OrdersPage/ordersSlice';
import { fetchSubscriptionOrdersByShop } from 'pages/OrdersPage/subscriptionsSlice';
import { fetchLayoutFooter } from 'pages/management/LayoutPage/layoutFooterSlice';
import { fetchGID } from 'pages/management/AnalyticsPage/analyticsSlice';
import { fetchApiKey } from 'pages/management/DeliveryOptionsPage/apiKeysSlice';
import { fetchInvoices } from 'pages/management/PaymentsPage/paymentsSlice';
import { fetchThemeByShop } from 'pages/SettingsPage/settingsSlice';

const authContext = createContext();

// Reference: https://usehooks.com/useAuth/

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);

function useProvideAuth() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchStatus, setFetchStatus] = useState('idle');
  const [shopId, setShopId] = useState('');

  const signIn = async (email, password) => {
    const resourcePath = `${manageBaseUrl}/users/sign-in`;
    const response = await axios.post(resourcePath, { email, password });
    if (response.data.successSignIn) {
      setUser(response.data.user);
      setLoading(false);
    }
    return response;
  };

  const signOut = () => {
    setUser(null);
    setLoading(true);
    setFetchStatus('idle');
  };

  const fetchItems = async (paramShopId) => {
    if (fetchStatus !== 'pending') {
      setFetchStatus('pending');
      await Promise.all([
        dispatch(setSelectedShopId(paramShopId)),
        dispatch(fetchLicenses({ shopId: paramShopId })),
        dispatch(fetchCurrency({ shopId: paramShopId })),
        dispatch(fetchAttributes({ shopId: paramShopId })),
        dispatch(fetchShop(paramShopId)),
        dispatch(fetchProducts({ shopId: paramShopId })),
        dispatch(fetchCustomers(paramShopId)),
        dispatch(fetchOrdersByShop(paramShopId)),

        dispatch(fetchGID(paramShopId)),
        dispatch(fetchLayoutFooter({ shopId: paramShopId })),
        dispatch(fetchApiKey({ entityId: user.id })),
        dispatch(fetchInvoices({ shopId: paramShopId })),
        dispatch(fetchShopBA(paramShopId)),
        dispatch(fetchThemeByShop(paramShopId)),
        dispatch(fetchSubscriptionOrdersByShop(paramShopId)),
      ]);
      setFetchStatus('succeeded');
    }
  };

  useEffect(() => {
    dispatch(fetchUser()).then((resp) => {
      if (resp.payload) setUser(resp.payload);
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (shopId !== '' && shopId !== undefined && user && fetchStatus === 'idle')
      fetchItems(shopId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId, user, fetchStatus]);

  return {
    user,
    fetchStatus,
    loading,
    fetchItems,
    signIn,
    signOut,
    setShopId,
  };
}
