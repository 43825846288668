/* eslint-disable no-nested-ternary */

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from 'app/message';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import {
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import {
  releaseApprovedData,
  updateApproval,
} from 'pages/OrdersPage/ordersSlice';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'papaparse';
import { SAMPLE_DATA_DISPLAY_COUNT, manageBaseUrl } from 'config';
import Tags from 'features/tags/Tags';
import ReactGA from 'react-ga4';
import { PreviewDataTable } from '../previewDataTable/PreviewDataTable';
import axios from '../../client/client';
import { selectAllLicenses } from '../../pages/management/LicensesPage/licensesSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  cardActions: {
    container: 'flex',
    justifyContent: 'flex-end',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  // approve and reject buttons
  ARbutton: {
    justifyContent: 'flex-end',
  },
  formControl: {
    margin: theme.spacing(0, 1, 0, 0),
    minWidth: 120,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  sectionTitle: {
    margin: theme.spacing(1, 0),
  },
  license: () => ({
    margin: theme.spacing(1, 0, 1, 0),
  }),
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  dateField: {
    margin: theme.spacing(0, 1),
    width: '140px',
  },
  cardHeader: {
    padding: theme.spacing(3, 1, 0, 2),
  },
  licenseLabel: {
    textTransform: 'uppercase',
    fontWeight: 800,
    color: '#888',
    fontSize: '12px',
  },
  shopPrimary: () => ({
    backgroundColor: '#cf4520',
    color: '#fff',
  }),
}));

export const Product = injectIntl(
  ({
    cart,
    entity,
    orderItems,
    onAddToCart,
    added,
    order,
    addDisabled,
    addLicenseToCart,
    removeLicenseFromCart,
    onChangeDelivery,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState(false);
    const [checkSubscription, setCheckSubscription] = React.useState(false);
    const [checkHistory, setCheckHistory] = React.useState(false);
    const [getSampleURL, setGetSampleURL] = React.useState(false);
    const [sampleData, setSampleData] = React.useState('');

    const [rejectDialog, setRejectDialog] = React.useState(false);
    const [rejectReason, setRejectReason] = React.useState('');
    const [rejectId, setRejectId] = React.useState(-1);
    const [approvalDialog, setApprovalDialog] = React.useState(false);

    const [draft, setDraft] = React.useState(entity);
    const currency = useSelector((state) => state.licenses.currency);

    const GAevent = (category, action, label) => {
      ReactGA.event({ category, action, label });
    };

    const sortAlphanumeric = (a, b) =>
      a.name.localeCompare(b.name, 'en', { numeric: true });

    const licenses = useSelector(selectAllLicenses)
      .filter((license) => license.productId === entity.id)
      .filter((license) => license.draftStatus !== 'draft')
      .sort(sortAlphanumeric);

    const handlePreview = async () => {
      if (!getSampleURL) {
        const resourcePath = `${manageBaseUrl}/products/get-sample-url`;
        const resp = await axios.get(resourcePath, {
          params: {
            fileName: `${entity.id}.csv`, // change to productId
          },
        });
        setGetSampleURL(resp.data);
        if (resp.data) {
          const response = await axios.get(resp.data);
          handleDrop(response.data);
          setSampleData(response.data);
        } else {
          setSampleData(false);
        }
      } else {
        const response = await axios.get(getSampleURL);
        handleDrop(response.data);

        if (!getSampleURL) setSampleData(false);
        else setSampleData(response.data);
      }
    };

    const handleExpandClick = () => {
      setExpanded(!expanded);
      handlePreview();

      if (expanded)
        GAevent('engagement', 'products_close_preview', 'Close preview');
      else GAevent('engagement', 'products_open_preview', 'Open preview');
    };

    const handleApproval = (newStatus, orderId) => {
      dispatch(
        releaseApprovedData({
          newStatus,
          orderId,
          reason: newStatus === 'rejected' ? rejectReason : '',
        }),
      );
    };

    const handleRejection = (newStatus, orderId) => {
      dispatch(
        updateApproval({
          newStatus,
          orderId,
          reason: newStatus === 'rejected' ? rejectReason : '',
        }),
      );
    };

    const manageLicenseInCart = (
      adding,
      licenseId,
      licenseDelivery,
      licensePrice,
    ) => {
      if (licenses.length !== 1) {
        if (adding)
          addLicenseToCart(
            licenseId,
            licenseDelivery.length === 1 ? licenseDelivery : [],
            licensePrice,
          );
        else removeLicenseFromCart(licenseId);
      }
    };

    const formattedDate = (epochUnix) => {
      const date = new Date(parseInt(epochUnix, 10));
      return date.toString().substring(0, 15);
    };

    const LicenseList = () => (
      <Grid container direction="row" justify="center" alignItems="flex-start">
        {licenses.map((license) => {
          const associatedOrderItem = orderItems
            ? orderItems?.find(
                (orderItem) => orderItem.licenseId === license.id,
              )
            : null;
          const curOrderItem =
            orderItems?.find(
              (orderItem) => orderItem.licenseId === license.id,
            ) || null;
          const selected = !!curOrderItem;
          return (
            <Grid key={license.id} item container xs={12}>
              <Grid
                item
                xs={3}
                className={classes.license}
                onClick={() => setCheckSubscription(!checkSubscription)}
              >
                <Typography component="span" variant="body2">
                  <div className={classes.licenseLabel}>License Name</div>
                  <div className={classes.licensePrice}>{license.name}</div>
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.license}
                onClick={() => setCheckHistory(!checkHistory)}
              >
                <Typography component="span" variant="body2">
                  <div className={classes.licenseLabel}>
                    <FormattedMessage {...messages.LABEL_PRICING} />
                  </div>
                  <div className={classes.licensePrice}>
                    {license.price.toString()}{' '}
                    <FormattedMessage
                      {...messages[`CURRENCY_${currency.toUpperCase()}`]}
                    />{' '}
                    (
                    <FormattedMessage
                      {...messages[
                        `BILLING_FREQUENCY_${license.billingFrequency}`
                      ]}
                    />
                    )
                  </div>
                </Typography>
              </Grid>
              {order.startDate || order.endDate ? (
                <Grid item xs={4}>
                  <Typography component="span" variant="body2">
                    <div className={classes.licenseLabel}>From</div>
                    <Typography className={classes.dateField}>
                      {order.startDate
                        ? formattedDate(order.startDate)
                        : 'Not Specified'}
                    </Typography>
                    <div className={classes.licenseLabel}>To</div>
                    <Typography className={classes.dateField}>
                      {order.endDate
                        ? formattedDate(order.endDate)
                        : 'Not Specified'}
                    </Typography>
                  </Typography>
                </Grid>
              ) : null}
              {cart && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selected}
                        disabled
                        onChange={(e) =>
                          manageLicenseInCart(
                            e.target.checked,
                            license.id,
                            license.delivery,
                            license.price,
                          )
                        }
                      />
                    }
                  />
                </Grid>
              )}
              {cart && selected && (
                <Grid item>
                  <CardContent>
                    <Typography className={classes.sectionTitle}>
                      Delivery:
                    </Typography>
                    {license.delivery.includes('api') && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled
                            checked={associatedOrderItem.delivery.includes(
                              'api',
                            )}
                            onChange={() =>
                              onChangeDelivery('api', associatedOrderItem)
                            }
                          />
                        }
                        label="API"
                      />
                    )}
                    {license.delivery.includes('aws') && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled
                            checked={associatedOrderItem.delivery.includes(
                              'aws',
                            )}
                            onChange={() =>
                              onChangeDelivery('aws', associatedOrderItem)
                            }
                          />
                        }
                        label="Cloud Transfer to AWS S3"
                      />
                    )}
                    {license.delivery.includes('download') && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled
                            checked={associatedOrderItem.delivery.includes(
                              'download',
                            )}
                            onChange={() =>
                              onChangeDelivery('download', associatedOrderItem)
                            }
                          />
                        }
                        label="Download Link"
                      />
                    )}
                    {license.delivery.includes('SFTP-buyer-hosted') && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled
                            checked={associatedOrderItem.delivery.includes(
                              'SFTP-buyer-hosted',
                            )}
                            onChange={() =>
                              onChangeDelivery(
                                'SFTP-buyer-hosted',
                                associatedOrderItem,
                              )
                            }
                          />
                        }
                        label="SFTP (Buyer hosted server)"
                      />
                    )}
                  </CardContent>
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
    );

    const handleDrop = (sampleDataSet) => {
      const result = parse(sampleDataSet, { header: true });
      const dataColumns = Object.keys(result.data[0]).map((column) => ({
        field: column,
        flex: 1,
      }));
      const dataRows = result.data
        .splice(0, SAMPLE_DATA_DISPLAY_COUNT)
        .map((row, index) => ({ ...row, id: index + 1 }));
      setDraft({
        ...draft,
        columns: dataColumns,
        rows: dataRows,
      });
    };

    // const handleRemoveFromCart = () => {
    //   licenses.forEach((license) => {
    //     removeLicenseFromCart(license.id);
    //   });
    //   onRemoveFromCart();
    // };

    const handleAddToCart = async () => {
      const cartOrderId = await onAddToCart();
      if (licenses.length === 1)
        addLicenseToCart(
          licenses[0].id,
          licenses[0].delivery.length === 1 ? licenses[0].delivery : [],
          licenses[0].price,
          cartOrderId,
        );
    };
    return (
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          title={entity.name}
          subheader={entity.tagline}
        />
        <CardMedia
          className={classes.media}
          image="/static/images/cards/paella.jpg"
          title="Paella dish"
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {entity.description}
          </Typography>
          <Box pt={2.5}>
            <Tags
              className={classes.tagClass}
              editing={false}
              tagData={draft.tags}
            />
          </Box>
          <Typography className={classes.sectionTitle} variant="h6">
            {' '}
            Licensing{' '}
          </Typography>
          <LicenseList />
        </CardContent>
        <CardActions disableSpacing className={classes.cardActions}>
          {order?.status === 'submitted' && (
            <>
              <Button
                className={classes.ARbutton}
                onClick={() => setApprovalDialog(true)}
              >
                Approve
              </Button>
              <Button
                className={classes.ARbutton}
                onClick={() => {
                  setRejectDialog(true);
                  setRejectId(order.id);
                }}
              >
                Reject
              </Button>
            </>
          )}
          <Dialog
            open={approvalDialog}
            onClose={() => {
              setApprovalDialog(false);
            }}
          >
            <DialogTitle>Order Approval Confirmation</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to confirm this order? Data will be
                released to the client after approval.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  setApprovalDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleApproval('approved', order.id);
                  setApprovalDialog(false);
                }}
              >
                Approve
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={rejectDialog}
            onClose={() => {
              setRejectReason('');
              setRejectId(-1);
              setRejectDialog(false);
            }}
          >
            <DialogTitle>Reason for Rejection</DialogTitle>
            <DialogContent>
              <Typography>
                Please give a reason for rejecting the order.
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                id="reject-reason"
                label="Rejection Reason"
                onChange={(e) => setRejectReason(e.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => {
                  setRejectReason('');
                  setRejectId(-1);
                  setRejectDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleRejection('rejected', rejectId);
                  setRejectDialog(false);
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            Preview
            <ExpandMoreIcon
              className={clsx('', {
                [classes.expandOpen]: expanded,
              })}
            />
          </Button>
          {!cart ? (
            <Button
              className={classes.shopPrimary}
              disabled={added || addDisabled}
              onClick={handleAddToCart}
              variant="contained"
              color="default"
            >
              {added ? 'Added' : 'Add to Cart'}
            </Button>
          ) : null}
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {draft.columns && draft.rows && sampleData ? (
              <PreviewDataTable
                columns={draft.columns}
                rows={draft.rows}
                // editing={editing}
                onDropEvent={handleDrop}
              />
            ) : !draft.columns.length &&
              !draft.rows.length &&
              !sampleData &&
              !getSampleURL ? (
              <Typography data-testid="productPreview">
                {' '}
                No Preview Available.
              </Typography>
            ) : (
              <Typography> Fetching data preview... </Typography>
            )}
          </CardContent>
        </Collapse>
      </Card>
    );
  },
);
