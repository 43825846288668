/* eslint-disable no-nested-ternary */

import React, { useEffect } from 'react';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useAuth } from 'auth/user/userAuth';
import { LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectShopId } from '../../pages/management/SignInPage/shopSignInSlice';

export default function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  const routeMatch = useRouteMatch();

  const shopId = useSelector(selectShopId);

  useEffect(() => {
    if (
      routeMatch.params.shopId !== '' &&
      routeMatch.params.shopId !== undefined
    )
      auth.setShopId(routeMatch.params.shopId);
    else if (shopId) {
      auth.setShopId(shopId);
    }
  }, [auth, routeMatch.params.shopId, shopId]);

  return (
    <Route
      {...rest}
      render={({ match }) => {
        if (match.params.shopId === 'undefined')
          return <Redirect to="/users/sign-in" />;
        return auth.loading ||
          (match.params.shopId && auth.fetchStatus !== 'succeeded') ? (
          <LinearProgress />
        ) : auth.user ? (
          children
        ) : (
          <Redirect to="/users/sign-in" />
        );
      }}
    />
  );
}
