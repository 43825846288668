/* eslint-disable no-nested-ternary */

import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from 'app/message';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { DataSetDraft } from 'features/product/dataSetDraft';
import clsx from 'clsx';

import {
  Button,
  Box,
  Typography,
  TextField,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  FormGroup,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';

import {
  ExpandMore as ExpandMoreIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import { useSelector } from 'react-redux';
import { parse } from 'papaparse';
import { Link } from 'react-router-dom';
import {
  manageBaseUrl,
  SAMPLE_DATA_MAX_SIZE,
  SAMPLE_DATA_DISPLAY_COUNT,
} from 'config';
import Tags from 'features/tags/Tags';
import ReactGA from 'react-ga4';
import { PreviewDataTable } from '../previewDataTable/PreviewDataTable';
import logo from '../../assets/images/logo_icon_default.png';
import axios from '../../client/client';
import { selectAllLicenses } from '../../pages/management/LicensesPage/licensesSlice';
import { UploadDataSet } from './uploadDataSet';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  cardActions: {
    container: 'flex',
    justifyContent: 'flex-end',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  licenseBox: {
    border: 'solid 1px black',
    backgroundColor: 'red',
  },
  formControl: {
    margin: theme.spacing(0, 1, 0, 0),
    minWidth: 120,
  },
  FSButtons: {
    margin: theme.spacing(0, 1, 0, 0),
    minWidth: 100,
  },
  dataSetDraftFormControl: {
    margin: theme.spacing(0, 0, 0, 1),
    minWidth: 120,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  sectionTitle: {
    margin: theme.spacing(1, 0),
  },
  license: () => ({
    margin: theme.spacing(1, 0, 1, 0),
  }),
  selectedLicense: () => ({
    border: 'solid 1px black',
  }),
  licenseSection: {
    display: 'flex',
    '& > *': {
      flexGrow: 1,
    },
  },
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  buttonSection: {
    display: 'flex',
    '& > *': {
      flexGrow: 1,
    },
    margin: theme.spacing(1, 0),
  },
  chip: {
    margin: theme.spacing(2, 1),
  },
  avatar: {
    height: theme.spacing(4),
    backgroundImage: `url(${logo})`,
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  dateField: {
    margin: theme.spacing(0, 1),
    width: '140px',
  },
  cardHeader: {
    padding: theme.spacing(3, 1, 0, 2),
  },
  licenseLabel: {
    textTransform: 'uppercase',
    fontWeight: 800,
    color: '#888',
    fontSize: '12px',
  },
  licensePrice: {},
  shopPrimary: () => ({
    backgroundColor: '#cf4520',
    color: '#fff',
  }),
  previewText: {
    fontWeight: 10000,
    color: '#cc33ff',
    fontSize: '12px',
  },
  dataSetDraftSubtitles: {
    fontWeight: 800,
    margin: theme.spacing(2, 0, 0),
  },
  deleteButton: {
    color: '#d63429',
    borderColor: '#d63429',
  },
  buttonRow: {
    display: 'flex',
    '& > *': {
      margin: '3px',
    },
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
  text: {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
    },
  },
}));

export const Product = injectIntl(
  ({
    entity,
    isDraft,
    onAddProduct,
    onDeleteProduct,
    dataSet,
    editingAfterCreation,
    onChangeEdit,
    currency = 'usd',
  }) => {
    const [editing, setEditing] = React.useState(editingAfterCreation);
    const [putFactSheetURL, setPutFactSheetURL] = React.useState(false);
    const [getFactSheetURL, setGetFactSheetURL] = React.useState(false);
    const classes = useStyles();
    const [publishWarning, setPublishWarning] = React.useState(false);
    const [publishWarningMessage, setPublishWarningMessage] = React.useState(
      '',
    );
    const [expanded, setExpanded] = React.useState(false);
    const [checkSubscription, setCheckSubscription] = React.useState(false);
    const [checkHistory, setCheckHistory] = React.useState(false);
    const [putSampleURL, setPutSampleURL] = React.useState(false);
    const [getSampleURL, setGetSampleURL] = React.useState(false);
    const [sampleDataURL, setSampleDataURL] = React.useState(false);
    const [factSheetURL, setFactSheetURL] = React.useState(false);
    const [sampleData, setSampleData] = React.useState('');
    const [factSheet, setFactSheet] = React.useState('');
    const [validate, setValidate] = React.useState(true);
    const [openDraftDialog, setOpenDraftDialog] = React.useState(false);
    const [dataDefinitionError, setDataDefinitionError] = React.useState(false);
    const [uploadDataSetModal, setUploadDataSetModal] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [revoke, setRevoke] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [draft, setDraft] = React.useState(entity);

    const GAevent = (category, action, label) => {
      ReactGA.event({ category, action, label });
    };

    const sortAlphanumeric = (a, b) =>
      a.name.localeCompare(b.name, 'en', { numeric: true });

    const licenses = useSelector(selectAllLicenses)
      .filter((license) => license.productId === entity.id)
      .filter((license) => license.draftStatus !== 'draft')
      .sort(sortAlphanumeric);

    const setUnloadWindow = (windowState) => {
      if (windowState && window.onbeforeunload === null) {
        window.onbeforeunload = () => true;
      } else if (!windowState && window.onbeforeunload !== null) {
        window.onbeforeunload = null;
      }
    };

    const getPreSignedURLs = async () => {
      const preSignedURLS = await Promise.all([
        axios.get(`${manageBaseUrl}/products/generate-get-url`, {
          params: {
            fileName: `${entity.id}.pdf`, // change to productId
          },
        }),
        axios.get(`${manageBaseUrl}/products/generate-put-url`, {
          params: {
            fileName: `${entity.id}.pdf`,
          },
        }),
        axios.get(`${manageBaseUrl}/products/put-sample-url`, {
          params: {
            fileName: `${entity.id}.csv`, // TODO: store as guuid
          },
        }),
        axios.get(`${manageBaseUrl}/products/get-sample-url`, {
          params: {
            fileName: `${entity.id}.csv`, // change to productId
          },
        }),
        axios.get(`${manageBaseUrl}/products/get-object-url`, {
          params: {
            fileName: `${entity.id}`, // TODO: store as guuid
          },
        }),
      ]);

      setGetFactSheetURL(preSignedURLS[0].data);
      setPutFactSheetURL(preSignedURLS[1].data);
      setPutSampleURL(preSignedURLS[2].data);
      setGetSampleURL(preSignedURLS[3].data);
      setSampleDataURL(preSignedURLS[4].data.sampleDataURL);
      setFactSheetURL(preSignedURLS[4].data.factSheetURL);
    };

    const handlePreview = async () => {
      if (!getSampleURL) {
        const resourcePath = `${manageBaseUrl}/products/get-sample-url`;
        const resp = await axios.get(resourcePath, {
          params: {
            fileName: `${entity.id}.csv`, // change to productId
          },
        });
        setGetSampleURL(resp.data);
        if (resp.data) {
          const response = await axios.get(resp.data);
          setDataColumnRows(response.data);
          setSampleData(response.data);
        } else {
          setSampleData(false);
        }
      } else {
        const response = await axios.get(getSampleURL);
        setDataColumnRows(response.data);

        if (!getSampleURL) setSampleData(false);
        else setSampleData(response.data);
      }
    };

    const deleteFactSheet = async () => {
      const resourcePath = `${manageBaseUrl}/products/delete-fs`;
      await axios.delete(resourcePath, {
        params: {
          fileName: `${entity.id}.pdf`, // change to productId
        },
      });
    };

    const handleFactSheetUpload = (e) => {
      const file = e.target.files[0];
      Object.defineProperty(file, 'name', {
        writable: true,
        value: `${entity.id}.pdf`,
      });
      setFactSheet(file);
      setDraft({
        ...draft,
        factSheetUri: factSheetURL,
      });
    };

    const uploadFactSheet = async () => {
      const options = {
        headers: {
          'Content-Type': 'application/pdf',
        },
      };
      await axios.put(putFactSheetURL, factSheet, options);
    };

    const handleAddTag = (newTag) => {
      setDraft({
        ...draft,
        tags: [
          ...draft.tags,
          { attribute: newTag.attributeId, tag: newTag.tagId },
        ],
      });
    };

    const handleSampleDataUpload = async (e) => {
      await processFile(e.target.files[0]);
    };

    const uploadSampleData = async () => {
      const options = {
        headers: {
          'Content-Type': 'text/csv',
        },
      };
      await axios.put(putSampleURL, sampleData, options);
    };

    const deleteSampleData = async () => {
      const resourcePath = `${manageBaseUrl}/products/delete-sample-data`;
      await axios.delete(resourcePath, {
        params: {
          fileName: `${entity.id}.csv`, // TODO: change to guuid
        },
      });
      setSampleData(false);
    };

    const handleDeleteTag = (deleteKey) => {
      setDraft({
        ...draft,
        tags: draft.tags.filter((tag) => tag.tag !== deleteKey),
      });
    };

    const handleExpandClick = () => {
      setExpanded(!expanded);
      handlePreview();

      if (expanded)
        GAevent('engagment', 'products_close_preview', 'Close Preview');
      else GAevent('engagment', 'products_open_preview', 'Open Preview');
    };

    const LicenseList = () => (
      <Grid container direction="row" justify="center" alignItems="flex-start">
        {licenses.map((license, index) => (
          <Grid key={license.id} item container xs={12}>
            <Grid
              item
              xs={3}
              className={classes.license}
              onClick={() => setCheckSubscription(!checkSubscription)}
            >
              <Typography component="span" variant="body2">
                {index === 0 && (
                  <div className={classes.licenseLabel}>License Name</div>
                )}
                <div className={classes.licensePrice}>{license.name}</div>
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              className={classes.license}
              onClick={() => setCheckHistory(!checkHistory)}
            >
              <Typography component="span" variant="body2">
                {index === 0 && (
                  <div className={classes.licenseLabel}>
                    <FormattedMessage {...messages.LABEL_PRICING} />
                  </div>
                )}
                <div className={classes.licensePrice}>
                  {license.price.toString()}{' '}
                  <FormattedMessage
                    {...messages[`CURRENCY_${currency.toUpperCase()}`]}
                  />{' '}
                </div>
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );

    const handleChange = (key) => (event) => {
      setDraft({
        ...draft,
        [key]: event.target.value,
      });
      setUnloadWindow(true);
    };

    const handleCancel = () => {
      onAddProduct(entity);
    };

    const handleDelete = () => {
      onDeleteProduct({ id: entity.id, revoke });

      GAevent('engagement', 'products_delete_product', 'Delete product');
    };

    const handleEditChange = () => {
      onChangeEdit(entity.id);
    };

    const handleSave = () => {
      if (editing) {
        if (draft.name === '' || draft.description === '') {
          setValidate(false);
        } else {
          setValidate(true);
          setEditing(!editing);
          if (putSampleURL) {
            uploadSampleData();
          }
          onAddProduct(draft);
          if (putFactSheetURL) {
            uploadFactSheet();
          }
        }
      } else {
        setEditing(!editing);
        handleEditChange();
        getPreSignedURLs();
      }
      GAevent('engagement', 'products_edit_product', 'Edit product');
    };

    const publishProduct = async () => {
      handleSave();
      const newDraft = { ...draft };
      const resourcePath = `${manageBaseUrl}/data-sets/get-existing-uploads`;
      const resp = await axios.get(resourcePath, {
        params: {
          productId: entity.id,
        },
      });

      if (draft.status === 'Published') {
        newDraft.status = 'Draft';
        onAddProduct(newDraft);
        setEditing(true);
        handleEditChange();
        return;
      }

      if (licenses.length >= 1 && resp.data.existingUpload) {
        setPublishWarning(false);
        if (draft.status !== 'Published') {
          newDraft.status = 'Published';
        }
        onAddProduct(newDraft);
        setEditing(true);
        handleEditChange();
        return;
      }

      if (licenses.length === 0) {
        setPublishWarning(true);
        setPublishWarningMessage('A License is required to Publish');
      }
      if (resp.data.existingUpload === false) {
        setPublishWarning(true);
        setPublishWarningMessage(
          'An uploaded dataset that matches the definition is required to Publish',
        );
      }
    };

    const setDataColumnRows = (sampleDataSet) => {
      const result = parse(sampleDataSet, { header: true });
      const dataColumns = Object.keys(result.data[0]).map((column) => ({
        field: column,
        flex: 1,
      }));
      const dataRows = result.data
        .splice(0, SAMPLE_DATA_DISPLAY_COUNT)
        .map((row, index) => ({ ...row, id: index + 1 }));
      setDraft({
        ...draft,
        columns: dataColumns,
        rows: dataRows,
        sampleDataUri: sampleDataURL,
      });
    };

    const processFile = async (file) => {
      Object.defineProperty(file, 'name', {
        writable: true,
        value: `${entity.id}.csv`,
      });

      if (file.size > SAMPLE_DATA_MAX_SIZE) {
        setSampleData(false);
        alert('File size must be less than 10 Megabytes.'); // eslint-disable-line
      } else {
        setSampleData(file);
        const content = await file.text();
        setDataColumnRows(content);
      }
    };
    const handleDrop = async (files) => {
      await processFile(files[0]);
    };

    const handleOpenDraftDialog = async () => {
      setLoading(true);
      const resourcePath = `${manageBaseUrl}/data-sets/get-existing-uploads`;
      const resp = await axios.get(resourcePath, {
        params: {
          productId: entity.id,
        },
      });

      if (resp.data.existingUpload === true) {
        setDataDefinitionError(true);
        setLoading(false);
      } else {
        setOpenDraftDialog(true);
        setLoading(false);
      }
    };

    const ConfirmDeleteDialog = () => (
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogTitle>Are you sure you want to delete this product?</DialogTitle>
        <DialogContent>
          Clicking <strong>Delete</strong> will permanently delete this product
          and all licenses associated with this product. Any S3 Access Points
          associated with this product will also be deleted.
        </DialogContent>
        <DialogActions>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="s3Share"
                  className={classes.checkbox}
                  checked={revoke}
                  onChange={(e) => setRevoke(e.target.checked)}
                />
              }
              label="Revoke Access to S3?"
            />
          </FormGroup>
          <Button onClick={() => setDialog(false)}>Cancel</Button>
          <Button
            data-testid="DeleteProductConfirmBtn"
            variant="contained"
            color="secondary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );

    const UnableToSaveDialog = () => (
      <Dialog
        open={dataDefinitionError}
        onClose={() => setDataDefinitionError(false)}
      >
        <DialogTitle>Unable to Edit the Data Definition</DialogTitle>

        <DialogContent>
          <Typography>
            A data set has already been uploaded for this product. Data
            definitions cannot be changed after data has been uploaded.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDataDefinitionError(false)}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <Card className={classes.root}>
        {dialog && <ConfirmDeleteDialog />}
        {dataDefinitionError && <UnableToSaveDialog />}
        {openDraftDialog && (
          <DataSetDraft
            onClose={() => setOpenDraftDialog(false)}
            onConfirm={() => handleSave()}
            product={entity}
            dataSet={dataSet}
          />
        )}
        {uploadDataSetModal && (
          <UploadDataSet
            onClose={() => setUploadDataSetModal(false)}
            landingZoneLink={dataSet.landingZoneLink}
          />
        )}
        {!validate && editing ? (
          <CardContent>
            <Alert variant="outlined" severity="error">
              Please enter a Product Name or Product Description.
            </Alert>
          </CardContent>
        ) : null}
        <CardHeader
          className={classes.cardHeader}
          title={
            editing ? (
              <TextField
                className={classes.textField}
                fullWidth
                inputProps={{ 'data-testid': 'ProductName' }}
                label="Product Name"
                value={draft.name}
                onChange={handleChange('name')}
                required
              />
            ) : (
              entity.name
            )
          }
          subheader={
            editing ? (
              <TextField
                className={classes.textField}
                fullWidth
                data-testid="ProductTagline"
                label="Product Tagline"
                value={draft.tagline}
                onChange={handleChange('tagline')}
              />
            ) : (
              entity.tagline
            )
          }
        />
        <CardMedia
          className={classes.media}
          image="/static/images/cards/paella.jpg"
          title="Paella dish"
        />
        <CardContent>
          {editing ? (
            <TextField
              className={classes.textField}
              fullWidth
              label="Product Description"
              inputProps={{ 'data-testid': 'ProductDescription' }}
              multiline
              value={draft.description}
              onChange={handleChange('description')}
              required
            />
          ) : (
            <Typography variant="body2" color="textSecondary" component="p">
              {entity.description}
            </Typography>
          )}
          <div>
            <div className={classes.buttonSection}>
              {editing && (
                <>
                  <Button
                    variant="contained"
                    component="label"
                    className={classes.formControl}
                    data-testid="FactSheetBtn"
                  >
                    {getFactSheetURL ? 'Replace' : 'Upload'} Fact Sheet
                    <input
                      type="file"
                      id="upload-fs"
                      onChange={handleFactSheetUpload}
                      data-testid="FactSheetInput"
                      hidden
                    />
                  </Button>
                </>
              )}
              {editing && getFactSheetURL ? (
                <Button
                  variant="contained"
                  component="label"
                  className={classes.formControl}
                  onClick={() => {
                    window.open(getFactSheetURL);
                  }}
                >
                  Download Fact Sheet
                </Button>
              ) : null}
              {editing && getFactSheetURL ? (
                <Button
                  variant="contained"
                  component="label"
                  className={classes.formControl}
                  onClick={() => {
                    deleteFactSheet();
                  }}
                >
                  Delete Fact Sheet
                </Button>
              ) : null}
              {editing && (
                <>
                  <div className={classes.wrapper}>
                    {dataSet !== undefined && dataSet.dataUploaded === true ? (
                      <Tooltip
                        title="Data set definitions can not be edited once data has been uploaded. Create a new product to change the definition."
                        placement="top"
                        arrow
                      >
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            component="label"
                            className={classes.formControl}
                            disabled
                            fullWidth
                          >
                            Define Data Sets
                          </Button>
                        </div>
                      </Tooltip>
                    ) : (
                      <Button
                        data-testid=" DefineDataSetsBtn"
                        variant="contained"
                        color="primary"
                        component="label"
                        className={classes.formControl}
                        onClick={() => handleOpenDraftDialog()}
                        disabled={loading}
                        fullWidth
                      >
                        Define Data Sets
                      </Button>
                    )}
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
            {editing && !getSampleURL && (!sampleData || sampleData === '') && (
              <Button
                data-testid="UploadSampleDataBtn"
                variant="contained"
                component="label"
              >
                Upload Sample Data (CSV)
                <input
                  type="file"
                  data-testid="UploadSampleDataInput"
                  onChange={handleSampleDataUpload}
                  accept=".csv"
                  hidden
                />
              </Button>
            )}
          </div>
          <Box pt={0}>
            {draft.tags.length > 0 && !editing ? (
              <Typography className={classes.sectionTitle} variant="h6">
                Tags
              </Typography>
            ) : null}
            {editing ? (
              <Typography className={classes.sectionTitle} variant="h6">
                Tags
              </Typography>
            ) : null}
            <Tags
              className={classes.tagClass}
              editing={editing}
              tagData={draft.tags}
              onAdd={handleAddTag}
              onDelete={handleDeleteTag}
            />
          </Box>
          <Typography className={classes.sectionTitle} variant="h6">
            Licensing
            {editing ? (
              <IconButton
                data-testid="licenseBtn"
                onClick={handleSave}
                component={Link}
                to={`./licenses?productId=${entity.id}`}
              >
                <EditIcon />
              </IconButton>
            ) : null}
          </Typography>
          <LicenseList />
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                data-testid="DeleteBtn"
                variant="outlined"
                className={classes.deleteButton}
                onClick={() => setDialog(true)}
              >
                Delete
              </Button>
            </Grid>
            <Box className={classes.buttonRow}>
              {dataSet && dataSet.landingZoneLink && (
                <Button
                  // component="a"
                  // href={dataSet.landingZoneLink}
                  // rel="noopener noreferrer"
                  // target="_blank"
                  // target="_blank"
                  variant="outlined"
                  onClick={() => setUploadDataSetModal(true)}
                >
                  Upload Data Set
                </Button>
              )}
              {editing ? (
                <Button onClick={handleCancel} variant="outlined" padding="8">
                  Cancel
                </Button>
              ) : null}
              <Button
                data-testid="EditBtn"
                variant="outlined"
                onClick={() => {
                  handleSave();
                }}
              >
                {editing ? 'Save' : 'Edit'}
              </Button>
              <Button
                variant="outlined"
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                data-testid="PreviewBtn"
              >
                Preview
                <ExpandMoreIcon
                  className={clsx('', {
                    [classes.expandOpen]: expanded,
                  })}
                />
              </Button>
              {dataSet !== undefined ? (
                <Button
                  data-testid="PublishBtn"
                  onClick={publishProduct}
                  variant="contained"
                  color={draft.status === 'Published' ? 'default' : 'secondary'}
                >
                  {draft.status === 'Published' ? 'Pull' : 'Publish'}
                </Button>
              ) : null}
              {publishWarning ? (
                <Alert severity="error">{publishWarningMessage}</Alert>
              ) : null}
            </Box>
          </Grid>
        </CardActions>
        {isDraft ? (
          <Typography data-testid="DraftProductPrompt">
            {isDraft ? 'This product is still a draft.' : null}
          </Typography>
        ) : null}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {!draft.columns.length &&
              !draft.rows.length &&
              editing &&
              !getSampleURL &&
              !sampleData && (
                <CardActions>
                  <Typography data-testid="productPreview">
                    {' '}
                    No Preview Available.
                  </Typography>
                </CardActions>
              )}
            {draft.columns && draft.rows && sampleData && (
              <PreviewDataTable
                columns={draft.columns}
                rows={draft.rows}
                editing={editing}
                onDropEvent={handleDrop}
                data-testid="PreviewDataTable"
              />
            )}
            {!draft.columns.length &&
              !draft.rows.length &&
              !sampleData &&
              !getSampleURL &&
              !editing && (
                <Typography data-testid="productPreview">
                  {' '}
                  No Preview Available.
                </Typography>
              )}

            {draft.columns.length && draft.rows.length && !sampleData ? (
              <Typography> Fetching data preview... </Typography>
            ) : null}

            {editing && getSampleURL && sampleData ? (
              <CardActions>
                <Button
                  variant="contained"
                  color="secondary"
                  component="label"
                  onClick={deleteSampleData}
                >
                  Delete Sample Data
                </Button>
              </CardActions>
            ) : null}
          </CardContent>
        </Collapse>
      </Card>
    );
  },
);
