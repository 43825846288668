import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid';
import { useHistory } from 'react-router-dom';
import { fetchInvoices, selectInvoices } from './paymentsSlice';
import { selectShopId } from '../SignInPage/shopSignInSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 0),
  },
  table: {
    height: 525,
    width: '100%',
  },
  description: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: 'flex', justifyContent: 'flex-end' }}
    >
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function PaymentsPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const shopId = useSelector(selectShopId);
  const invoiceStatus = useSelector((state) => state.invoices.status);
  const invoices = useSelector(selectInvoices);
  const history = useHistory();

  useEffect(() => {
    if (invoiceStatus === 'idle') {
      dispatch(
        fetchInvoices({
          shopId,
        }),
      );
    }
  }, [invoiceStatus, dispatch, shopId]);

  const columns = [
    {
      field: 'id',
      headerName: 'Order Number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'customerName',
      headerName: 'Buyer',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'dateIssued',
      headerName: 'Date Issued',
      type: 'date',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'price',
      headerName: 'Invoice Total',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <>
      <Typography className={classes.title} variant="h3">
        Invoices
      </Typography>
      <Typography className={classes.description} variant="body2">
        Review all of the invoices that have been issued to your customers.
      </Typography>
      {invoices != null ? (
        <div className={classes.table}>
          <DataGrid
            rows={invoices}
            columns={columns}
            pageSize={8}
            components={{
              Toolbar: CustomToolbar,
            }}
            onRowClick={(rowData) =>
              history.push(
                `/shop/${shopId}/invoice?invoiceId=${rowData.row.orderId}`,
              )
            }
          />
        </div>
      ) : (
        <Typography className={classes.description} variant="body2">
          No Invoices were found.
        </Typography>
      )}
    </>
  );
}

// const mapStateToProps = () => ({});

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPage);

export default PaymentsPage;
