import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
  selectAllOrders,
  selectOrderFilter,
} from 'pages/OrdersPage/ordersSlice';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { selectAllProducts } from '../../pages/management/ProductsPage/productsSlice';
import { selectAllSubscriptionOrders } from '../../pages/OrdersPage/subscriptionsSlice';
import { Order } from './order';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  header: {
    '& .MuiCardHeader-title': {
      color: theme.palette.text.primary,
    },
    padding: theme.spacing(2, 2, 0),
  },
  textField: {
    color: theme.palette.text.primary,
    width: '300px',
  },
  content: {
    paddingBottom: '0px !important',
    padding: theme.spacing(0, 2, 0),
  },
  actions: {
    alignSelf: 'flex-end',
    textAlign: 'end',
  },
  button: {
    margin: theme.spacing(2),
  },
  cancel: {
    color: 'white',
    backgroundColor: '#ff3c3c',
    '&:hover': {
      backgroundColor: '#dc3c3c',
    },
  },
  popover: {
    height: '200px',
  },

  hover: {},
}));

const OrderList = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const { tab } = props;

  const orderFilters = useSelector(selectOrderFilter);
  const products = useSelector(selectAllProducts);

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const subscriptionOrders = useSelector(selectAllSubscriptionOrders).map(
    (subOrder) => ({
      ...subOrder,
      products: products.filter((curProduct) =>
        subOrder.productIds.includes(curProduct.id),
      ),
    }),
  );

  const orders = useSelector(selectAllOrders)
    .filter((order) => order.status !== 'cart')
    .sort(function (a, b) {
      return b.createdAt - a.createdAt;
    })
    .filter((order) => {
      // filter by filter bar
      const orderDate = new Date(order.createdAt);
      const fromDate = new Date(orderFilters.from);

      // for the to date we want it to actually be tomorrow since it defaults to midnight on the day selected
      const toDate = new Date(orderFilters.to);
      toDate.setDate(toDate.getDate() + 1);

      if (!orderFilters.filterApplied) {
        return true;
      }
      return (
        orderDate >= fromDate &&
        orderDate < toDate &&
        order.customerName
          .toString()
          .toUpperCase()
          .includes(orderFilters.customerName.toUpperCase()) &&
        (order.id
          .toString()
          .toUpperCase()
          .includes(orderFilters.query.toUpperCase()) ||
          order.customerId
            .toString()
            .toUpperCase()
            .includes(orderFilters.query.toUpperCase()) ||
          order.shopId
            .toString()
            .toUpperCase()
            .includes(orderFilters.query.toUpperCase()))
      );
    })
    .filter((order) => {
      // filter by order status
      if (tab === 0) {
        return true;
      }
      if (tab === 1) {
        return order.status === 'submitted';
      }
      if (tab === 2) {
        return order.status === 'approved';
      }
      if (tab === 3) {
        return order.status === 'completed';
      }
      if (tab === 4) {
        return order.status === 'rejected';
      }
      return true;
    })
    .map((order) => ({
      ...order,
      products: products.filter((curProduct) =>
        order.productIds.includes(curProduct.id),
      ),
    }));
  const pages = Math.ceil(orders.length / rowsPerPage);
  const subscriptionPages = Math.ceil(subscriptionOrders.length / rowsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const formattedDate = (epochUnix) => {
    const date = new Date(epochUnix);
    return date.toString().substring(0, 15);
  };

  useEffect(() => {
    setPage(1);
  }, [tab]);

  // If subscriptions tab is selected
  if (tab === 5) {
    if (subscriptionOrders.length > 0) {
      return (
        <>
          {subscriptionOrders
            .slice(
              (page - 1) * rowsPerPage,
              (page - 1) * rowsPerPage + rowsPerPage,
            )
            .map((order) => (
              <Card key={order.id} className={classes.card}>
                <CardHeader
                  className={classes.header}
                  title={`Order #${order.id.substring(0, 5)}`}
                />
                <CardContent className={classes.content}>
                  <Grid
                    container
                    direction="row"
                    alignItems="stretch"
                    justify="flex-start"
                  >
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">
                        Status:{' '}
                        {order.status === 'approved'
                          ? 'Order approved'
                          : 'Order is pending'}
                      </Typography>
                      <Typography variant="subtitle1">
                        Placed: {formattedDate(order.createdAt)}
                      </Typography>
                      <Typography variant="subtitle1">
                        Last Updated: {formattedDate(order.updatedAt)}
                      </Typography>
                      {order.status === 'rejected' ? (
                        <Typography variant="subtitle1">
                          Rejection Reason: {order.reason}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="subtitle2">Includes</Typography>
                        </Grid>
                        {order.products?.map((curProduct) => (
                          <Grid
                            item
                            xs={12}
                            key={curProduct.id}
                            component="span"
                          >
                            <Typography variant="caption">
                              {curProduct.name}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid className={classes.actions}>
                      {order.status === 'approved' && (
                        <>
                          <Button
                            className={classes.button}
                            variant="outlined"
                            component={Link}
                            to={`/shop/${order.shopId}/manage-subscriptions?orderId=${order.id}`}
                          >
                            Manage Subscriptions
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          {
            // pagination
            <Grid container spacing={2}>
              <Grid item xs={6} md={8}>
                <Pagination
                  count={subscriptionPages}
                  page={page}
                  onChange={handleChange}
                  showFirstButton
                  showLastButton
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Orders Per Page</InputLabel>
                  <Select
                    value={rowsPerPage}
                    label="Orders Per Page"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <MenuItem value={3}>Three</MenuItem>
                    <MenuItem value={5}>Five</MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          }
        </>
      );
    }
    // no subscription orders
    return (
      <Card>
        <CardContent>
          <Typography>No subscription orders for the current shop.</Typography>
        </CardContent>
      </Card>
    );
  }

  // Any tab other than subscriptions selected
  // show message if there are no orders
  if (orders.length === 0) {
    if (tab === 1) {
      return (
        <Card>
          <CardContent>
            <Typography>No pending orders for the current shop.</Typography>
          </CardContent>
        </Card>
      );
    }
    if (tab === 2) {
      return (
        <Card>
          <CardContent>
            <Typography>No approved orders for the current shop.</Typography>
          </CardContent>
        </Card>
      );
    }
    if (tab === 3) {
      return (
        <Card>
          <CardContent>
            <Typography>No completed orders for the current shop.</Typography>
          </CardContent>
        </Card>
      );
    }
    if (tab === 4) {
      return (
        <Card>
          <CardContent>
            <Typography>No rejected orders for the current shop.</Typography>
          </CardContent>
        </Card>
      );
    }
    return (
      <Card>
        <CardContent>
          <Typography>No orders for the current shop.</Typography>
        </CardContent>
      </Card>
    );
  }

  // proceed to render if there are orders to render
  return (
    <>
      {orders
        .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
        .map((order) => (
          <Order key={order.id} order={order} formattedDate={formattedDate} />
        ))}
      {
        // pagination
        <Grid container spacing={2}>
          <Grid item xs={6} md={8}>
            <Pagination
              count={pages}
              page={page}
              onChange={handleChange}
              showFirstButton
              showLastButton
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="orders-per-page">Orders Per Page</InputLabel>
              <Select
                value={rowsPerPage}
                label="Orders Per Page"
                onChange={(e) => {
                  setRowsPerPage(e.target.value);

                  GAevent(
                    'engagement',
                    `orders_${rowsPerPage}_rows_perpage`,
                    'Change rows per page',
                  );
                }}
                labelId="orders-per-page"
              >
                <MenuItem value={3}>Three</MenuItem>
                <MenuItem value={5}>Five</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default injectIntl(OrderList);
