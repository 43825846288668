/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { manageBaseUrl, stripeKey } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const licensesAdapter = createEntityAdapter({
  selectId: (license) => license.id,
  sortComparer: null,
});

const initialState = licensesAdapter.getInitialState({
  status: 'idle',
  error: null,
  currency: 'usd',
  editRecentlyCreated: null,
  filter: {
    from: new Date('2021-01-01').toISOString().split('T')[0],
    to: new Date('2021-12-31').toISOString().split('T')[0],
    query: '',
    sortParam: 5,
  },
});

const licenseDraft = {
  id: '',
  shopId: '',
  productId: '',
  name: '',
  description: 'Enter your description here',
  agreementBody: 'Enter your agreement body here',
  price: 0,
  pricingUnit: 'fixedPrice',
  contentSpan: 'allRows',
  delivery: [],
  filterable: {},
  draftStatus: 'draft',
  billingFrequency: 'once',
  stripeProductId: '',
  stripePriceId: '',
};

export const createLicense = createAsyncThunk(
  'licenses/createLicense',
  async ({ shopId, productId, currency }) => {
    const license = { ...licenseDraft };
    license.id = uuidv4().toUpperCase();
    license.shopId = shopId;
    license.productId = productId;
    license.currency = currency;
    const createResp = await axios.post(`${manageBaseUrl}/licenses`, license);
    return createResp.data;
  },
);

export const fetchLicenses = createAsyncThunk(
  'licenses/fetchLicenses',
  async ({ shopId }) => {
    const resp = await axios.get(`${manageBaseUrl}/licenses`, {
      params: { shopId },
    });
    return { licenses: resp.data };
  },
);

export const fetchCurrency = createAsyncThunk(
  'licenses/fetchCurrency',
  async ({ shopId }) => {
    const resp = await axios.get(`${manageBaseUrl}/licenses/currency`, {
      params: { shopId },
    });
    return resp.data;
  },
);

export const updateLicense = createAsyncThunk(
  'licenses/updateLicense',
  async (license) => {
    license.draftStatus = 'published';
    await axios.put(`${manageBaseUrl}/licenses`, {
      ...license,
      stripeKey,
    });
    return license;
  },
);

export const updateLicenseFilterables = createAsyncThunk(
  'licenses/updateLicenseFilterables',
  async ({ shopId, productId, datasetColumns }) => {
    const params = {
      datasetColumns,
      productId,
      shopId,
    };
    const resp = await axios.put(
      `${manageBaseUrl}/licenses/update-license-filterables`,
      params,
    );
    return resp.data;
  },
);

export const deleteLicense = createAsyncThunk(
  'licenses/deleteLicense',
  async (params) => {
    await axios.delete(`${manageBaseUrl}/licenses`, {
      params,
    });
    return params.id;
  },
);

const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {
    filterEdited(state, action) {
      const prop = Object.keys(action.payload)[0];
      const value = action.payload[prop];
      state.filter[prop] = value;
    },
    updateCurrency(state, action) {
      state.currency = action.payload.currency;
    },
  },
  extraReducers: {
    [updateLicenseFilterables.pending]: (state) => {
      state.status = 'loading';
    },
    [createLicense.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchLicenses.pending]: (state) => {
      state.status = 'fetchLoading';
    },
    [updateLicense.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteLicense.pending]: (state) => {
      state.status = 'loading';
    },
    [updateLicenseFilterables.rejected]: (state, action) =>
      errorResponse(state, action),
    [createLicense.rejected]: (state, action) => errorResponse(state, action),
    [fetchLicenses.rejected]: (state, action) => errorResponse(state, action),
    [fetchCurrency.rejected]: (state, action) => errorResponse(state, action),
    [updateLicense.rejected]: (state, action) => errorResponse(state, action),
    [deleteLicense.rejected]: (state, action) => errorResponse(state, action),
    [createLicense.fulfilled]: (state, action) => {
      const license = action.payload;
      licensesAdapter.upsertOne(state, license);
      state.status = 'succeeded';
      state.editRecentlyCreated = license.id;
    },
    [fetchLicenses.fulfilled]: (state, action) => {
      if (action.payload) {
        const { licenses } = action.payload;
        licensesAdapter.setAll(state, licenses);
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving licenses: received null value';
      }
    },
    [fetchCurrency.fulfilled]: (state, action) => {
      if (action.payload) {
        const { currency } = action.payload;
        state.currency = currency;
      } else {
        state.currency = 'usd';
        state.error = 'Error retrieving currency: received null value';
      }
    },
    [updateLicense.fulfilled]: (state, action) => {
      state.editRecentlyCreated = null;
      const license = action.payload;
      licensesAdapter.updateOne(state, {
        id: license.id,
        changes: license,
      });
      state.status = 'succeeded';
    },
    [updateLicenseFilterables.fulfilled]: (state, action) => {
      const license = action.payload;
      licensesAdapter.updateOne(state, {
        id: license?.id,
        changes: license,
      });
      state.status = 'succeeded';
    },
    [deleteLicense.fulfilled]: (state, action) => {
      const id = action.payload;
      licensesAdapter.removeOne(state, id);
      state.status = 'succeeded';
      if (state.editRecentlyCreated === id) state.editRecentlyCreated = null;
    },
  },
});
export const SelectLicenses = (state) => state.licenses.entities;

export const { filterEdited, updateCurrency } = licensesSlice.actions;

export const {
  selectAll: selectAllLicenses,
  selectById: selectLicenseById,
  selectIds: selectLicenseIds,
} = licensesAdapter.getSelectors((state) => state.licenses);

export const selectFilter = (state) => state.licenses.filter;

export default licensesSlice.reducer;
