/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { manageBaseUrl } from 'config';
import ReactGA from 'react-ga4';
import axios from '../../../client/client';

const initialState = {
  googleTrackingId: '',
  googleTrackingIdstatus: 'idle',
  googleTrackingIderror: '',
  googleTrackingIdsaveSuccess: 'idle',
  mixPanelId: 'Aec-124-b45-aa1',
  monthlyRevenueStatus: 'idle',
  monthlyRevenue: {},
};

export const saveGID = createAsyncThunk('analytics/saveGID', async (params) => {
  const route = `${manageBaseUrl}/shops/update-gid`;
  const { data, status } = await axios.post(route, params);
  if (status === 200) {
    ReactGA.initialize(data);
  }
  return data;
});

export const fetchGID = createAsyncThunk(
  'analytics/fetchGID',
  async (shopId) => {
    const route = `${manageBaseUrl}/shops/get-gid`;
    const { data, status } = await axios.get(route, {
      params: { shopId },
    });
    if (status === 200 && data !== '') {
      ReactGA.initialize(data);
    }
    return data;
  },
);

export const fetchMonthlyRevenue = createAsyncThunk(
  'analytics/fetchMonthlyRevenue',
  async (shopId) => {
    const resp = await axios.get(`${manageBaseUrl}/invoices/revenue-by-month`, {
      params: {
        shopId,
      },
    });
    return resp.data;
  },
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: {
    [saveGID.pending]: (state) => {
      state.googleTrackingIdstatus = 'loading';
      state.googleTrackingIdsaveSuccess = 'idle';
    },
    [fetchGID.pending]: (state) => {
      state.googleTrackingIdstatus = 'loading';
    },
    [fetchMonthlyRevenue.pending]: (state) => {
      state.monthlyRevenueStatus = 'loading';
    },
    [saveGID.rejected]: (state, action) => {
      state.googleTrackingIdstatus = 'failed';
      state.googleTrackingIderror = action.error.message;
      state.googleTrackingIdsaveSuccess = 'error';
    },
    [fetchGID.rejected]: (state, action) => {
      state.googleTrackingIdstatus = 'failed';
      state.googleTrackingIderror = action.error.message;
    },
    [fetchMonthlyRevenue.rejected]: (state, action) => {
      state.monthlyRevenueStatus = 'failed';
      state.error = action.error.message;
    },
    [saveGID.fulfilled]: (state, action) => {
      if (action.payload) {
        state.googleTrackingId = action.payload;
        state.googleTrackingIdstatus = 'succeeded';
        state.googleTrackingIdsaveSuccess = 'success';
      } else {
        state.googleTrackingIdstatus = 'failed';
        state.googleTrackingIderror = 'Error updating google analytics id';
      }
    },
    [fetchGID.fulfilled]: (state, action) => {
      if (action.payload) {
        state.googleTrackingId = action.payload;
        state.googleTrackingIdstatus = 'succeeded';
      } else {
        state.googleTrackingIdstatus = 'failed';
        state.googleTrackingIderror = 'Error retrieving google analytics id';
      }
    },
    [fetchMonthlyRevenue.fulfilled]: (state, action) => {
      state.monthlyRevenue = action.payload;
      state.monthlyRevenueStatus = 'succeeded';
    },
  },
});

export default analyticsSlice.reducer;
