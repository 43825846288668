import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography, IconButton } from '@material-ui/core';
import { HelpOutline as HelpOutlineIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  toolTipFontSize: {
    fontSize: '1.2rem',
  },
}));

export default function CustomToolTip({ text }) {
  const classes = useStyles();

  return (
    <Tooltip
      title={
        <Typography variant="h2" className={classes.toolTipFontSize}>
          {text}
        </Typography>
      }
    >
      <IconButton aria-label="Page Help">
        <HelpOutlineIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}

export const toolTipMessages = {
  ProductPage: 'This is the product page',
  AttributesPage: 'This is the attributes page',
  LayoutPage: 'This is the layout page',
  CustomersPage: 'This is the customers page',
  OrdersPage: 'This is the orders page',
  AnalyticsPage: 'This is the analytics page',
};
