/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Popover,
  Grid,
  IconButton,
  Box,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  releaseApprovedData,
  updateApproval,
} from 'pages/OrdersPage/ordersSlice';
import fileDownload from 'js-file-download';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReactGA from 'react-ga4';
import axios from '../../client/client';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  header: {
    '& .MuiCardHeader-title': {
      color: theme.palette.text.primary,
    },
    padding: theme.spacing(2, 2, 0),
  },
  textField: {
    color: theme.palette.text.primary,
    width: '300px',
  },
  content: {
    paddingBottom: '0px !important',
    padding: theme.spacing(0, 2, 0),
  },
  actions: {
    alignSelf: 'flex-end',
    textAlign: 'end',
  },
  button: {
    margin: theme.spacing(2),
  },
  cancel: {
    color: 'white',
    backgroundColor: '#ff3c3c',
    '&:hover': {
      backgroundColor: '#dc3c3c',
    },
  },
  popover: {
    height: '200px',
  },

  hover: {},
}));

export const Order = injectIntl(({ formattedDate, order }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [approvalDialog, setApprovalDialog] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rejectDialog, setRejectDialog] = React.useState(false);
  const [rejectReason, setRejectReason] = React.useState('');
  const [rejectId, setRejectId] = React.useState(-1);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async (orderId) => {
    const resourcePath =
      '/data?productId=1384B0D3-0079-41D9-8597-0DCE814074DA&startDate=2019-01-01&endDate=2019-01-15';
    axios.get(axios.defaults.baseURL + resourcePath).then((response) => {
      fileDownload(JSON.stringify(response.data, null, 2), `${orderId}.json`);
    });

    GAevent('engagement', `orders_download_${orderId}`, 'Download order');
  };

  const handleApproval = (newStatus, orderId) => {
    dispatch(
      releaseApprovedData({
        newStatus,
        orderId,
        reason: newStatus === 'rejected' ? rejectReason : '',
      }),
    );
  };

  const handleRejection = (newStatus, orderId) => {
    dispatch(
      updateApproval({
        newStatus,
        orderId,
        reason: newStatus === 'rejected' ? rejectReason : '',
      }),
    );
  };

  return (
    <Card key={order.id} className={classes.card}>
      <CardHeader
        className={classes.header}
        title={`Order #${order.id.substring(0, 5)}`}
      />
      <CardContent className={classes.content}>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          justify="flex-start"
        >
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Placed: {formattedDate(order.createdAt)}
            </Typography>
            <Typography variant="subtitle1">
              Last Updated: {formattedDate(order.updatedAt)}
            </Typography>
            {order.status === 'rejected' ? (
              <Typography variant="subtitle1">
                Rejection Reason: {order.reason}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Includes</Typography>
              </Grid>
              {order.products?.map((curProduct) => (
                <Grid item xs={12} key={curProduct.id} component="span">
                  <Typography variant="caption">{curProduct.name}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid className={classes.actions}>
            <Button
              className={classes.button}
              component={Link}
              to={`/shop/${order.shopId}/order-detail?orderId=${order.id}`}
            >
              View Details
            </Button>
            {order.status === 'approved' ? (
              <>
                <Button
                  className={classes.button}
                  onClick={async () => handleDownload(order.id)}
                >
                  <DownloadIcon />
                  Download
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  className={classes.popover}
                >
                  <Box p={3}>
                    <TextField
                      className={classes.textField}
                      disabled
                      value="curl https://5u8m8qp2j8.execute-api.us-east-1.amazonaws.com/dev/data/\?productId\=1384B0D3-0079-41D9-8597-0D\[%E2%80%A6\]074DA\&startDate\=2019-01-01\&endDate\=2019-01-15"
                    ></TextField>
                    <IconButton aria-label="Clipboard">
                      <AssignmentIcon />
                    </IconButton>
                    <Typography variant="body2">
                      Manage your API settings in Delivery Preferences.
                    </Typography>
                  </Box>
                </Popover>
              </>
            ) : null}
            {order.status === 'submitted' && (
              <>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setRejectDialog(true);
                    setRejectId(order.id);
                  }}
                >
                  Reject
                </Button>
                <Button
                  className={classes.button}
                  onClick={() => setApprovalDialog(true)}
                >
                  Approve
                </Button>
              </>
            )}
            <Dialog
              open={approvalDialog}
              onClose={() => {
                setApprovalDialog(false);
              }}
            >
              <DialogTitle>Order Approval Confirmation</DialogTitle>
              <DialogContent>
                <Typography>
                  Are you sure you want to confirm this order? Data will be
                  released to the client after approval.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    setApprovalDialog(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleApproval('approved', order.id);
                    setApprovalDialog(false);
                  }}
                >
                  Approve
                </Button>
              </DialogActions>
            </Dialog>
            {/* order rejection dialog modal */}
            <Dialog
              open={rejectDialog}
              onClose={() => {
                setRejectReason('');
                setRejectId(-1);
                setRejectDialog(false);
              }}
            >
              <DialogTitle>Reason for Rejection</DialogTitle>
              <DialogContent>
                <Typography>
                  Please give a reason for rejecting the order.
                </Typography>
                <TextField
                  autoFocus
                  margin="dense"
                  id="reject-reason"
                  label="Rejection Reason"
                  onChange={(e) => setRejectReason(e.target.value)}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    setRejectReason('');
                    setRejectId(-1);
                    setRejectDialog(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleRejection('rejected', rejectId);
                    setRejectDialog(false);
                  }}
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
