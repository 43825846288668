import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Card,
  TextField,
  Typography,
  Snackbar,
  LinearProgress,
  Checkbox,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid';
import { Delete as DeleteIcon, StopRounded } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import ChartistGraph from 'react-chartist';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import CustomToolTip, { toolTipMessages } from 'features/toolTip/customToolTip';
import ReactGA from 'react-ga4';
import { saveGID, fetchMonthlyRevenue } from './analyticsSlice';
import { selectShopId } from '../SignInPage/shopSignInSlice';

import { selectAllProducts } from '../ProductsPage/productsSlice';

const colours = ['pink', 'purple', 'blue', 'yellow', 'red', 'magenta'];
const aspectRatio = 'ct-double-octave';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 0),
  },
  graph: {
    '& g.ct-series > line, path': {
      stroke: '#1D3176 !important',
    },
  },
  chartCard: {
    margin: theme.spacing(2),
    padding: theme.spacing(2, 3, 2, 1),
  },
  row: {
    height: 21,
    '&:nth-of-type(odd)': {
      backgroundColor: '#e4e4e4',
    },
  },
  cell: {
    height: 'auto',
    padding: 0,
  },
  emptycell: {
    height: 'auto',
    padding: 0,
    borderBottom: 'hidden',
  },
  form: {
    padding: theme.spacing(0, 0.5, 2),
    minWidth: 200,
    maxWidth: 200,
  },
  barForm: {
    padding: theme.spacing(0, 0.5, 2),
    minWidth: 200,
    maxWidth: 200,
  },
  monthForm: {
    padding: theme.spacing(0, 0.5, 2),
    minWidth: 175,
    maxWidth: 175,
  },
  select: {
    height: 30,
  },
  button: {
    margin: theme.spacing(1),
  },
  deleteButton: {
    color: '#d63429',
    marginTop: '0.5rem',
  },
  graphTypography: {
    marginLeft: '1rem',
  },
  graphStyle: {
    'aspect-ratio': '.ct-octave',
    '& .ct-series-a .ct-bar': { stroke: colours[0] },
    '& .ct-series-b .ct-bar': { stroke: colours[1] },
    '& .ct-series-c .ct-bar': { stroke: colours[2] },
    '& .ct-series-d .ct-bar': { stroke: colours[3] },
    '& .ct-series-e .ct-bar': { stroke: colours[4] },
    '& .ct-series-f .ct-bar': { stroke: colours[5] },
    '& .ct-series-a .ct-line': { stroke: colours[0] },
    '& .ct-series-b .ct-line': { stroke: colours[1] },
    '& .ct-series-c .ct-line': { stroke: colours[2] },
    '& .ct-series-d .ct-line': { stroke: colours[3] },
    '& .ct-series-e .ct-line': { stroke: colours[4] },
    '& .ct-series-f .ct-line': { stroke: colours[5] },
    '& .ct-series-a .ct-point': { stroke: colours[0] },
    '& .ct-series-b .ct-point': { stroke: colours[1] },
    '& .ct-series-c .ct-point': { stroke: colours[2] },
    '& .ct-series-d .ct-point': { stroke: colours[3] },
    '& .ct-series-e .ct-point': { stroke: colours[4] },
    '& .ct-series-f .ct-point': { stroke: colours[5] },
    '& .ct-label': { color: theme.palette.text.primary },
    '& .ct-grid': { stroke: theme.palette.text.primary },
    borderRadius: '3px',
    boxShadow:
      '0 12px 20px -10px rgba(0,0,0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0,0,0, 0.2)',
  },
  legendItem: {
    margin: theme.spacing(1),
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: 'flex', justifyContent: 'flex-end' }}
    >
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns = [
  {
    field: 'month',
    headerName: 'Month',
    flex: 0.6,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'productName',
    headerName: 'Product',
    flex: 3,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'numOrders',
    headerName: '# Orders',
    flex: 0.6,
    headerAlign: 'left',
    align: 'right',
  },
  {
    field: 'revenue',
    headerName: 'Revenue ($)',
    flex: 0.75,
    headerAlign: 'left',
    align: 'right',
  },
];

const granularities = [
  { id: 'none', label: '-' },
  { id: 'all', label: 'All' },
  { id: 'mtd', label: 'Month to Date' },
  { id: 'pm', label: 'Previous Month' },
  { id: 'ytd', label: 'Year to Date' },
  { id: 'py', label: 'Previous Year' },
];

const chartTypes = [
  { id: 'bar-sbs', label: 'Bar - Side By Side', type: 'Bar', stacked: false },
  { id: 'bar-stacked', label: 'Bar - Stacked', type: 'Bar', stacked: true },
  { id: 'line', label: 'Line', type: 'Line', stacked: false },
];

const GAevent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};

function AnalyticsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stringCompareFunc = (a, b) => {
    if (a.productName < b.productName) return -1;
    if (a.productName > b.productName) return 1;
    return 0;
  };

  const [granularity, setGranularity] = React.useState('all');
  const [chartType, setChartType] = React.useState('bar-sbs');
  const [chartTypeInfo, setChartTypeInfo] = React.useState({
    id: 'bar-sbs',
    label: 'Bar - Side By Side',
    type: 'Bar',
    stacked: false,
  });
  const [data, setData] = React.useState({ labels: [], series: [] });
  const [products] = React.useState(
    [{ name: 'All Products', id: 'all' }].concat(
      useSelector(selectAllProducts)
        .map((curProduct) => ({ name: curProduct.name, id: curProduct.id }))
        .sort(stringCompareFunc),
    ),
  );
  const [filters, setFilters] = React.useState({
    products: products.map((curProd) => curProd.id),
    from: '',
    to: '',
  });
  const shopId = useSelector(selectShopId);
  const monthlyRevenueStatus = useSelector(
    (state) => state.analytics.monthlyRevenueStatus,
  );
  const monthlyRevenue = useSelector((state) => state.analytics.monthlyRevenue);
  const [monthlyRevenueRows, setMonthlyRevenueRows] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [manualDate, setManualDate] = React.useState(false);

  const getNumMonths = (startMonth, startYear, endMonth, endYear) =>
    (endYear - startYear) * 12 + endMonth - startMonth + 1;
  // fetches monthly revenue info
  useEffect(() => {
    if (monthlyRevenueStatus === 'idle') dispatch(fetchMonthlyRevenue(shopId));
  }, [dispatch, monthlyRevenueStatus, shopId]);

  // turns the monthly revenue info from the redux store into an array of rows
  useEffect(() => {
    // Note: Object.entries(monthlyRevenue) seems to sort the key/value values by month/year before giving us back the array
    const allRows = Object.entries(monthlyRevenue).reduce(
      (acc, [date, productObject]) => {
        const month = date === 'no_date' ? '' : date;
        const productsArray = Object.entries(productObject)
          .map(([id, productInfo]) => ({
            id: `${date}-${id}`,
            productId: id,
            month,
            productName: productInfo.name,
            numOrders: productInfo.numOrders,
            revenue: productInfo.revenue,
          }))
          .sort(stringCompareFunc);
        return [...acc, ...productsArray];
      },
      [],
    );
    setMonthlyRevenueRows(allRows);
    setFilteredRows(allRows);
  }, [monthlyRevenue]);

  // turns the monthly revenue info from the redux store into data for the table
  useEffect(() => {
    const today = new Date();
    const todayMonth = today.getMonth() + 1;
    const todayYear = today.getFullYear();
    const { from, to } = filters;
    let fromMonth;
    let fromYear;
    let toMonth;
    let toYear;
    if (from !== '' && to !== '') {
      [fromYear, fromMonth] = from.split('-').map((a) => parseInt(a, 10));
      [toYear, toMonth] = to.split('-').map((a) => parseInt(a, 10));
    } else if (from !== '') {
      [fromYear, fromMonth] = from.split('-').map((a) => parseInt(a, 10));
      toMonth = fromMonth === 1 ? 12 : fromMonth - 1;
      toYear = fromMonth === 1 ? fromYear : fromYear + 1;
      // sets the end month and year to current month and year if automatic value is in the future
      if (toYear * 100 + toMonth > todayYear * 100 + todayMonth) {
        toMonth = todayMonth;
        toYear = todayYear;
      }
    } else if (to !== '') {
      [toYear, toMonth] = to.split('-').map((a) => parseInt(a, 10));
      fromMonth = toMonth === 12 ? 1 : toMonth + 1;
      fromYear = toMonth === 12 ? toYear : toYear - 1;
    } else {
      toMonth = todayMonth;
      toYear = todayYear;
      fromMonth = todayMonth === 12 ? 1 : todayMonth + 1;
      fromYear = todayMonth === 12 ? todayYear : todayYear - 1;
    }
    const numMonths = getNumMonths(fromMonth, fromYear, toMonth, toYear);
    const months = [];
    const dataInfo = {};
    for (let i = 0; i < numMonths; i += 1) {
      const curMonth = ((fromMonth + i - 1) % 12) + 1;
      const curYear = fromYear + Math.floor((fromMonth + i - 1) / 12);
      const formattedMonth = `${
        curMonth < 10 ? `0${curMonth}` : curMonth
      }/${curYear}`;
      months.push(formattedMonth);
      if (monthlyRevenue[formattedMonth] !== undefined) {
        const curProducts = Object.entries(monthlyRevenue[formattedMonth]);
        for (let j = 0; j < curProducts.length; j += 1) {
          const [curProdId, curRevData] = curProducts[j];
          if (
            filters.products.includes('all') ||
            filters.products.includes(curProdId)
          ) {
            if (dataInfo[curProdId] === undefined) {
              const newArray = new Array(numMonths).fill(0);
              newArray[i] = curRevData.revenue;
              dataInfo[curProdId] = {
                name: curRevData.name,
                totalRevenue: curRevData.revenue,
                data: newArray,
              };
            } else {
              dataInfo[curProdId].totalRevenue += curRevData.revenue;
              dataInfo[curProdId].data[i] = curRevData.revenue;
            }
          }
        }
      }
    }
    const sortedDataArray = Object.entries(dataInfo)
      .map((curDataInfo) => ({ ...curDataInfo[1], id: curDataInfo[0] }))
      .sort((a, b) => b.totalRevenue - a.totalRevenue);

    const otherProducts = sortedDataArray.slice(5);
    const otherProductsData = new Array(numMonths).fill(0);

    for (let i = 0; i < otherProducts.length; i += 1) {
      for (let j = 0; j < numMonths; j += 1) {
        otherProductsData[j] += otherProducts[i].data[j];
      }
    }

    const finalSeries =
      sortedDataArray.length <= 5
        ? sortedDataArray
        : [
            ...sortedDataArray.slice(0, 5),
            {
              id: 'other',
              name: 'Other Products',
              totalRevenue: otherProducts.reduce(
                (acc, curElement) => acc + curElement.totalRevenue,
                0,
              ),
              data: otherProductsData,
            },
          ];
    setData({
      labels: months,
      series: finalSeries,
    });
  }, [monthlyRevenue, filters]);

  // updating table values everytime filters is changed
  useEffect(() => {
    const { from, to } = filters;
    let fromMonth;
    let fromYear;
    let toMonth;
    let toYear;
    if (from !== '') [fromYear, fromMonth] = from.split('-');
    if (to !== '') [toYear, toMonth] = to.split('-');
    setFilteredRows(
      monthlyRevenueRows.filter((curRow) => {
        const [month, year] = curRow.month.split('/');
        return (
          (filters.products.includes('all') ||
            filters.products.includes(curRow.productId)) &&
          (from === '' ||
            year > fromYear ||
            (year === fromYear && month >= fromMonth)) &&
          (to === '' || year < toYear || (year === toYear && month <= toMonth))
        );
      }),
    );
  }, [filters, monthlyRevenueRows]);

  // applying filters from selecting date from dropdown menu
  useEffect(() => {
    const curDate = new Date();
    const curMonth = curDate.getMonth() + 1;
    const curYear = curDate.getFullYear();
    const prevMonth = curMonth === 1 ? 12 : curMonth - 1;
    switch (granularity) {
      case 'all':
        setFilters((prevValue) => ({ ...prevValue, from: '', to: '' }));
        break;
      case 'mtd':
        setFilters((prevValue) => ({
          ...prevValue,
          from: `${curYear}-${curMonth < 10 ? `0${curMonth}` : curMonth}`,
          to: `${curYear}-${curMonth < 10 ? `0${curMonth}` : curMonth}`,
        }));
        break;
      case 'pm':
        setFilters((prevValue) => ({
          ...prevValue,
          from: `${curMonth === 1 ? curYear - 1 : curYear}-${
            prevMonth < 10 ? `0${prevMonth}` : prevMonth
          }`,
          to: `${curMonth === 1 ? curYear - 1 : curYear}-${
            prevMonth < 10 ? `0${prevMonth}` : prevMonth
          }`,
        }));
        break;
      case 'ytd':
        setFilters((prevValue) => ({
          ...prevValue,
          from: `${curYear}-01`,
          to: `${curYear}-${curMonth < 10 ? `0${curMonth}` : curMonth}`,
        }));
        break;
      case 'py':
        setFilters((prevValue) => ({
          ...prevValue,
          from: `${curYear - 1}-01`,
          to: `${curYear - 1}-12`,
        }));
        break;
      default:
    }
  }, [granularity]);
  useEffect(() => {
    if (manualDate) setGranularity('none');
  }, [filters.from, filters.to, manualDate]);

  // sets the chart info based on the selection menu
  useEffect(() => {
    setChartTypeInfo(chartTypes.find((curType) => curType.id === chartType));
  }, [chartType]);

  const [draftGoogleTrackingId, setDraftGoogleTrackingId] = React.useState(
    useSelector((state) => state.analytics.googleTrackingId),
  );
  const [draftMixPanelId, setMixPanelId] = React.useState(
    useSelector((state) => state.analytics.mixPanelId),
  );
  const [googleErrText, setGoogleErrText] = React.useState('');
  const [mixpanelErrText] = React.useState('');
  const [integrationChange, setIntegrationChange] = React.useState(false);
  const googleRegex = /^([UGAD][AWC-]-*[A-Za-z0-9]{4,10}(-[0-9]{1,4})?)$/;
  const [openSuccessBanner, setOpenSuccessBanner] = React.useState(false);
  const [openErrorBanner, setOpenErrorBanner] = React.useState(false);

  // Table Functions
  const onGranularityChange = (e) => {
    const { value } = e.target;
    setManualDate(false);
    setGranularity(value);
  };

  const handleMenuChange = (e) => {
    const { value } = e.target;
    if (value.includes('all') && !filters.products.includes('all'))
      setFilters({
        ...filters,
        products: products.map((curProd) => curProd.id),
      });
    else if (filters.products.includes('all') && !value.includes('all')) {
      setFilters({ ...filters, products: [] });
    } else if (
      filters.products.length === products.length &&
      value.length < products.length
    ) {
      const allIndex = value.indexOf('all');
      setFilters({
        ...filters,
        products: [...value.slice(0, allIndex), ...value.slice(allIndex + 1)],
      });
    } else if (
      filters.products.length !== products.length &&
      !filters.products.includes('all') &&
      value.length === products.length - 1
    ) {
      setFilters({
        ...filters,
        products: ['all', ...value],
      });
    } else {
      setFilters({ ...filters, products: value });
    }
  };

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessBanner(false);
    setOpenErrorBanner(false);
  };

  const handleEdit = (e, prop) => {
    setIntegrationChange(true);
    if (prop === 'google') {
      setDraftGoogleTrackingId(e.target.value);
    } else {
      setMixPanelId(e.target.value);
    }
  };

  const handleClear = (prop) => {
    setIntegrationChange(true);
    if (prop === 'google') {
      setDraftGoogleTrackingId('G-XXXXXXXXXX');
    }
  };

  const bannerStatus = useSelector(
    (state) => state.analytics.googleTrackingIdsaveSuccess,
  );

  const handleBanner = (status) => {
    if (status === 'success') {
      setOpenSuccessBanner(true);
    } else if (status === 'error') {
      setOpenErrorBanner(true);
    }
  };

  useEffect(() => {
    handleBanner(bannerStatus);
  }, [bannerStatus]);

  const handleIntegrationChange = () => {
    if (draftGoogleTrackingId.length > 0) {
      if (googleRegex.test(draftGoogleTrackingId)) {
        setGoogleErrText('');
        dispatch(saveGID({ gid: draftGoogleTrackingId, shopId }));
        setIntegrationChange(false);
      } else {
        setGoogleErrText('Invalid ID');
      }
    } else {
      // TOOO: Implement MixPanel Integration
    }
  };

  const SalesGraph = () => {
    if (data.labels.length === 0 || data.series.length === 0)
      return (
        <Typography className={classes.graphTypography}>
          There is no data to display.
        </Typography>
      );
    return (
      <Box px={3} justifyContent="center">
        <Typography variant="h6">Aggregated Sales over Time</Typography>
        <Grid container justify="center">
          {data.series.map((curSeries, index) => (
            <Grid item key={curSeries.id}>
              <Grid container className={classes.legendItem}>
                <Grid item>
                  <StopRounded style={{ fill: colours[index] }} />
                </Grid>
                <Grid item>
                  <Typography>{curSeries.name}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <ChartistGraph
          className={`${aspectRatio} ${classes.graphStyle}`}
          data={data}
          type={chartTypeInfo.type}
          options={{
            stackBars: chartTypeInfo.stacked,
          }}
          key={`${chartTypeInfo.id}-chart`}
        />
      </Box>
    );
  };

  return (
    <>
      <Snackbar
        open={openSuccessBanner}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Integration settings saved successfully! Please reload to apply
          changes.
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorBanner}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          Error occurred saving integration changes. Please try again!
        </Alert>
      </Snackbar>
      <Typography className={classes.title} variant="h3">
        Sales Analytics <CustomToolTip text={toolTipMessages.AnalyticsPage} />
      </Typography>
      <Card className={classes.chartCard}>
        {monthlyRevenueStatus !== 'succeeded' ? (
          <LinearProgress />
        ) : (
          <SalesGraph />
        )}
      </Card>
      <Grid container direction="row" justify="flex-end" alignContent="stretch">
        <Grid item>
          <Grid
            container
            direction="column"
            justify="space-between"
            style={{ height: '100%' }}
          >
            <Grid item>
              <InputLabel id="product-select-label">Product</InputLabel>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className={classes.form}
                component="fieldset"
              >
                <Select
                  variant="outlined"
                  multiple
                  className={classes.select}
                  renderValue={(selected) =>
                    selected.includes('all')
                      ? 'All Products'
                      : 'Filtered Products'
                  }
                  labelId="product-select-label"
                  value={filters.products}
                  onChange={handleMenuChange}
                >
                  {products.map((prod) => (
                    <MenuItem key={prod.id} value={prod.id}>
                      <Checkbox
                        inputProps={{ 'data-testid': `checkbox${prod.name}` }}
                        checked={filters.products.indexOf(prod.id) > -1}
                      />
                      <ListItemText primary={prod.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <InputLabel id="date-select-label">Date</InputLabel>
          <Grid container direction="row" alignItems="flex-end">
            <Grid item>
              <FormControl
                variant="outlined"
                className={classes.monthForm}
                component="fieldset"
              >
                <TextField
                  id="dateFrom"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: filters.to,
                    'data-testid': 'calendarFrom',
                  }}
                  type="month"
                  value={filters.from}
                  onChange={(e) => {
                    setManualDate(true);
                    setFilters({
                      ...filters,
                      from: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className={classes.monthForm}
                component="fieldset"
              >
                <TextField
                  id="dateTo"
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: filters.from,
                    'data-testid': 'calendarTo',
                  }}
                  type="month"
                  value={filters.to}
                  onChange={(e) => {
                    setManualDate(true);
                    setFilters({
                      ...filters,
                      to: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className={classes.form}
                component="fieldset"
              >
                <Select
                  variant="outlined"
                  labelId="date-select-label"
                  className={classes.select}
                  value={granularity}
                  onChange={(e) => {
                    onGranularityChange(e);

                    GAevent(
                      'engagement',
                      `analytics_sort_by_${granularity}`,
                      'Sort by time range',
                    );
                  }}
                >
                  {granularities
                    .filter((curGran) =>
                      granularity === 'none' ? true : curGran.id !== 'none',
                    )
                    .map((gran) => (
                      <MenuItem key={gran.id} value={gran.id}>
                        <div data-testid={`${gran.label}`}>{gran.label}</div>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justify="space-between"
            style={{ height: '100%' }}
          >
            <Grid item>
              <InputLabel id="chart-select-label">Chart</InputLabel>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                className={classes.barForm}
                component="fieldset"
              >
                <Select
                  variant="outlined"
                  className={classes.select}
                  labelId="chart-select-label"
                  value={chartType}
                  onChange={(e) => {
                    setChartType(e.target.value);

                    GAevent(
                      'engagement',
                      `analytics_sort_by_${chartType}`,
                      'Change analytics chart type',
                    );
                  }}
                >
                  {chartTypes.map((curType) => (
                    <MenuItem key={curType.id} value={curType.id}>
                      {curType.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {monthlyRevenueStatus !== 'succeeded' ? (
        <LinearProgress />
      ) : (
        <Grid container style={{ height: 460, width: '100%' }}>
          <DataGrid
            data-testid="DataTable"
            rows={filteredRows}
            columns={columns}
            pageSize={6}
            disableColumnMenu
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      )}
      <Typography className={classes.title} variant="h5">
        Integrations
      </Typography>
      <Grid container>
        <Grid item xs={4}>
          <FormControl className={classes.form} component="fieldset">
            <TextField
              label="GA4 Measurement ID"
              value={draftGoogleTrackingId}
              error={googleErrText.length > 0}
              inputProps={{ 'data-testid': 'GoogleAnalyticsTextfield' }}
              helperText={googleErrText}
              onChange={(e) => handleEdit(e, 'google')}
            />
          </FormControl>
          <IconButton
            variant="contained"
            className={classes.deleteButton}
            onClick={() => handleClear('google')}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <FormControl className={classes.form} component="fieldset">
            <TextField
              label="MixPanel"
              value={draftMixPanelId}
              error={mixpanelErrText.length > 0}
              inputProps={{ 'data-testid': 'MixPanelTextfield' }}
              helperText={mixpanelErrText}
              onChange={(e) => handleEdit(e, 'mixpanel')}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          disabled={!integrationChange}
          onClick={handleIntegrationChange}
        >
          Save
        </Button>
      </Box>
    </>
  );
}

export default AnalyticsPage;
