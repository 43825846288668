/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { manageBaseUrl, stripeKey, shopCurrencyList } from 'config';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Grid,
  Snackbar,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '@material-ui/core';
import { ColorPicker } from 'material-ui-color';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga4';
import { updateThemeByShop } from './settingsSlice';
import {
  selectShopId,
  updateShopById,
} from '../management/SignInPage/shopSignInSlice';
// import { Store } from '@material-ui/icons';

import { linkConnectedAccount } from './paymentsSlice';
import { updateCurrency } from '../management/LicensesPage/licensesSlice';
const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 0),
  },
  radio: {
    '&$checked': {},
  },
  checked: {},
  Button: {
    margin: theme.spacing(1),
    width: '40ch',
  },
  card: {
    margin: theme.spacing(2, 0),
  },
  status: {
    flexGrow: 1,
  },
  imgPreview: {
    maxWidth: 400,
    maxHeight: 400,
  },
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  textField: {
    margin: theme.spacing(1),
    width: '25ch',
  },
}));

function SettingsPage() {
  const dispatch = useDispatch();
  const shopId = useSelector(selectShopId);
  const classes = useStyles();
  const shopInfo = useSelector((state) => state.user.shopInfo);
  const primaryLightColor = useSelector(
    (state) => state.settings.primaryLightColor,
  );
  const secondaryLightColor = useSelector(
    (state) => state.settings.secondaryLightColor,
  );
  const lightFontColor = useSelector((state) => state.settings.lightFontColor);
  const primaryDarkColor = useSelector(
    (state) => state.settings.primaryDarkColor,
  );
  const secondaryDarkColor = useSelector(
    (state) => state.settings.secondaryDarkColor,
  );
  const darkFontColor = useSelector((state) => state.settings.darkFontColor);
  const isDarkLight = useSelector((state) => state.settings.isDarkLight);

  const [putIconImageURL, setPutIconImageURL] = useState(false);
  const [putAppbarImageURL, setPutAppbarImageURL] = useState(false);
  const [iconImage, setIconImage] = useState('');
  const [appbarImage, setAppbarImage] = useState('');

  const [imageName, setimageName] = useState('');
  const [previewIconImage, setpreviewIconImage] = useState(false);
  const [previewAppImage, setpreviewAppImage] = useState(false);

  const [snackbar, setSnackbar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarSeverity, setSnackBarSeverity] = React.useState('');

  const [ShopNameText, setShopNameText] = useState(shopInfo?.name || '');
  const [ShopCurrencyText, setShopCurrencyText] = useState(
    shopInfo?.currency || 'usd',
  );
  const [primaryLightColorText, setPrimaryLightColorText] = useState(
    primaryLightColor || '',
  );
  const [secondaryLightColorText, setSecondaryLightColorText] = useState(
    secondaryLightColor || '',
  );
  const [lightFontColorText, setLightFontColorText] = useState(
    lightFontColor || '',
  );
  const [primaryDarkColorText, setPrimaryDarkColorText] = useState(
    primaryDarkColor || '',
  );
  const [secondaryDarkColorText, setSecondaryDarkColorText] = useState(
    secondaryDarkColor || '',
  );
  const [darkFontColorText, setDarkFontColorText] = useState(
    darkFontColor || '',
  );
  const [isDarkLightValue, setIsDarkLightValue] = useState(
    isDarkLight || false,
  );

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  useEffect(() => {
    if (iconImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setpreviewIconImage(reader.result);
      };
      reader.readAsDataURL(iconImage);
    } else {
      setpreviewIconImage(null);
    }
    if (appbarImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setpreviewAppImage(reader.result);
      };
      reader.readAsDataURL(appbarImage);
    } else {
      setpreviewAppImage(null);
    }
  }, [iconImage, appbarImage, imageName]);

  const getPreSignedURLs = async () => {
    const preSignedURLS = await Promise.all([
      axios.get(`${manageBaseUrl}/shop-theme/generate-put-url`, {
        params: {
          fileName: `${shopId}-icon.png`,
        },
      }),
      axios.get(`${manageBaseUrl}/shop-theme/generate-put-url`, {
        params: {
          fileName: `${shopId}-appbar.png`,
        },
      }),
    ]);
    setPutIconImageURL(preSignedURLS[0].data);
    setPutAppbarImageURL(preSignedURLS[1].data);
  };

  const handleSnackbarClose = () => {
    setSnackbar(false);
  };

  const SnackBarAlert = () => (
    <Snackbar
      open={snackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity={snackBarSeverity}>
        {snackBarMessage}
      </Alert>
    </Snackbar>
  );

  //   const handleThemeSave = () => {
  //     dispatch(
  //       updateThemeByShop({
  //         shopId,
  //         themeObject: {
  //           primaryLightColor: primaryLightColorText,
  //           secondaryLightColor: secondaryLightColorText,
  //           lightFontColor: lightFontColorText,
  //           primaryDarkColor: primaryDarkColorText,
  //           secondaryDarkColor: secondaryDarkColorText,
  //           darkFontColor: darkFontColorText,
  //           isDarkLight: isDarkLightValue,
  //         },
  //       }),
  //     );
  //     setSnackBarMessage('Theme Saved!');
  //     setSnackBarSeverity('success');
  //     setSnackbar(true);
  //   };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // if file is smaller than 1 MB (1048576 Bytes)
    if (file !== undefined) {
      if (file.size < 1048576) {
        Object.defineProperty(file, 'name', {
          writable: true,
          value: `${shopId}-${imageName}.png`,
        });
        if (imageName === 'icon') {
          setIconImage(file);
        } else if (imageName === 'appbar') {
          setAppbarImage(file);
        }
        if (putIconImageURL && imageName === 'icon') {
          uploadImage(putIconImageURL, null, file);

          GAevent(
            'engagement',
            'settings_upload_iconimage',
            'Upload icon image',
          );
        } else if (putAppbarImageURL && imageName === 'appbar') {
          uploadImage(null, putAppbarImageURL, file);

          GAevent(
            'engagement',
            'settings_upload_appbarimage',
            'Upload appbar image',
          );
        }
        setSnackbar(false);
      } else {
        setSnackBarMessage(
          'Image File Size is greater than 1 Megabyte please try uploading a smaller image',
        );
        setSnackBarSeverity('error');
        setSnackbar(true);
      }
    }
  };

  const uploadImage = async (putIconURL, putAppbarURL, file) => {
    const options = {
      headers: {
        'Content-Type': 'image/png',
      },
    };
    if (putIconURL !== null) {
      await axios.put(putIconURL, file, options);
      setSnackBarMessage('Icon Image uploaded successfully!');
      setSnackBarSeverity('success');
      setSnackbar(true);
    }
    if (putAppbarURL !== null) {
      await axios.put(putAppbarURL, file, options);
      setSnackBarMessage('Appbar Image uploaded successfully!');
      setSnackBarSeverity('success');
      setSnackbar(true);
    }
  };

  const handleSetIsDarkLight = () => {
    setPrimaryDarkColorText(primaryLightColorText);
    setSecondaryDarkColorText(secondaryLightColorText);
    setDarkFontColorText(lightFontColorText);
  };

  // create function needs better wording
  const handleStripeCreate = () => {
    dispatch(
      linkConnectedAccount({
        key: stripeKey,
        returnURL: window.location.href,
        shopId,
      }),
    );
  };

  // delete function used for testing
  // const handleStripeDelete = () => {
  // if (stripeAccountId !== '') {
  //   dispatch(
  //     deleteConnectedAccount({
  //       key: stripeKey,
  //       accountId: stripeAccountId,
  //     }),
  //   );
  // }
  // };

  return (
    <>
      <SnackBarAlert />
      <Typography className={classes.title} variant="h3">
        Settings
      </Typography>
      <Card className={classes.card}>
        <CardHeader
          title="Shop Details"
          subheader="Add Your info to your shop"
        />
        <CardContent>
          <Typography variant="h6">Shop Name</Typography>
          <TextField
            className={classes.textField}
            label="Your shops name"
            inputProps={{ 'data-testid': 'ShopNameTextfield' }}
            value={ShopNameText}
            onChange={(e) => {
              setShopNameText(e.target.value);
            }}
            placeholder="Enter Shop Name"
          />
          <Typography variant="h6">Shop Currency</Typography>
          <TextField
            select
            className={classes.textField}
            label="Your shops currency"
            inputProps={{ 'data-testid': 'ShopCurrencyTextfield' }}
            value={ShopCurrencyText}
            onChange={(e) => {
              setShopCurrencyText(e.target.value);
            }}
            placeholder="Enter Shop Currency"
          >
            {shopCurrencyList.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {currency.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>
        </CardContent>
        <CardActions disableSpacing>
          <Grid container item justify="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(
                  updateShopById({
                    shopId,
                    UpdatedShopName: ShopNameText,
                    UpdatedShopCurrency: ShopCurrencyText,
                  }),
                );
                dispatch(updateCurrency({ currency: ShopCurrencyText }));
                setSnackBarMessage('Shop Details Saved!');
                setSnackBarSeverity('success');
                setSnackbar(true);

                GAevent(
                  'engagement',
                  'settings_update_details',
                  'Update shop details',
                );
              }}
            >
              Save Shop Details
            </Button>
          </Grid>
        </CardActions>
      </Card>
      <Card className={classes.card}>
        <CardHeader
          title="Stripe Integration"
          subheader="Link your Stripe Account"
        />
        <CardActions disableSpacing>
          <Grid container item justify="flex-start">
            {/* Create buttom needs better wording, because create button creates connected account and links it all in one process */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleStripeCreate}
            >
              Link Stripe Account
            </Button>
            {/* Delete button was only used for testing and deleting test accounts */}
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleStripeDelete}
            >
              Delete Stripe Account
            </Button> */}
          </Grid>
        </CardActions>
      </Card>
      <Card className={classes.card}>
        <CardHeader title="Colour and Theme" subheader="Customize your Shop" />
        <CardContent>
          <Grid container spacing={5}>
            <Grid item>
              {isDarkLightValue ? (
                ''
              ) : (
                <Typography variant="h5">Light Mode</Typography>
              )}
              <Typography variant="h6">Primary Colour</Typography>
              <Typography variant="subtitle1">
                Click on a box to choose the Colour
              </Typography>
              <ColorPicker
                className={classes.textField}
                value={primaryLightColorText}
                onChange={(e) => {
                  setPrimaryLightColorText(`#${e.hex}`);
                  if (isDarkLightValue) setPrimaryDarkColorText(`#${e.hex}`);
                }}
                disablePlainColor
                deferred
                hideTextfield
              />
              <Typography variant="h6">Accent Colour</Typography>
              <ColorPicker
                className={classes.textField}
                value={secondaryLightColorText}
                onChange={(e) => {
                  setSecondaryLightColorText(`#${e.hex}`);
                  if (isDarkLightValue) setSecondaryDarkColorText(`#${e.hex}`);
                }}
                disablePlainColor
                deferred
                hideTextfield
              />
              <Typography variant="h6">Shopname Font Colour</Typography>
              <ColorPicker
                className={classes.textField}
                value={lightFontColorText}
                onChange={(e) => {
                  setLightFontColorText(`#${e.hex}`);
                  if (isDarkLightValue) setDarkFontColorText(`#${e.hex}`);
                }}
                disablePlainColor
                deferred
                hideTextfield
              />
            </Grid>
            {isDarkLightValue ? (
              ''
            ) : (
              <Grid item>
                <Typography variant="h5">Dark Mode</Typography>
                <Typography variant="h6">Primary Colour</Typography>
                <Typography variant="subtitle1">
                  Click on a box to choose the Colour
                </Typography>
                <ColorPicker
                  className={classes.textField}
                  value={primaryDarkColorText}
                  onChange={(e) => {
                    setPrimaryDarkColorText(`#${e.hex}`);
                  }}
                  disablePlainColor
                  deferred
                  hideTextfield
                />
                <Typography variant="h6">Accent Colour</Typography>
                <ColorPicker
                  className={classes.textField}
                  value={secondaryDarkColorText}
                  onChange={(e) => {
                    setSecondaryDarkColorText(`#${e.hex}`);
                  }}
                  disablePlainColor
                  deferred
                  hideTextfield
                />
                <Typography variant="h6">Shopname Font Color</Typography>
                <ColorPicker
                  className={classes.textField}
                  value={darkFontColorText}
                  onChange={(e) => {
                    setDarkFontColorText(`#${e.hex}`);
                  }}
                  disablePlainColor
                  deferred
                  hideTextfield
                />
              </Grid>
            )}
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="DarkSameLightCheckbox"
                name="darkSameLight"
                checked={isDarkLightValue}
                onChange={(e) => {
                  setIsDarkLightValue(!isDarkLightValue);
                  if (e.target.checked) handleSetIsDarkLight();
                }}
              />
            }
            label="Set dark mode colours to the same as light mode colours"
          />
          {/* <Typography variant="h6">Shop Dark mode</Typography>
          <Typography variant="subtitle1">
            Default Dark mode for your shop
          </Typography> */}
        </CardContent>
        <CardActions disableSpacing>
          <Grid container item justify="flex-end">
            <Button
              onClick={() => {
                dispatch(
                  updateThemeByShop({
                    shopId,
                    themeObject: {
                      primaryLightColor: primaryLightColorText,
                      secondaryLightColor: secondaryLightColorText,
                      lightFontColor: lightFontColorText,
                      primaryDarkColor: primaryDarkColorText,
                      secondaryDarkColor: secondaryDarkColorText,
                      darkFontColor: darkFontColorText,
                      isDarkLight: isDarkLightValue,
                    },
                  }),
                );
                setSnackBarMessage('Theme Saved!');
                setSnackBarSeverity('success');
                setSnackbar(true);

                GAevent(
                  'engagement',
                  'settings_update_theme',
                  'Update theme details',
                );
              }}
              variant="contained"
              color="primary"
            >
              Save Theme
            </Button>
          </Grid>
        </CardActions>
      </Card>
      <Card className={classes.card}>
        <CardHeader
          title="Your Branding"
          subheader="Add your branding to your shop"
        />
        <CardContent>
          <Typography variant="h6">Icon Logo</Typography>
          <Button
            className={classes.Button}
            variant="contained"
            component="label"
            data-testid="click-btn-test-icon"
            onClick={() => {
              setimageName('icon');
              getPreSignedURLs();
            }}
          >
            Upload Shop Icon (png)
            <input
              type="file"
              data-testid="IconLogoImgInput"
              accept="image/png"
              onChange={handleImageUpload}
              hidden
            />
          </Button>
          <Grid>
            {previewIconImage && (
              <>
                <img
                  className={classes.imgPreview}
                  alt="Icon logo Preview"
                  src={previewIconImage}
                />
              </>
            )}
          </Grid>
          <Typography variant="subtitle2">Maximum file size of 1 MB</Typography>
          <Typography variant="h6">Appbar Logo</Typography>
          <Button
            className={classes.Button}
            variant="contained"
            component="label"
            data-testid="click-btn-test-appbar"
            onClick={() => {
              setimageName('appbar');
              getPreSignedURLs();
            }}
          >
            Upload Appbar Logo (png)
            <input
              data-testid="AppBarImgInput"
              type="file"
              onChange={handleImageUpload}
              accept=".png"
              hidden
            />
          </Button>
          <Grid>
            {previewAppImage && (
              <>
                <img
                  className={classes.imgPreview}
                  alt="Appbar logo Preview"
                  src={previewAppImage}
                />
              </>
            )}
          </Grid>
          <Typography variant="subtitle2">Maximum file size of 1 MB</Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default SettingsPage;
