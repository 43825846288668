import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Snackbar,
  LinearProgress,
} from '@material-ui/core';
import { Pagination, Alert } from '@material-ui/lab';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { manageBaseUrl } from 'config';
import {
  deleteCustomer,
  fetchCustomers,
  selectAllCustomers,
  selectCustomerFilter,
  updateCustomer,
} from 'pages/management/CustomersPage/customersSlice';
import { useHistory } from 'react-router';
import ReactGA from 'react-ga4';
import axios from '../../client/client';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  header: {
    padding: theme.spacing(1, 2, 0),
  },
  content: {
    paddingBottom: '0px !important',
    padding: theme.spacing(0, 2, 0),
  },
  cardActions: {
    padding: theme.spacing(0),
  },
  actionButton: {
    margin: theme.spacing(0, 1),
  },
  text: {
    padding: theme.spacing(0, 1, 0, 0),
    '& .MuiInputBase-root.Mui-disabled, .MuiInputBase-root.Mui-disabled:before, .MuiFormLabel-root': {
      color: theme.palette.text.primary,
      borderBottomStyle: 'hidden',
    },
  },
  add: {
    margin: theme.spacing(0, 1),
    color: 'white',
  },
  warning: {
    color: 'red',
  },
}));

const CustomerList = ({ shopId }) => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(1);
  const [rowsPerPage] = React.useState(3);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [deleteAction, setDeleteAction] = React.useState(false);
  const [revoke, setRevoke] = React.useState(false);
  const [menuCustomer, setMenuCustomer] = React.useState(null);
  const [editCustomer, setEditCustomer] = React.useState({
    id: -1,
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
  });
  const dispatch = useDispatch();
  const filter = useSelector(selectCustomerFilter);
  const customersStatus = useSelector((state) => state.customers.status);
  const customers = useSelector(selectAllCustomers)
    .filter((customer) =>
      filter.query
        ? customer.companyName
            .toUpperCase()
            .includes(filter.query.toUpperCase())
        : true,
    )
    .sort((a, b) => {
      switch (filter.sortParam) {
        case 0:
          if (a.companyName < b.companyName) return -1;
          if (a.companyName > b.companyName) return 1;
          return 0;
        case 1:
          if (a.companyName < b.companyName) return 1;
          if (a.companyName > b.companyName) return -1;
          return 0;
        case 2:
          if (a.lastName < b.lastName) return -1;
          if (a.lastName > b.lastName) return 1;
          return 0;
        case 3:
          if (a.lastName < b.lastName) return 1;
          if (a.lastName > b.lastName) return -1;
          return 0;
        default:
          return 0;
      }
    });

  useEffect(() => {
    if (!shopId) return;
    if (customersStatus === 'idle') {
      dispatch(fetchCustomers(shopId));
    }
  }, [customersStatus, shopId, dispatch]);

  const pages = Math.ceil(customers.length / rowsPerPage);

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const handleClick = (e, customer) => {
    if (e.currentTarget.value === 'Modify') {
      setEditCustomer({
        ...editCustomer,
        id: customer.id,
        email: customer.email,
        firstName: customer.firstName,
        lastName: customer.lastName,
        companyName: customer.companyName,
      });

      GAevent('engagement', 'customers_modify_customer', 'Modify customer');
    } else if (e.currentTarget.value === 'Cancel') {
      setEditCustomer({ ...editCustomer, id: -1 });
    } else {
      dispatch(updateCustomer(editCustomer));
      setEditCustomer({ ...editCustomer, id: -1 });
    }
  };

  const goToCustomerOrders = (lastName, firstName) => {
    localStorage.setItem('customerName', `${firstName} ${lastName}`);
    history.push(`/shop/${shopId}/orders`);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEditCustomer({ ...editCustomer, email: value });
  };

  const handleFirstNameChange = (e) => {
    const { value } = e.target;
    setEditCustomer({ ...editCustomer, firstName: value });
  };

  const handleLastNameChange = (e) => {
    const { value } = e.target;
    setEditCustomer({ ...editCustomer, lastName: value });
  };

  const handleCompanyNameChange = (e) => {
    const { value } = e.target;
    setEditCustomer({ ...editCustomer, companyName: value });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleMenuOpen = (event, customer) => {
    setAnchorEl(event.currentTarget);
    setMenuCustomer(customer);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuCustomer('');
  };

  const handleDeleteCustomer = () => {
    dispatch(deleteCustomer({ id: menuCustomer.id, revoke }));
    handleMenuClose();
    setDialog(false);
  };

  const handleRequestReset = async () => {
    handleMenuClose();
    setDialog(false);
    try {
      await axios.post(`${manageBaseUrl}/customers/request-reset`, {
        id: menuCustomer.id,
      });
      setSnackbar(true);
    } catch (e) {
      setSnackbar(false);
    }
  };

  const handleCancelDialog = () => {
    setDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbar(false);
  };

  const SnackBarAlert = () => (
    <Snackbar
      open={snackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity="success">
        Successfully sent email with password reset token to customer!
      </Alert>
    </Snackbar>
  );

  const CustomDialog = () => (
    <Dialog open={dialog} onClose={handleCancelDialog}>
      {deleteAction ? (
        <>
          <DialogTitle>Confirm Delete?</DialogTitle>
          <DialogContent>
            Are you sure you want to delete{' '}
            <strong>
              {menuCustomer.firstName} {menuCustomer.lastName}
            </strong>{' '}
            from <strong>{menuCustomer.companyName}</strong>?
            <Typography className={classes.warning}>
              Warning: Clicking Delete will permanantly delete this customer and
              is not undoable. Any access they may have to JamLabs AWS Resources
              may be revoked.
            </Typography>
          </DialogContent>
          <DialogActions>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    name="s3Share"
                    className={classes.checkbox}
                    checked={revoke}
                    onChange={(e) => setRevoke(e.target.checked)}
                  />
                }
                label="Revoke Access to S3?"
              />
            </FormGroup>
            <Button variant="contained" onClick={handleCancelDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteCustomer}
            >
              Delete
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Confirm Send?</DialogTitle>
          <DialogContent>
            Are you sure you want to send an email with a password reset token
            to{' '}
            <strong>
              {menuCustomer.firstName} {menuCustomer.lastName}
            </strong>{' '}
            from <strong>{menuCustomer.companyName}</strong> at{' '}
            <strong>{menuCustomer.email}</strong>?
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleCancelDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRequestReset}
            >
              Send
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );

  return customersStatus === 'loading' ? (
    <LinearProgress />
  ) : (
    <>
      <SnackBarAlert />
      {menuCustomer && <CustomDialog />}
      <div data-testid="customerListWrapper">
        {customers
          .slice(
            (page - 1) * rowsPerPage,
            (page - 1) * rowsPerPage + rowsPerPage,
          )
          .map((customer) => (
            <Card
              key={customer.id}
              className={classes.card}
              data-testid={customer.id}
            >
              <CardHeader
                className={classes.header}
                action={
                  <IconButton
                    aria-label="Learn More"
                    onClick={(e) => {
                      handleMenuOpen(e, customer);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
                title={customer.companyName}
              />
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!anchorEl}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setDialog(true);
                    setDeleteAction(true);
                  }}
                >
                  Delete Customer
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDialog(true);
                    setDeleteAction(false);
                  }}
                >
                  Send Reset Password Email
                </MenuItem>
              </Menu>
              <CardContent className={classes.content}>
                <Grid container>
                  <Grid item xs={3}>
                    <TextField
                      className={classes.text}
                      fullWidth
                      disabled={editCustomer.id !== customer.id}
                      label="Email"
                      value={
                        editCustomer.id !== customer.id
                          ? customer.email
                          : editCustomer.email
                      }
                      onChange={handleEmailChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      className={classes.text}
                      disabled={editCustomer.id !== customer.id}
                      label="First Name"
                      value={
                        editCustomer.id !== customer.id
                          ? customer.firstName
                          : editCustomer.firstName
                      }
                      onChange={handleFirstNameChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      className={classes.text}
                      disabled={editCustomer.id !== customer.id}
                      label="Last Name"
                      value={
                        editCustomer.id !== customer.id
                          ? customer.lastName
                          : editCustomer.lastName
                      }
                      onChange={handleLastNameChange}
                    />
                  </Grid>
                  {editCustomer.id !== customer.id ? null : (
                    <Grid item xs={2}>
                      <TextField
                        className={classes.text}
                        label="Company Name"
                        value={editCustomer.companyName}
                        onChange={handleCompanyNameChange}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Grid container justify="flex-end">
                  {editCustomer.id === customer.id ? (
                    <Button
                      className={classes.actionButton}
                      onClick={(e) => handleClick(e, null)}
                      value="Cancel"
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      className={classes.actionButton}
                      onClick={() =>
                        goToCustomerOrders(
                          customer.lastName,
                          customer.firstName,
                        )
                      }
                    >
                      View Orders
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.add}
                    onClick={(e) => handleClick(e, customer)}
                    data-testid="customerListBtn"
                    value={editCustomer.id === customer.id ? 'Save' : 'Modify'}
                  >
                    {editCustomer.id === customer.id ? 'Save' : 'Modify'}
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          ))}
      </div>
      <Pagination
        count={pages}
        page={page}
        onChange={handlePageChange}
        showFirstButton
        showLastButton
      />
    </>
  );
};

export default injectIntl(CustomerList);
