/* eslint-disable default-case, no-param-reassign */

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { manageBaseUrl } from 'config';
import axios from '../../../client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const attributesAdapter = createEntityAdapter({
  selectId: (attribute) => attribute.id,
  sortComparer: null,
});

const initialState = attributesAdapter.getInitialState({
  status: 'idle',
  error: null,
});

export const createAttribute = createAsyncThunk(
  'attributes/createAttribute',
  async (attribute) => {
    attribute.id = uuidv4().toUpperCase();
    const resp = await axios.post(`${manageBaseUrl}/attributes`, {
      ...attribute,
    });
    return resp.data;
  },
);

export const fetchAttributes = createAsyncThunk(
  'attributes/fetchAttributes',
  async ({ shopId }) => {
    const resp = await axios.get(`${manageBaseUrl}/attributes`, {
      params: { shopId },
    });
    return resp.data;
  },
);

export const updateAttribute = createAsyncThunk(
  'attributes/updateAttribute',
  async (attribute) => {
    await axios.put(`${manageBaseUrl}/attributes`, {
      ...attribute,
    });
    return attribute;
  },
);

export const deleteAttribute = createAsyncThunk(
  'attributes/deleteAttribute',
  async (id) => {
    await axios.delete(`${manageBaseUrl}/attributes`, {
      params: {
        id,
      },
    });
    return id;
  },
);

const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {},
  extraReducers: {
    [createAttribute.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAttributes.pending]: (state) => {
      state.status = 'loading';
    },
    [updateAttribute.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteAttribute.pending]: (state) => {
      state.status = 'loading';
    },
    [createAttribute.rejected]: (state, action) => errorResponse(state, action),
    [fetchAttributes.rejected]: (state, action) => errorResponse(state, action),
    [updateAttribute.rejected]: (state, action) => errorResponse(state, action),
    [deleteAttribute.rejected]: (state, action) => errorResponse(state, action),
    [createAttribute.fulfilled]: (state, action) => {
      const attribute = action.payload;
      attributesAdapter.upsertOne(state, attribute);
      state.status = 'succeeded';
    },
    [fetchAttributes.fulfilled]: (state, action) => {
      if (action.payload) {
        const attribute = action.payload;
        state.status = 'succeeded';
        attributesAdapter.setAll(state, attribute);
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving attributes: received null value';
      }
    },
    [updateAttribute.fulfilled]: (state, action) => {
      const attribute = action.payload;
      attributesAdapter.updateOne(state, {
        id: attribute.id,
        changes: attribute,
      });
      state.status = 'succeeded';
    },
    [deleteAttribute.fulfilled]: (state, action) => {
      const id = action.payload;
      attributesAdapter.removeOne(state, id);
      state.status = 'succeeded';
    },
  },
});

export const {
  selectAll: selectAllAttributes,
  selectById: selectAttributeById,
  selectIds: selectAttributeIds,
} = attributesAdapter.getSelectors((state) => state.attributes);

export default attributesSlice.reducer;
