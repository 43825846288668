/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

import { manageBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const subscriptionOrdersAdapter = createEntityAdapter({
  selectId: (subscriptionOrder) => subscriptionOrder.id,
  sortComparer: null,
});

export const fetchSubscriptionOrdersByShop = createAsyncThunk(
  'orders/fetchSubscriptionOrdersByShop',
  async (shopId) => {
    const resp = await axios.get(`${manageBaseUrl}/orders/get-subscriptions`, {
      params: { shopId },
    });
    return { subscriptionOrders: resp.data };
  },
);

const initialState = subscriptionOrdersAdapter.getInitialState({
  status: 'idle',
  error: null,
});

const subscriptionOrdersSlice = createSlice({
  name: 'subscriptionOrders',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSubscriptionOrdersByShop.pending]: (state) => {
      state.status = 'fetchLoading';
    },
    [fetchSubscriptionOrdersByShop.rejected]: (state, action) =>
      errorResponse(state, action),
    [fetchSubscriptionOrdersByShop.fulfilled]: (state, action) => {
      if (action.payload) {
        const { subscriptionOrders } = action.payload;
        subscriptionOrdersAdapter.setAll(state, subscriptionOrders);
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving subscription Orders';
      }
    },
  },
});

export const {
  selectAll: selectAllSubscriptionOrders,
  selectById: selectSubscriptionOrdersById,
  selectIds: selectSubscriptionOrdersIds,
} = subscriptionOrdersAdapter.getSelectors((state) => state.subscriptionOrders);

export default subscriptionOrdersSlice.reducer;
