/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'client/client';
import { manageBaseUrl } from 'config';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const initialState = {
  status: 'idle',
  id: '',
  creationDate: 0,
  dueDate: 0,
  customer: null,
  seller: null,
  paidStatus: '',
  payment: null,
  currency: '',
  invoiceItems: [],
  error: '',
};

export const markAsPaid = createAsyncThunk(
  'invoice/markAsPaid',
  async (orderId) => {
    const response = axios.post(`${manageBaseUrl}/invoices/mark-paid`, {
      orderId,
    });
    return response;
  },
);

export const fetchInvoice = createAsyncThunk(
  'invoice/fetchInvoice',
  async (orderId) => {
    const { data } = await axios.get(`${manageBaseUrl}/invoices`, {
      params: { orderId },
    });
    const { customer, order, orderItems, shop } = data;
    return {
      id: order.orderId,
      creationDate: order.approvedAt,
      dueDate: order.dueDate || 0,
      total: order.total,
      customer: {
        firstName: null,
        lastName: null,
        companyName: customer.companyName,
        email: customer.email,
        billingAddress: customer.billingAddress,
        // TODO: remove placeholder
        // || {
        //   streetAddress: '123 Placeholder Street',
        //   city: 'Toronto',
        //   province: 'ON',
        //   postalCode: 'A1A1A1',
        // },
      },
      seller: {
        name: shop.name,
        billingAddress: shop.billingAddress,
      },
      paidStatus: order.paidStatus,
      // TODO: remove placeholder
      payment: order.paymentInfo || {
        method: 'VISA',
        info: {
          cardNumber: 'XXXX-XXXX-XXXX-1234',
        },
      },
      currency: orderItems[0]?.currency || 'CAD', // may want to change this to be currency of order (which currently does not exist as an attribute)
      invoiceItems: orderItems.map((curItem) => ({
        name: curItem.productName,
        price: curItem.price,
        license: {
          name: curItem.licenseName,
          delivery: curItem.delivery,
        },
      })),
    };
  },
);

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchInvoice.pending]: (state) => {
      state.status = 'loading';
    },
    [markAsPaid.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchInvoice.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [markAsPaid.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [fetchInvoice.fulfilled]: (state, action) => {
      const data = action.payload;
      if (data) {
        return { ...state, status: 'succeeded', ...data };
      }
      return {
        ...state,
        status: 'failed',
        error: 'Invoice with id not found',
      };
    },
    [markAsPaid.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
        state.paidStatus = 'paid';
      } else {
        state.status = 'failed';
        state.error = 'Unable to mark invoice as paid';
      }
    },
  },
});

export const selectInvoice = (state) => state.invoice;

export default invoiceSlice.reducer;
