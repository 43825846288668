/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { manageBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const initialState = {
  status: 'idle',
  error: null,
};

// was initially a different process created under the assumption that you could connect a
// previously and externally created Stripe account, which turns out you cannot

export const createConnectedAccount = createAsyncThunk(
  'payments/create',
  async (key) => {
    const resp = await axios.post(`${manageBaseUrl}/payments/create`, {
      key,
    });
    if (resp.status !== 200) {
      return null;
    }
    return resp.data;
  },
);

export const linkConnectedAccount = createAsyncThunk(
  'payments/link',
  async ({ key, returnURL, shopId }) => {
    const resp = await axios.post(`${manageBaseUrl}/payments/link`, {
      key,
      returnURL,
      shopId,
    });
    if (resp.status !== 200) {
      return null;
    }
    if (resp.data.url) window.open(resp.data.url, '_blank').focus();
    return resp.data;
  },
);

export const deleteConnectedAccount = createAsyncThunk(
  'payments/delete',
  async ({ key, accountId }) => {
    const resp = await axios.post(`${manageBaseUrl}/payments/delete`, {
      key,
      accountId,
    });
    if (resp.status !== 200) {
      return null;
    }
    return resp.data;
  },
);

export const fetchConnectedStripeId = createAsyncThunk(
  'payments/fetchConnectedStripeId',
  async (shopId) => {
    const resp = await axios.get(`${manageBaseUrl}/payments/connectedId`, {
      params: { shopId },
    });
    return resp.data;
  },
);

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: {
    [createConnectedAccount.pending]: (state) => {
      state.status = 'loading';
    },
    [linkConnectedAccount.pending]: (state) => {
      state.status = 'loading';
    },
    [deleteConnectedAccount.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchConnectedStripeId.pending]: (state) => {
      state.status = 'loading';
    },
    [createConnectedAccount.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [linkConnectedAccount.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [deleteConnectedAccount.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [fetchConnectedStripeId.rejected]: (state, action) => {
      errorResponse(state, action);
    },
    [createConnectedAccount.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error creating connected account: received null value';
      }
    },
    [linkConnectedAccount.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error linking connected account: received null value';
      }
    },
    [deleteConnectedAccount.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error deleting connected account: received null value';
      }
    },
    [fetchConnectedStripeId.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error fetching connected stripe id: received null value';
      }
    },
  },
});

export default paymentsSlice.reducer;
