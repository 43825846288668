import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  entities: [
    {
      key: '995E19C5-221F-4FF6-9CBE-CDA409408101',
      label: 'JamLabs Data Lake',
      views: [],
    },
    {
      key: '79EBBDA9-AACB-48C3-A9C1-AD5680E02D8E',
      label: 'Snowflake Account 4012205 - db: data_source - table: footsteps',
      views: [],
    },
    {
      key: 'DD0E402F-0BA9-4778-9142-5201AE2D584',
      label: 'AWS Bucket i-12481afc',
      views: [],
    },
  ],
  views: [
    {
      key: '0',
      label: 'FX Spot Volume-Daily-Daily',
    },
    {
      key: '1',
      label: 'FX Spot Volume-Hourly-Hourly',
    },
  ],
};

const dataSourcesSlice = createSlice({
  name: 'dataSources',
  initialState,
  reducers: {
    addEntity() {},
  },
});

export const { addEntity } = dataSourcesSlice.actions;

export const selectDataSources = (state) => state.dataSources.entities;
export const selectViews = (state) => state.dataSources.views;

export default dataSourcesSlice.reducer;
