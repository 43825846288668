/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { manageBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const orderItemsAdapter = createEntityAdapter({
  selectId: (orderItem) => orderItem.id,
  sortComparer: null,
});

export const fetchOrderItems = createAsyncThunk(
  'orderItems/fetchOrderItems',
  async (customerId) => {
    const resp = await axios.get(`${manageBaseUrl}/order-items/customer`, {
      params: { customerId },
    });
    return { orderItems: resp.data };
  },
);

const initialState = orderItemsAdapter.getInitialState({
  status: 'idle',
  error: null,
});

const orderItemsSlice = createSlice({
  name: 'orderItems',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOrderItems.pending]: (state) => {
      state.status = 'fetchLoading';
    },
    [fetchOrderItems.rejected]: (state, action) => errorResponse(state, action),
    [fetchOrderItems.fulfilled]: (state, action) => {
      if (action.payload) {
        const { orderItems } = action.payload;
        orderItemsAdapter.setAll(state, orderItems);
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving orderItems: received null value';
      }
    },
  },
});

export const {
  selectAll: selectAllOrderItems,
  selectById: selectOrderItemById,
  selectIds: selectOrderItemIds,
} = orderItemsAdapter.getSelectors((state) => state.orderItems);

export default orderItemsSlice.reducer;
