import { defineMessages } from 'react-intl';

export const scope = 'app';

export default defineMessages({
  LABEL_PRICING: {
    id: `${scope}.LABEL_PRICING`,
    defaultMessage: 'Pricing',
  },
  LABEL_FILE_DELIVERIES: {
    id: `${scope}.LABEL_FILE_DELIVERIES`,
    defaultMessage: 'File Deliveries',
  },
  CURRENCY_none: {
    id: `${scope}.CURRENCY_none`,
    defaultMessage: 'None',
  },
  CURRENCY_USD: {
    id: `${scope}.CURRENCY_USD`,
    defaultMessage: 'USD',
  },
  CURRENCY_CAD: {
    id: `${scope}.CURRENCY_CAD`,
    defaultMessage: 'CAD',
  },
  CURRENCY_GBP: {
    id: `${scope}.CURRENCY_GBP`,
    defaultMessage: 'GBP',
  },
  CURRENCY_EUR: {
    id: `${scope}.CURRENCY_EUR`,
    defaultMessage: 'EUR',
  },
  CURRENCY_HKD: {
    id: `${scope}.CURRENCY_HKD`,
    defaultMessage: 'HKD',
  },
  CURRENCY_JPY: {
    id: `${scope}.CURRENCY_JPY`,
    defaultMessage: 'JPY',
  },
  CURRENCY_AUD: {
    id: `${scope}.CURRENCY_AUD`,
    defaultMessage: 'AUD',
  },
  BILLING_FREQUENCY_once: {
    id: `${scope}.BILLING_FREQUENCY_once`,
    defaultMessage: 'Once',
  },
  BILLING_FREQUENCY_monthly: {
    id: `${scope}.BILLING_FREQUENCY_monthly`,
    defaultMessage: 'Per Month',
  },
  BILLING_FREQUENCY_monthlyData: {
    id: `${scope}.BILLING_FREQUENCY_monthlyData`,
    defaultMessage: 'Per Month of Data',
  },
  DATA_TYPE_str: {
    id: `${scope}.DATA_TYPE_str`,
    defaultMessage: 'String',
  },
  DATA_TYPE_timestamp: {
    id: `${scope}.DATA_TYPE_timestamp`,
    defaultMessage: 'Timestamp',
  },
  DATA_TYPE_int64: {
    id: `${scope}.DATA_TYPE_int64`,
    defaultMessage: 'Integer',
  },
  DATA_TYPE_float64: {
    id: `${scope}.DATA_TYPE_float64`,
    defaultMessage: 'Float',
  },
});
