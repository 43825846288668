import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  TextField,
  Divider,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { createCustomer } from 'pages/management/CustomersPage/customersSlice';
const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(2, 0, 0),
  },
  card: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
  },
  header: {
    padding: theme.spacing(1, 2, 0),
  },
  content: {
    paddingBottom: '0px !important',
    padding: theme.spacing(0, 2, 0),
  },
  cardActions: {
    padding: theme.spacing(0),
  },
  alert: {
    margin: theme.spacing(0, 0, 1),
  },
  text: {
    padding: theme.spacing(0, 1, 0, 0),
  },
  actionButton: {
    margin: theme.spacing(0, 1),
  },
  save: {
    margin: theme.spacing(0, 1),
    color: 'white',
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

const clearDraft = {
  id: '',
  shopId: '',
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
};

const CustomerDraft = ({ shopId, onCloseDraft }) => {
  const [draft, setDraft] = React.useState({ ...clearDraft, shopId });
  const classes = useStyles();
  const dispatch = useDispatch();
  const [draftWarning, setDraftWarning] = React.useState(false);

  const handleChangeDraft = (arg) => {
    setDraft({ ...draft, ...arg });
  };

  const onSaveCustomer = () => {
    if (
      draft.companyName === '' ||
      draft.email === '' ||
      draft.firstName === '' ||
      draft.lastName === ''
    ) {
      setDraftWarning(true);
    } else {
      setDraftWarning(false);
      dispatch(createCustomer(draft));
      setDraft({ ...clearDraft, shopId });
      onCloseDraft();
    }
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader className={classes.header} title="Add Customer" />
        {draftWarning ? (
          <Alert severity="error">All parameters are required</Alert>
        ) : null}
        <CardContent className={classes.content}>
          <Grid container>
            <Grid item xs={3}>
              <TextField
                className={classes.text}
                fullWidth
                label="Email"
                value={draft.email}
                required
                inputProps={{ 'data-testid': 'EmailField' }}
                onChange={(e) => handleChangeDraft({ email: e.target.value })}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                className={classes.text}
                label="First Name"
                value={draft.firstName}
                required
                inputProps={{ 'data-testid': 'FirstNameField' }}
                onChange={(e) =>
                  handleChangeDraft({ firstName: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                className={classes.text}
                label="Last Name"
                value={draft.lastName}
                required
                inputProps={{ 'data-testid': 'LastNameField' }}
                onChange={(e) =>
                  handleChangeDraft({ lastName: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                className={classes.text}
                label="Company Name"
                value={draft.companyName}
                required
                inputProps={{ 'data-testid': 'CompanyField' }}
                onChange={(e) =>
                  handleChangeDraft({ companyName: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Grid container justify="flex-end">
            <Button
              className={classes.actionButton}
              onClick={() => setDraft({ ...clearDraft, shopId })}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.save}
              onClick={onSaveCustomer}
            >
              Save
            </Button>
          </Grid>
        </CardActions>
      </Card>
      <Divider className={classes.divider} />
    </>
  );
};

export default CustomerDraft;
