/* eslint-disable default-case, no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { manageBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const initialState = {
  status: 'idle',
  signedIn: false,
  shopId: '',
  BAStatus: 'idle',
  awsIdstatus: 'idle',
};

export const fetchUser = createAsyncThunk('manage/fetchUser', async () => {
  const resp = await axios.get(`${manageBaseUrl}/users`);
  return resp.data;
});

export const updateUser = createAsyncThunk(
  'manage/updateUser',
  async (user) => {
    await axios.post(`${manageBaseUrl}/users`, {
      user,
    });
    return user;
  },
);

export const fetchShop = createAsyncThunk(
  'manage/fetchShop',
  async (shopId) => {
    const resp = await axios.get(`${manageBaseUrl}/shops/shop-info`, {
      params: { shopId },
    });
    return resp.data;
  },
);

export const fetchShopBA = createAsyncThunk(
  'manage/fetchShopBA',
  async (shopId) => {
    const response = await axios.get(`${manageBaseUrl}/shops/billing-address`, {
      params: { shopId },
    });
    return response.data;
  },
);

export const updateShopBA = createAsyncThunk(
  'manage/updateShopBA',
  async ({ shopId, billingAddress }) => {
    const response = await axios.post(
      `${manageBaseUrl}/shops/billing-address`,
      {
        shopId,
        billingAddress,
      },
    );
    return response.data;
  },
);

export const updateShopById = createAsyncThunk(
  'manage/updateNameByShop',
  async ({ shopId, UpdatedShopName, UpdatedShopCurrency }) => {
    const resp = await axios.post(`${manageBaseUrl}/shops/update`, {
      shopId,
      shopName: UpdatedShopName,
      shopCurrency: UpdatedShopCurrency,
    });
    if (resp.status !== 200) {
      return null;
    }
    return resp.data;
  },
);

export const saveAwsId = createAsyncThunk(
  'users/aws-userId',
  async (params) => {
    const resp = await axios.post(`${manageBaseUrl}/users/aws-userId`, params);
    return resp.data;
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signInUser(state, action) {
      state.status = 'succeeded';
      state.signedIn = true;
      state.user = action.payload;
    },
    signOutUser(state) {
      state.status = 'succeeded';
      state.signedIn = false;
      state.user = undefined;
      state.shopId = '';
    },
    setSelectedShopId(state, action) {
      state.status = 'succeeded';
      state.shopId = action.payload;
    },
    changeAwsIdStatus(state) {
      state.awsIdstatus = 'idle';
    },
  },
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.status = 'loading';
    },
    [updateUser.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchShopBA.pending]: (state) => {
      state.BAStatus = 'loading';
    },
    [updateShopBA.pending]: (state) => {
      state.BAStatus = 'loading';
    },
    [updateShopById.pending]: (state) => {
      state.status = 'loading';
    },
    [saveAwsId.pending]: (state) => {
      state.awsIdstatus = 'pending';
    },
    [fetchUser.rejected]: (state, action) => errorResponse(state, action),
    [updateUser.rejected]: (state, action) => errorResponse(state, action),
    [fetchShopBA.rejected]: (state, action) => {
      state.BAStatus = 'failed';
      state.error = action.error.mesasge;
    },
    [updateShopById.rejected]: (state, action) => errorResponse(state, action),
    [updateShopBA.rejected]: (state, action) => {
      state.BAStatus = 'failed';
      state.error = action.error.mesasge;
    },
    [saveAwsId.rejected]: (state, action) => {
      state.awsIdstatus = 'failed';
      state.error = action.error.message;
    },
    [fetchUser.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
        state.signedIn = true;
        state.user = action.payload;
      } else {
        state.status = 'failed';
        state.signedIn = false;
        state.error = 'Error retrieving user: received null value';
      }
    },
    [updateShopById.fulfilled]: (state, action) => {
      if (action.payload) {
        const { shopName } = action.payload;
        state.shopName = shopName;
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error =
          'Error updating shop name in Settings: received null value';
      }
    },
    [updateUser.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
        state.signedIn = true;
        state.user = action.payload;
      } else {
        state.status = 'failed';
        state.error = 'Error updating user: received null value';
      }
    },
    [fetchShopBA.fulfilled]: (state, action) => {
      state.BAStatus = 'succeeded';
      state.billingAddress = action.payload;
    },
    [updateShopBA.fulfilled]: (state, action) => {
      if (action.payload) {
        state.BAStatus = 'succeeded';
        state.billingAddress = action.payload;
      } else {
        state.BAStatus = 'failed';
        state.error = 'Error updating billing address';
      }
    },

    [fetchShop.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchShop.rejected]: (state, action) => errorResponse(state, action),
    [fetchShop.fulfilled]: (state, action) => {
      if (action.payload) {
        state.status = 'succeeded';
        state.shopInfo = action.payload;
      } else {
        state.status = 'failed';
        state.error =
          'Error retrieving shop in management: received null value';
      }
    },
    [saveAwsId.fulfilled]: (state, action) => {
      if (action.payload) {
        state.user.awsUserId = action.payload;
        state.awsIdstatus = 'succeeded';
      } else {
        state.error = 'Error saving AWS ID';
        state.awsIdstatus = 'failed';
      }
    },
  },
});
export const selectUser = (state) => state.user.user;

export const selectShopId = (state) => state.user.shopId;

export const {
  signOutUser,
  signInUser,
  setSelectedShopId,
  changeAwsIdStatus,
} = userSlice.actions;

export default userSlice.reducer;
