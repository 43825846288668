export const manageBaseUrl =
  process.env.REACT_APP_MANAGE_BASE_URL || 'http://localhost:3001/manage';
// export const manageHosts =
//   process.env.REACT_APP_MANAGE_HOSTS || 'localhost:3000';
// export const jamlabsUAID = process.env.REACT_APP_JAMLABS_UAID;
export const jamlabsGID = process.env.REACT_APP_JAMLABS_MEASUREMENT_ID;
export const stripeKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
export const SAMPLE_DATA_MAX_SIZE = 10000000;
export const SAMPLE_DATA_DISPLAY_COUNT = 99;
export const shopCurrencyList = [
  'usd',
  'cad',
  'gbp',
  'eur',
  'aud',
  'jpy',
  'hkd',
];
