/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';

// import { v4 as uuidv4 } from 'uuid';
import { manageBaseUrl } from 'config';
import axios from 'client/client';

const errorResponse = (state, action) => {
  state.status = 'failed';
  state.error = action.error.message;
};

const ordersAdapter = createEntityAdapter({
  selectId: (order) => order.id,
  sortComparer: null,
});

export const updateApproval = createAsyncThunk(
  'orders/updateApproval',
  async ({ newStatus, orderId, reason }) => {
    await axios.post(`${manageBaseUrl}/orders/update-approval`, {
      status: newStatus,
      id: orderId,
      reason,
    });

    return { newStatus, orderId, reason };
  },
);

export const fetchOrdersByShop = createAsyncThunk(
  'orders/fetchOrdersByShop',
  async (shopId) => {
    const resp = await axios.get(`${manageBaseUrl}/orders`, {
      params: { shopId },
    });
    return { orders: resp.data };
  },
);

export const releaseApprovedData = createAsyncThunk(
  'orders/releaseApprovedData',
  async ({ newStatus, orderId, reason }) => {
    await axios.post(`${manageBaseUrl}/orders/release-approved-data`, {
      status: newStatus,
      id: orderId,
      reason,
    });
    return { newStatus, orderId, reason };
  },
);

const toDate = new Date();
const fromDate = new Date();
fromDate.setFullYear(toDate.getFullYear() - 1);

const initialState = ordersAdapter.getInitialState({
  status: 'idle',
  filter: {
    filterApplied: false,
    from: fromDate.toISOString().split('T')[0],
    to: toDate.toISOString().split('T')[0],
    query: '',
    customerName: '',
  },
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    filterEdited(state, action) {
      const prop = Object.keys(action.payload)[0];
      const value = action.payload[prop];
      state.filter[prop] = value;
    },
  },
  extraReducers: {
    [fetchOrdersByShop.pending]: (state) => {
      state.status = 'fetchLoading';
    },

    [updateApproval.pending]: (state) => {
      state.status = 'loading';
    },
    [releaseApprovedData.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchOrdersByShop.rejected]: (state, action) =>
      errorResponse(state, action),
    [updateApproval.rejected]: (state, action) => errorResponse(state, action),
    [releaseApprovedData.rejected]: (state, action) =>
      errorResponse(state, action),
    [fetchOrdersByShop.fulfilled]: (state, action) => {
      if (action.payload) {
        const { orders } = action.payload;
        ordersAdapter.setAll(state, orders);
        state.status = 'succeeded';
      } else {
        state.status = 'failed';
        state.error = 'Error retrieving orders';
      }
    },
    [updateApproval.fulfilled]: (state, action) => {
      const { orderId, newStatus, reason } = action.payload;
      ordersAdapter.updateOne(state, {
        id: orderId,
        changes: { status: newStatus, reason },
      });
    },
    [releaseApprovedData.fulfilled]: (state, action) => {
      if (action.payload) {
        const { orderId, newStatus, reason } = action.payload;
        state.status = 'succeeded';
        ordersAdapter.updateOne(state, {
          id: orderId,
          changes: { status: newStatus, reason },
        });
      } else {
        state.status = 'failed';
        state.error = 'Error releasing approved data';
      }
    },
  },
});

export const {
  selectAll: selectAllOrders,
  selectById: selectOrderById,
  selectIds: selectOrderIds,
} = ordersAdapter.getSelectors((state) => state.orders);

export const { filterEdited } = ordersSlice.actions;

export const selectOrderFilter = (state) => state.orders.filter;

export default ordersSlice.reducer;
