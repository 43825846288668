import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Redirect, useParams } from 'react-router-dom';
import {
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  InputAdornment,
  FormGroup,
  Checkbox,
  Button,
  Grid,
  LinearProgress,
  Select,
  MenuItem,
  InputLabel,
  Snackbar,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import {
  fetchDataSets,
  selectAllDataSets,
} from 'pages/management/ProductsPage/dataSetsSlice';
import { updateApproval, selectAllOrders } from 'pages/OrdersPage/ordersSlice';
import CustomToolTip, { toolTipMessages } from 'features/toolTip/customToolTip';
import ReactGA from 'react-ga4';
import { Product } from '../../../features/product/userProduct';
import logo from '../../../assets/images/logo_icon_default.png';

import {
  createProduct,
  fetchProducts,
  updateProduct,
  deleteProduct,
  selectAllProducts,
  filterEdited,
} from './productsSlice';
import {
  fetchAttributes,
  selectAllAttributes,
} from '../AttributesPage/attributesSlice';
import { fetchLicenses } from '../LicensesPage/licensesSlice';
import { selectShopId } from '../SignInPage/shopSignInSlice';
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.primary,
    },
  },
  paper: {
    textAlign: 'center',
  },
  status: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 0),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  form: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    height: theme.spacing(4),
    backgroundImage: `url(${logo})`,
  },
  loading: {
    width: '50%',
    margin: theme.spacing(10, 0, 10),
  },
  text: {
    color: theme.palette.text.primary,
  },
}));

function ProductsPage() {
  const classes = useStyles();

  const dispatch = useDispatch();

  let shopId = useSelector(selectShopId);

  const currency = useSelector((state) => state.licenses.currency);

  const urlShopId = useParams().shopId;
  if (!shopId) shopId = urlShopId;

  const filter = useSelector((state) => state.products.filter);

  const [snackbar, setSnackbar] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [blankProductExists, setBlankProductExists] = React.useState(false);
  const orders = useSelector(selectAllOrders);
  const attributesStatus = useSelector((state) => state.attributes.status);
  const licensesStatus = useSelector((state) => state.licenses.status);
  const dataSetsStatus = useSelector((state) => state.dataSets.status);
  const productsStatus = useSelector((state) => state.products.status);
  const productsError = useSelector((state) => state.products.error);
  const attributes = useSelector(selectAllAttributes);
  const dataSets = useSelector(selectAllDataSets);
  let editRecentlyCreated = useSelector(
    (state) => state.products.editRecentlyCreated,
  );

  const products = useSelector(selectAllProducts)
    .filter((product) => {
      // filter product logic
      const startDate = moment(product.startDate, 'MMMM DD, YYYY');
      const fromDate = moment(filter.from).toDate();
      const toDate = moment(filter.to).toDate();

      const tagData = product.tags.map((tag) => {
        const parentAttribute = attributes.find(
          (attribute) => attribute.id === tag.attribute,
        );
        return parentAttribute
          ? parentAttribute.tags.find((x) => x.key === tag.tag).label
          : '';
      });

      if (product.status === 'Draft') {
        return (
          (product.id === localStorage.getItem('new_product_id')
            ? true
            : startDate >= fromDate && startDate <= toDate) &&
          (filter.status.draft && !filter.status.published
            ? product.status === 'Draft'
            : true) &&
          (filter.status.published && !filter.status.draft
            ? product.status === 'Published'
            : true) &&
          (filter.query.length > 0
            ? product.name.toLowerCase().includes(filter.query.toLowerCase()) ||
              product.description
                .toLowerCase()
                .includes(filter.query.toLowerCase()) ||
              tagData.findIndex((x) =>
                x.toLowerCase().includes(filter.query.toLowerCase()),
              ) !== -1
            : true)
        );
      }
      // product is : 1) within from and to 2) status adheres to filter 3) query product name, description, or tags
      return (
        startDate >= fromDate &&
        startDate <= toDate &&
        (filter.status.draft && !filter.status.published
          ? product.status === 'Draft'
          : true) &&
        (filter.status.published && !filter.status.draft
          ? product.status === 'Published'
          : true) &&
        (filter.query.length > 0
          ? product.name.toLowerCase().includes(filter.query.toLowerCase()) ||
            product.description
              .toLowerCase()
              .includes(filter.query.toLowerCase()) ||
            tagData.findIndex((x) =>
              x.toLowerCase().includes(filter.query.toLowerCase()),
            ) !== -1
          : true)
      );
    })
    .sort((a, b) => {
      switch (filter.sortParam) {
        case 0:
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        case 1:
          if (a.name < b.name) return 1;
          if (a.name > b.name) return -1;
          return 0;
        case 2:
          if (a.createdAt < b.createdAt) return -1;
          if (a.createdAt > b.createdAt) return 1;
          return 0;
        case 3:
          if (a.createdAt < b.createdAt) return 1;
          if (a.createdAt > b.createdAt) return -1;
          return 0;
        default:
          return 0;
      }
    });

  useEffect(() => {
    if (!shopId) return;
    if (productsStatus === 'idle') {
      dispatch(fetchProducts({ shopId }));
    }
    // Populate Attributes and Licenses as a dependency for each product
    if (attributesStatus === 'idle') {
      dispatch(fetchAttributes({ shopId }));
    }
    if (licensesStatus === 'idle') {
      dispatch(fetchLicenses({ shopId }));
    }
    if (dataSetsStatus === 'idle') {
      dispatch(fetchDataSets(shopId));
    }
    if (productsStatus === 'failed') {
      setSnackBarMessage(productsError);
      setSnackbar(true);
    }
  }, [
    shopId,
    productsStatus,
    attributesStatus,
    licensesStatus,
    dataSetsStatus,
    productsError,
    dispatch,
  ]);

  useEffect(
    () => () => {
      window.onbeforeunload = null;
    },
    [],
  );

  if (!shopId) return <Redirect to="/users/sign-in" />;

  const GAevent = (category, action, label) => {
    ReactGA.event({ category, action, label });
  };

  const setFilter = (payload) => {
    dispatch(filterEdited(payload));
  };

  const handleRejection = (newStatus, orderId) => {
    dispatch(
      updateApproval({
        newStatus,
        orderId,
        reason: 'product was deleted',
      }),
    );
  };

  const handleSaveProduct = (newProduct) => {
    if (window.onbeforeunload !== null) window.onbeforeunload = null;
    setBlankProductExists(false);
    dispatch(updateProduct(newProduct));
  };

  const handleDeleteProduct = (productId) => {
    setBlankProductExists(false);
    // deletes product and rejects orders that are pending with product
    dispatch(deleteProduct(productId));
    Object.values(orders).forEach((value) => {
      const { productIds } = value;
      const orderStatus = value.status;
      if (orderStatus === 'pending') {
        Object.values(productIds).forEach((val) => {
          if (val === productId.id) {
            handleRejection('rejected', value.id);
          }
        });
      }
    });
  };

  const handleAddBlankProduct = () => {
    GAevent('engagement', 'products_add_product', 'Add new product');

    if (!blankProductExists) {
      setBlankProductExists(true);
      dispatch(createProduct(shopId));
      setFilter({
        status: {
          ...filter.status,
          draft: true,
        },
      });
      products.sort((a, b) => {
        switch (filter.sortParam) {
          case 0:
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          case 1:
            if (a.name < b.name) return 1;
            if (a.name > b.name) return -1;
            return 0;
          case 2:
            if (a.createdAt < b.createdAt) return -1;
            if (a.createdAt > b.createdAt) return 1;
            return 0;
          case 3:
            if (a.createdAt < b.createdAt) return 1;
            if (a.createdAt > b.createdAt) return -1;
            return 0;
          default:
            return 0;
        }
      });
    } else {
      setSnackBarMessage('A blank product already exists!');
      setSnackbar(true);
    }
  };

  const handleChangeEdit = (productId) => {
    editRecentlyCreated = productId;
  };

  const handleSnackbarClose = () => {
    setSnackbar(false);
  };

  const SnackBarAlert = () => (
    <Snackbar
      open={snackbar}
      autoHideDuration={5000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity="error">
        {snackBarMessage}
      </Alert>
    </Snackbar>
  );

  const ProductsList = () => {
    if (productsStatus === 'loading') {
      return (
        <Grid container direction="column" justify="center" alignItems="center">
          <LinearProgress className={classes.loading} />
        </Grid>
      );
    }
    if (productsStatus === 'succeeded') {
      return (
        <div data-testid="ProductsList">
          {products.map((product) => (
            <Product
              xs={12}
              key={product.id}
              entity={product}
              isDraft={product.status === 'Draft'}
              dataSet={dataSets.find((ds) => ds.productId === product.id)}
              onAddProduct={handleSaveProduct}
              onDeleteProduct={handleDeleteProduct}
              editingAfterCreation={
                editRecentlyCreated != null &&
                editRecentlyCreated === product.id
              }
              onChangeEdit={handleChangeEdit}
              currency={currency}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const SortBySelect = () => (
    <FormControl>
      <InputLabel id="sort-by-select-label">Sort By:</InputLabel>
      <Select
        data-testid="SortBy"
        labelId="sort-by-select-label"
        value={filter.sortParam}
        onChange={(e) => {
          setFilter({ sortParam: e.target.value });

          GAevent(
            'engagement',
            `products_sort_by_${filter.sortParam}`,
            'Change product sort filter',
          );
        }}
      >
        <MenuItem value={0}>Product Name (A-Z)</MenuItem>
        <MenuItem value={1}>Product Name (Z-A)</MenuItem>
        <MenuItem value={2}>Date (Oldest to Newest)</MenuItem>
        <MenuItem value={3}>Date (Newest to Oldest)</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <>
      <SnackBarAlert />
      <Typography className={classes.title} variant="h3">
        Your Products <CustomToolTip text={toolTipMessages.ProductPage} />
      </Typography>
      <Typography className={classes.text} variant="body1">
        Change specifications of your products, upload sample data, specify
        structured schemas, and publish.
      </Typography>
      <form className={classes.form}>
        <TextField
          inputProps={{ 'data-testid': 'FromDate' }}
          id="dateFrom"
          label="From"
          type="date"
          value={filter.from}
          onChange={(e) => setFilter({ from: e.target.value })}
        />
        <TextField
          inputProps={{ 'data-testid': 'ToDate' }}
          id="dateTo"
          label="To"
          type="date"
          value={filter.to}
          onChange={(e) => setFilter({ to: e.target.value })}
        />
        <FormControl className={classes.status} component="fieldset">
          <FormLabel component="legend">Status</FormLabel>
          <FormGroup row>
            <FormControlLabel
              className={classes.root}
              control={
                <Checkbox
                  name="draft"
                  id="checkboxdraft"
                  checked={filter.status.draft}
                  onChange={(e) => {
                    setFilter({
                      status: {
                        ...filter.status,
                        draft: e.target.checked,
                      },
                    });

                    if (e.target.checked)
                      GAevent(
                        'engagement',
                        'products_filter_by_draft',
                        'Filter products by draft',
                      );
                    else
                      GAevent(
                        'engagement',
                        'products_unfilter_by_draft',
                        'Unfilter products by draft',
                      );
                  }}
                />
              }
              label="Draft"
            />
            <FormControlLabel
              className={classes.root}
              control={
                <Checkbox
                  name="published"
                  id="checkboxpublish"
                  checked={filter.status.published}
                  onChange={(e) => {
                    setFilter({
                      status: {
                        ...filter.status,
                        published: e.target.checked,
                      },
                    });

                    if (e.target.checked)
                      GAevent(
                        'engagement',
                        'products_filter_by_published',
                        'Filter products by published',
                      );
                    else
                      GAevent(
                        'engagement',
                        'products_unfilter_by_published',
                        'Unfilter products by published',
                      );
                  }}
                />
              }
              label="Published"
            />
          </FormGroup>
        </FormControl>
        <TextField
          id="search"
          label="Search"
          value={filter.query}
          onChange={(e) => setFilter({ query: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <SortBySelect />
      </form>
      <Grid container justify="space-between">
        <Grid item>
          <Typography className={classes.text} variant="caption">
            {products.length} of {products.length} products.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddBlankProduct}
            data-testid="NewProductBtn"
            defaultValue=""
          >
            New Product
          </Button>
        </Grid>
      </Grid>
      <ProductsList />
    </>
  );
}

export default ProductsPage;
