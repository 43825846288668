import { configureStore } from '@reduxjs/toolkit';
import languageReducer from '../features/language/languageSlice';
import attributesReducer from '../pages/management/AttributesPage/attributesSlice';
import shopSelectReducer from '../pages/management/ShopSelectPage/shopSelectSlice';
import analyticsReducer from '../pages/management/AnalyticsPage/analyticsSlice';
import productsReducer from '../pages/management/ProductsPage/productsSlice';
import dataSetsReducer from '../pages/management/ProductsPage/dataSetsSlice';
import ordersReducer from '../pages/OrdersPage/ordersSlice';
import orderItemsReducer from '../pages/OrdersPage/orderItemsSlice';
import subscriptionOrdersReducer from '../pages/OrdersPage/subscriptionsSlice';
import customersReducer from '../pages/management/CustomersPage/customersSlice';
import licensesReducer from '../pages/management/LicensesPage/licensesSlice';
import dataSourcesReducer from '../pages/management/DataSourcesPage/dataSourcesSlice';
import userReducer from '../pages/management/SignInPage/shopSignInSlice';
import layoutFooterReducer from '../pages/management/LayoutPage/layoutFooterSlice';
import apiKeyReducer from '../pages/management/DeliveryOptionsPage/apiKeysSlice';
import invoicesReducer from '../pages/management/PaymentsPage/paymentsSlice';
import invoiceReducer from '../pages/InvoicePage/invoiceSlice';
import settingsReducer from '../pages/SettingsPage/settingsSlice';
import orderSubscriptionsReducer from '../pages/management/SubscriptionManagementPage/orderSubscriptionsSlice';

export default configureStore({
  reducer: {
    language: languageReducer,
    attributes: attributesReducer,
    shops: shopSelectReducer,
    analytics: analyticsReducer,
    products: productsReducer,
    dataSets: dataSetsReducer,
    orders: ordersReducer,
    customers: customersReducer,
    licenses: licensesReducer,
    dataSources: dataSourcesReducer,
    user: userReducer,
    layoutFooter: layoutFooterReducer,
    apiKey: apiKeyReducer,
    invoices: invoicesReducer,
    invoice: invoiceReducer,
    settings: settingsReducer,
    subscriptionOrders: subscriptionOrdersReducer,
    orderSubscriptions: orderSubscriptionsReducer,
    orderItems: orderItemsReducer,
  },
});
