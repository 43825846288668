import React, { useEffect } from 'react';
import {
  IconButton,
  Button,
  Card,
  Box,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Grid,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import {
  HelpOutline as HelpOutlineIcon,
  FileCopy as CopyIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../../assets/images/logo_icon_primary.png';
// import awsLogo from '../../../assets/images/aws.png';

import axios from '../../../client/client';
import {
  fetchApiKey,
  selectApiKey,
  generateApiKey,
  regenerateApiKey,
  hideKey,
} from './apiKeysSlice';
import { selectUser } from '../SignInPage/shopSignInSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  text: { color: theme.palette.text.primary },
  textField: {
    margin: theme.spacing(1),
    width: '50ch',
    color: theme.palette.text.primary,
  },
  avatar: {
    backgroundImage: `url(${logo})`,
    height: '50px',
    width: '50px',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));

function DeliveryOptionsPage() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector(selectUser);
  const hideKeyAction = hideKey();

  const apiKeyStatus = useSelector((state) => state.apiKey.status);
  const [showApiKey, setShowApiKey] = React.useState(false);
  const apiKey = useSelector(selectApiKey);
  const [showAlert, setShowAlert] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [generated, setGenerated] = React.useState(false);

  // TODO: Update isCustomer when page is moved to shopfront
  useEffect(() => {
    if (apiKeyStatus === 'idle') {
      dispatch(
        fetchApiKey({
          entityId: user.id,
        }),
      );
    }
  }, [apiKeyStatus, dispatch, user]);

  const sampleUrl =
    '/data?productId=1384B0D3-0079-41D9-8597-0DCE814074DA&startDate=2019-01-01&endDate=2019-01-15';

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(axios.defaults.baseURL + sampleUrl).then(
      () => {},
      (err) => {
        throw err;
      },
    );
  };

  const handleCopyKeyID = () => {
    navigator.clipboard.writeText(apiKey.apiKey.id).then(
      () => {
        setShowAlert(true);
      },
      (err) => {
        throw err;
      },
    );
  };

  const handleCopyAPIkey = () => {
    navigator.clipboard.writeText(apiKey.apiKey.key).then(
      () => {
        setShowAlert(true);
      },
      (err) => {
        throw err;
      },
    );
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (showAlert === true) {
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  }, [showAlert]);

  useEffect(() => {
    if (!generated) {
      dispatch(hideKeyAction);
    }
  }, [dispatch, hideKeyAction, generated]);

  const handleGenerateKey = () => {
    dispatch(
      generateApiKey({
        entityId: user.id,
        entityType: 'user',
      }),
    );
    setGenerated(true);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const setApiKeyMessage = () => {
    if (apiKey.assignedKey && apiKey.displayKey) {
      return apiKey.apiKey.key;
    }
    if (apiKey.assignedKey && !apiKey.displayKey) {
      return 'Existing API key found';
    }
    return 'No API Key found';
  };

  let createdDateFormatted = '';
  let lastUsedDateFormatted = '';
  if (apiKey.assignedKey) {
    const createdDate = new Date(apiKey.apiKey.createdAt);
    createdDateFormatted = createdDate.toString().substring(0, 15);

    const lastUsedDate = new Date(apiKey.apiKey.lastUsed);
    lastUsedDateFormatted = lastUsedDate.toString().substring(0, 15);
  }

  const handleRegenerateKey = () => {
    setDialogOpen(false);
    dispatch(
      regenerateApiKey({
        oldId: apiKey.apiKey.id,
        entityId: user.id,
        entityType: 'user',
      }),
    );
    setGenerated(true);
  };

  const JamLabsCard = () => (
    <Card className={classes.root}>
      <CardHeader
        title="Express REST API"
        subheader="Dynamically query the data and get immediate up-to-date responses."
      />
      <CardContent>
        <TextField
          className={classes.textField}
          type="text"
          value={apiKey.assignedKey ? apiKey.apiKey.id : 'No Key ID found'}
          label="Key ID"
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <span>
                  <IconButton
                    onClick={handleCopyKeyID}
                    data-testid="CopyBtn"
                    onMouseDown={handleMouseDown}
                    disabled={!apiKey.assignedKey}
                  >
                    <CopyIcon />
                  </IconButton>
                </span>
              </InputAdornment>
            ),
          }}
        />
        <Grid container alignItems="center" justify="flex-start" spacing={3}>
          <Grid item>
            <TextField
              className={classes.textField}
              type={
                showApiKey ||
                !apiKey.assignedKey ||
                (!apiKey.displayKey && apiKey.assignedKey)
                  ? 'text'
                  : 'password'
              }
              value={setApiKeyMessage()}
              label="API Key"
              disabled={!apiKey.displayKey}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={
                        !apiKey.displayKey && apiKey.assignedKey
                          ? 'Unable to reveal existing API keys'
                          : ''
                      }
                      placement="top-end"
                      arrow
                    >
                      <span>
                        <IconButton
                          onClick={() => setShowApiKey(!showApiKey)}
                          data-testid="RevealBtn"
                          onMouseDown={handleMouseDown}
                          disabled={!apiKey.displayKey}
                        >
                          {showApiKey ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Typography component="div" variant="body1">
              <Box>
                <b>Date created:</b> {createdDateFormatted}
              </Box>
              <Box>
                <b>Date API key last used: </b> {lastUsedDateFormatted}
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            {apiKey.displayKey ? (
              <Typography>
                Warning - Navigating away from this page will permanently hide
                the API key. Ensure it is copied and safely stored before
                leaving this page.
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={11}>
            <Button
              variant="contained"
              color="primary"
              onClick={
                apiKey.assignedKey ? handleDialogOpen : handleGenerateKey
              }
            >
              {apiKey.assignedKey ? 'Revoke & Regenerate Key' : 'Generate Key'}
            </Button>
            <Dialog
              open={dialogOpen}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">API Key Warning</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Regenerating the API key will invalidate the existing API Key.
                  Are you sure you want to regenerate the API key?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleDialogClose}
                  variant="contained"
                  autoFocus
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleRegenerateKey}
                  variant="contained"
                  color="primary"
                >
                  Regenerate
                </Button>
              </DialogActions>
            </Dialog>
            {apiKey.assignedKey ? (
              <Tooltip
                title={
                  apiKey.displayKey ? '' : 'Unable to copy existing API keys'
                }
                placement="top-start"
                arrow
              >
                <span>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="default"
                    disabled={!apiKey.displayKey}
                    onClick={handleCopyAPIkey}
                  >
                    Copy API Key
                  </Button>
                </span>
              </Tooltip>
            ) : null}
            {showAlert ? (
              <Alert severity="success" style={{ marginTop: 20 }}>
                Successfully copied to clipboard!
              </Alert>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Box m="4">
              <TextField
                fullWidth
                disabled
                value={sampleUrl}
                label="Sample Query String"
                InputProps={{
                  endAdornment: (
                    <IconButton position="end" onClick={handleCopyToClipboard}>
                      <CopyIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  // const AwsCard = () => (
  //   <Card className={classes.root}>
  //     <CardHeader
  //       avatar={<img className={classes.avatar} src={awsLogo} alt={awsLogo} />}
  //       action={
  //         <IconButton aria-label="settings">
  //           <MoreVertIcon />
  //         </IconButton>
  //       }
  //       title="AWS S3 Data Lake"
  //       subheader="Have the data pushed to your AWS account via cloud-to-cloud transfer."
  //     />
  //     <CardContent>
  //       <TextField
  //         className={classes.textField}
  //         type="password"
  //         value="asdsadsdasadsda"
  //         label="AWS Key "
  //       />
  //       <TextField
  //         className={classes.textField}
  //         type="password"
  //         value="asdsadsdasadsda"
  //         label="AWS Secret"
  //       />
  //       <TextField
  //         className={classes.textField}
  //         type="password"
  //         value="asdsadsdasadsda"
  //         label="Root Bucket URI"
  //       />
  //     </CardContent>
  //     <CardActions disableSpacing>
  //       <Grid container item justify="flex-end">
  //         <Button color="default">View Content</Button>
  //         <Button variant="contained" color="default">
  //           Save
  //         </Button>
  //       </Grid>
  //     </CardActions>
  //   </Card>
  // );

  return (
    <>
      <Typography className={classes.text} variant="h3">
        Data Delivery Options
        <HelpOutlineIcon fontSize="large" />
      </Typography>
      <Typography className={classes.text} variant="body2">
        Specify how you want data to be available or accessed.
      </Typography>
      <Typography className={classes.text} variant="h6">
        Rest API
      </Typography>
      <JamLabsCard />
      {/* <Typography variant="h6">File Push Delivery</Typography>
      <Typography variant="body2">
        Have the data pushed to via cloud-to-cloud transfer or SFTP.
      </Typography>
      <AwsCard /> */}
    </>
  );
}

export default DeliveryOptionsPage;
