import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import store from './app/store';
import { Language } from './features/language/Language';
import * as serviceWorker from './serviceWorker';

// Import i18n messages
import { translationMessages } from './i18n';

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Language messages={translationMessages}>
        <App />
      </Language>
    </Provider>,
    document.getElementById('root'),
  );
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
