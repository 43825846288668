import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { Container, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '300px',
    width: '100%',
  },
  overlay: {
    border: 'dashed grey 6px',
    backgroundColor: 'rgba(232,232,232,.6)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  },
  text: {
    position: 'absolute',
    top: '50%',
    right: 0,
    left: 0,
    textAlign: 'center',
    color: 'grey',
  },
}));

export const PreviewDataTable = injectIntl(
  ({ rows, columns, editing, onDropEvent }) => {
    const classes = useStyles();
    const dropRef = React.createRef();
    const [dragging, setDragging] = React.useState(false);
    let dragCounter = 0;

    const handleDrag = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragIn = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (editing) {
        dragCounter += 1;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
          setDragging(true);
        }
      }
    };

    const handleDragOut = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (editing) {
        dragCounter -= 1;
        if (dragCounter === 0) {
          setDragging(false);
        }
      }
    };

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0 && editing) {
        onDropEvent(e.dataTransfer.files);
        e.dataTransfer.clearData();
        dragCounter = 0;
      }
    };

    useEffect(() => {
      const div = dropRef.current;

      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', handleDrop);

      return () => {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDrop);
      };
    });

    return (
      <div className={classes.root} ref={dropRef}>
        {dragging && (
          <Container component="div" className={classes.overlay}>
            <Container component="div" className={classes.text}>
              <Typography variant="h2">Upload Sample Data (*.csv)</Typography>
            </Container>
          </Container>
        )}
        <DataGrid columns={columns} rows={rows} pageSize={5} />
      </div>
    );
  },
);
