import React, { useEffect } from 'react';
import {
  Button,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { manageBaseUrl } from 'config';
import axios from '../../client/client';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
    maxWidth: '35rem',
  },
  cardHeader: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  actionButton: {
    margin: theme.spacing(1),
  },
}));

function ResetPasswordPage(props) {
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [fieldErrMsg, setFieldErrMsg] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');
  const [valid, setValid] = React.useState(true);
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [openErrorBanner, setOpenErrorBanner] = React.useState(false);
  const history = useHistory();

  // Check for valid url
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    axios
      .get(`${manageBaseUrl}/users/reset`, {
        params,
      })
      .then((response) => {
        setValid(response.data.valid);
        if (response.data.errMsg) setErrMsg(response.data.errMsg);
      });
  }, [props.location]);

  const handleKeyDown = (arg) => (e) => {
    if (e.keyCode === 13) {
      if (arg === 'password') {
        const { form } = e.target;
        const index = Array.prototype.indexOf.call(form, e.target);
        form.elements[index + 2].focus();
        e.preventDefault();
      } else {
        handleSubmit();
      }
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const handleSubmit = () => {
    if (password !== confirmPassword || password.length < 7) {
      setFieldErrMsg(
        'Passwords do not match or passwords less than 7 characters!',
      );
      return;
    }
    const resourcePath = `${manageBaseUrl}/users/reset`;
    // const { search } = props.location;
    const params = new URLSearchParams(props.location.search);
    const token = params.get('token');
    const email = params.get('email');
    const shopId = params.get('shopId');
    axios
      .post(
        resourcePath,
        {
          email,
          password,
          token,
          shopId,
        },
        { withCredentials: false },
      )
      .then((response) => {
        if (response.data.successRedirect) {
          history.push({
            pathname: `/users/sign-in`,
            state: { detail: 'password reset succeeded' },
          });
        }
      })
      .catch(() => {
        setOpenErrorBanner(true);
      });
  };
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        justify="flex-start"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={10}>
          {!valid ? (
            <Card className={classes.root}>
              <CardHeader
                className={classes.cardHeader}
                title={errMsg || 'URL has expired.'}
              />
            </Card>
          ) : (
            <Card className={classes.root}>
              <CardHeader
                className={classes.cardHeader}
                title="Set Your Password"
              />
              <CardContent>
                <form className={classes.form}>
                  {fieldErrMsg !== '' ? (
                    <span style={{ color: 'red' }}>{fieldErrMsg}</span>
                  ) : null}
                  <TextField
                    fullWidth
                    required
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDown}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    required
                    type={showConfirmPassword ? 'text' : 'password'}
                    label="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={handleKeyDown('confirmPassword')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            onMouseDown={handleMouseDown}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </CardContent>
              <CardActions>
                <Grid container justify="space-between" alignItems="flex-end">
                  <Button
                    className={classes.actionButton}
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    Submit Password
                  </Button>
                </Grid>
              </CardActions>
              <Snackbar
                open={openErrorBanner}
                autoHideDuration={5000}
                onClose={() => setOpenErrorBanner(false)}
              >
                <Alert
                  onClose={() => setOpenErrorBanner(false)}
                  severity="error"
                >
                  An error occurred while resetting password. Please try again
                  later!
                </Alert>
              </Snackbar>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ResetPasswordPage;
