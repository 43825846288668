import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  MenuItem,
  Select,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'auth/user/userAuth';
import { fetchShops, selectAllShops } from './shopSelectSlice';
import { selectShopId, setSelectedShopId } from '../SignInPage/shopSignInSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 2, 2),
    maxWidth: '35rem',
  },
  cardHeader: {
    padding: theme.spacing(2, 2, 0, 2),
    textAlign: 'center',
  },
  actionButton: {
    margin: theme.spacing(1),
    textDecoration: 'none',
  },
  link: {
    textDecoration: 'none',
  },
  signUpLink: {
    textAlign: 'center',
  },
}));

function ShopSelectPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useAuth();

  const shops = useSelector(selectAllShops);
  const shopsStatus = useSelector((state) => state.shops.status);
  const selectedShop = useSelector(selectShopId);
  useEffect(() => {
    if (shopsStatus === 'idle') {
      const params = new URLSearchParams(window.location.search);
      const userId = params.get('userId');
      dispatch(fetchShops(userId));
    }

    if (shopsStatus === 'succeeded' && shops.length === 1) {
      const shopId = shops[0].id;
      dispatch(setSelectedShopId(shopId));
      auth.setShopId(shopId);
      auth.fetchItems(shopId);
      history.push(`/shop/${shopId}`);
    }
  }, [shopsStatus, dispatch, history, shops, auth]);

  const handleShopSelect = (shopId) => {
    dispatch(setSelectedShopId(shopId));
    auth.fetchItems(shopId);
    history.push(`/shop/${shopId}`);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      justify="flex-start"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={10}>
        <Card className={classes.root}>
          <CardHeader className={classes.cardHeader} title="Select a Shop" />
          <CardContent>
            <div>
              <div className={classes.licenseSection}>
                <FormControl className={classes.formControl}>
                  <Select
                    value={selectedShop}
                    onChange={(event) => handleShopSelect(event.target.value)}
                  >
                    {shops.map((shop) => (
                      <MenuItem key={shop.id} value={shop.id}>
                        {shop.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </CardContent>
          <CardActions>
            <Grid
              container
              justify="space-between"
              alignItems="flex-end"
            ></Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ShopSelectPage;
